import request from "@/utils/request";

export const createShare =  (groupId=0)=>{
    return request({
        url: `https://api.cklllll.com/shop_manage/shop/createShare`,
        method: 'POST',
        data : {
            groupId,
        },
        loading : true,
    })
}

export const useCode =  (code)=>{
    return request({
        url: `https://api.cklllll.com/shop_manage/shop/useCode`,
        method: 'POST',
        data : {
            code
        },
        loading : true,
    })
}
