import { createVNode, render } from 'vue'
import editGorupWindow from './editGorup'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)




// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}



const editGorup = {
    open:(shopGroup)=>{
        const vnode = createVNode(editGorupWindow, { shopGroup:shopGroup,onClose : render(null, div)})
        renderMessage(vnode)
    },
}

export default editGorup
