<template>
  <a-modal v-model:visible="visible" width="40%" :title="`${order.shopInfo.shopName}:${order.orderId}`" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <span>备注等级:</span>&nbsp;&nbsp;&nbsp;&nbsp;
    <a-radio-group v-model:value="flag" name="radioGroup">
      <a-radio value="0"><img src="https://static.360buyimg.com/bus/webapp/order/image/0.png"></a-radio>
      <a-radio value="1"><img src="https://static.360buyimg.com/bus/webapp/order/image/1.png"></a-radio>
      <a-radio value="2"><img src="https://static.360buyimg.com/bus/webapp/order/image/2.png"></a-radio>
      <a-radio value="3"><img src="https://static.360buyimg.com/bus/webapp/order/image/3.png"></a-radio>
      <a-radio value="4"><img src="https://static.360buyimg.com/bus/webapp/order/image/4.png"></a-radio>
      <a-radio value="5"><img src="https://static.360buyimg.com/bus/webapp/order/image/5.png"></a-radio>
    </a-radio-group>
    <a-divider></a-divider>
    <a-textarea show-count :maxlength="450" v-model:value="order.halleyOrderisDetail.remark" placeholder="商家备注信息,会同步输入到店铺后台"></a-textarea><br>
    <div style="margin-top: 16px;text-align: right">
      <a-button @click="test">保存</a-button>
    </div>
  </a-modal>
</template>
<script>
import {defineComponent, h} from 'vue'
import { Modal,RadioGroup,Radio,Divider,Textarea,Button,message} from 'ant-design-vue'
import store from '@/store/index'
import {submitVenderRemark} from "@/api/order";


export default defineComponent({
  name : "remarkWindow",
  components:{
    aModal : Modal,
    aRadioGroup:RadioGroup,
    aRadio : Radio,
    aDivider:Divider,
    aTextarea : Textarea,
    aButton:Button,
  },
  props: {
    order: {},
    item: {},
  },
  data(){
    return{
      orderInfo: this.order,
      visible :false,
      flag : this.order.halleyOrderisDetail.flag?""+this.order.halleyOrderisDetail.flag:"0",
    }
  },
  setup() {
    const mystore = store
    return {
      mystore,message
    }
  },
  mounted() {
    if (!this.mystore.state.isInstall){
      Modal.error({title: "未安装获芸订单管理插件,请安装之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "./Chrome.zip",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})
      return
    }
    if (!this.mystore.state.isGetOrder){
      Modal.error({title: "未运行获芸订单同步组件客户端,请启动之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "https://huoyuntest.oss-cn-hangzhou.aliyuncs.com/huoyunOrderSysteam.exe",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})

      return
    }
    this.visible = true
  },
  methods:{
    test(){
      // eslint-disable-next-line vue/no-mutating-props
      this.order.halleyOrderisDetail.flag = this.flag
      submitVenderRemark(this.order.orderId,this.flag,this.order.halleyOrderisDetail.remark,this.order.shopInfo.shopId).then(res=>{
        if (res.success){
          message.success("备注成功:"+res.msg)
        }else{
          message.error("备注失败:"+res.msg)
        }

      })
    }
  }
})
</script>
