<template>
  <div class="headres">
    <a-row style="text-align: center;margin-top: 12px">
      <a-col :span="6" style="text-align: left">
        <div style="margin-left: 64px;width: 32px;height: 32px;margin-top: 12px"><a><img @click="reload" style="width: 32px;height: 32px;"  :src="require('@/assets/icon.png')"/></a></div>
      </a-col>
      <a-col :span="12" style="text-align: center">
        <h3 style="color: white;margin-top: -2px">获芸订单助手</h3>
        <h5 style="color: white;margin-top: -12px;font-size:12px">采购、出库、售后一体后台管理系统</h5>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: "Head",
  methods:{
    reload(){
      window.location.href="/"
    }
  }
}
</script>

<style>
  .headres{
    z-index: 999;
    position: fixed;
    width: 100%;
    height: 62px;
    left: 0;
    top: 0;
    background-image: -webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(69, 116, 241)), to(rgb(0, 171, 243)));
  }
</style>


