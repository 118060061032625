
export const get = name => {
    return window.localStorage.getItem(name)==null?{data : null}:JSON.parse(window.localStorage.getItem(name))
}


export const set = (name,data) => {
    if (typeof(data)=="object"){
        window.localStorage.setItem(name,JSON.stringify(data))
    }
    if (typeof(data)=="string"){
        window.localStorage.setItem(name,JSON.stringify({
            data : data
        }))
    }
}
