import request from '@/utils/request'
import { checkComponent } from '@/api/init'
import store from '@/store'

export const getOrderList = (data) => {
  return request({
    url: 'shop_manage/api/order?' + data,
    method: 'GET'
  })
}

export const getAfsOrderList = (data) => {
  return request({
    url: 'order/afs/afsOrder?' + data,
    method: 'GET'
  })
}

/**
 * 更新订单
 * @param data
 * @returns {*}
 */
export const updateOrder = (data) => {
  return request({
    url: 'shop_manage/order/updateOrder',
    method: 'POST',
    data: {
      data: data
    }
  })
}

/**
 * 取消关联
 *
 * @param data
 * @returns {*}
 */
export const cancleRelated = (data) => {
  return request({
    url: 'shop_manage/order/cancleRelated',
    method: 'POST',
    data: data
  })
}

/**
 * 购买记录
 *
 * @param data
 * @returns {*}
 */
export const orderChilds = (data) => {
  return request({
    url: 'shop_manage/order/orderChilds',
    method: 'POST',
    data: data
  })
}

function hashCode(string) {
  let hash = 0,
    i,
    chr
  if (string.length === 0) return hash
  for (i = 0; i < string.length; i++) {
    chr = string.charCodeAt(i)
    hash = (hash << 5) - hash + chr
    hash |= 0 // Convert to 32bit integer
  }
  return hash
}

export const getBuyUrl = (skuName, color = '') => {
  if (!color == '') {
    skuName = skuName.substring(0, skuName.indexOf(' ' + color))
  }
  console.log(skuName)
  /*    let skuCol = skuName.split(" ");
    if (skuCol.length >= 4){
        skuName = skuName.replace(skuCol[1],"")
        skuName = skuName + skuName.replace(skuCol[2],"")
    }else if(skuCol.length >= 3){
        skuName = skuName.replace(skuCol[skuCol.length-1],"")
    }*/
  return request({
    url: 'shop_manage/api/url?skuId=' + hashCode(skuName),
    method: 'GET',
    loading: false
  })
}

export const setBuyUrl = (skuName, buyInfo, color = '') => {
  if (!color == '') {
    skuName = skuName.substring(0, skuName.indexOf(' ' + color))
  }
  console.log(skuName)
  /*    let skuCol = skuName.split(" ");
    if (skuCol.length >= 4){
        skuName = skuName.replace(skuCol[1],"")
        skuName = skuName + skuName.replace(skuCol[2],"")
    }else if(skuCol.length >= 3){
        skuName = skuName.replace(skuCol[skuCol.length-1],"")
    }*/
  return request({
    url: 'shop_manage/api/url',
    method: 'POST',
    loading: false,
    data: {
      skuId: hashCode(skuName),
      info: buyInfo
    }
  })
}

export const refreshOrder = (order, loading = true) => {
  if (checkComponent()) {
    order.token = store.state.token
    return request({
      url: `http://localhost:8081/api`,
      method: 'POST',
      data: {
        name: '/script/update/jingdong/updateOrder',
        parameter: JSON.stringify(order),
        uninstall: false
      },
      loading: loading
    })
  }
}

export const refreshOrderPdd = (item) => {
  if (checkComponent()) {
    item.token = store.state.token
    return request({
      url: `http://localhost:8081/api`,
      method: 'POST',
      data: {
        name: `/script/update/${item.buyOrderType == 0 ? 'pinduoduo' : 'taobao'}/updateOrder`,
        parameter: JSON.stringify(item),
        uninstall: false
      },
      loading: false
    })
  }
}

export const remark = (orderId, itemId, remark) => {
  return request({
    url: `shop_manage/api/remark`,
    method: 'POST',
    loading: false,
    data: {
      orderId: orderId,
      skuId: itemId,
      remark: remark
    }
  })
}

export const History = (skuId, loading = true) => {
  return request({
    url: `order/History/buyHistory?skuId=${skuId}`,
    method: 'GET',
    loading: loading
  })
}

export const blockOrder = (orderId, status) => {
  return request({
    url: `order/api/blockOrder`,
    method: 'POST',
    loading: false,
    data: {
      orderId: orderId,
      status: status
    }
  })
}

export const submitVenderRemark = (orderId, flag, remark, shopId, loading = true) => {
  if (checkComponent()) {
    return request({
      url: `http://localhost:8081/api`,
      method: 'POST',
      data: {
        name: '/script/jingdong/api/submitVenderRemark',
        parameter: JSON.stringify({
          orderId: orderId,
          flag: flag,
          remark: remark,
          shopId: shopId,
          token: store.state.token
        }),
        uninstall: false
      },
      loading: loading
    })
  }
}

export const ShopStockOut = (orderList, loading = false) => {
  if (checkComponent()) {
    return request({
      url: `http://localhost:8081/api`,
      method: 'POST',
      data: {
        name: '/script/jingdong/api/orderOut',
        parameter: JSON.stringify({
          orderList,
          token: store.state.token
        }),
        uninstall: false
      },
      loading: loading
    })
  }
}

export const getMobileApi = (data) => {
  return request({
    url: `shop_manage/api/getMobile`,
    method: 'POST',
    loading: false,
    data
  })
}
