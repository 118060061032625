

<template>

  <a-card id="tool" hoverable style="text-align: left;width: 99%" >
    <a-tabs v-model:activeKey="searchParams.tabId" @change="getOrderList(true)">
      <a-tab-pane key="1" tab="全部订单"></a-tab-pane>
    </a-tabs>
    <span style="font-size: 14px"><Shop-outlined />&nbsp;选择店铺(可输入关键字搜索):</span><br>
    <a-row :gutter="20" style="margin-top: 12px">
      <a-col :span="24" >
        <a-select
            v-model:value="searchParams.shopIds"
            mode="multiple"
            style="width: 100%;"
            :allowClear="true"
            show-search
            :max-tag-count="10"
            placeholder="请选择您要使用的店铺(可多选)"
            @change="getOrderList(true)"
        >
          <a-select-option  v-for="shop in shopList" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
            {{shop.shopName}}
          </a-select-option>
        </a-select>
      </a-col>
    </a-row>
    <a-row :gutter="20" style="margin-top: 12px">

      <a-col :span="6" >
        <span style="font-size: 14px"><field-time-outlined />&nbsp;选择日期:</span><br>
        <a-range-picker style="margin-top: 8px" v-model:value="searchParams.selectTime"  size="small" />
      </a-col>

      <a-col :span="6" >
        <span style="font-size: 14px"><file-search-outlined />&nbsp;订单号:</span><br>
        <a-textarea
            style="margin-top: 12px"
            :pressEnter="getOrderList"
            size="small"
            v-model:value="searchParams.orderIds"
            placeholder="请输入内容,多个订单号请以,号或者换行分开"
            :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </a-col>
      <a-col :span="6" >
        <span style="font-size: 14px"><file-search-outlined />&nbsp;省份:</span><br>
        <a-textarea
            style="margin-top: 12px"
            size="small"
            v-model:value="searchParams.address"
            placeholder="请输入省份"
            :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </a-col>
      <a-col :span="6"  style="text-align: right">
        <br><br>
        <a-checkbox v-model:checked="searchParams.twoHour">只显示下单超过2小时的订单</a-checkbox><a-divider type="vertical" />&nbsp;
        <a-button @click="getOrderList(true)" type="primary"><search-outlined />搜索订单</a-button>
      </a-col>
      <a-col :span="6">
        <div style="text-align: left;margin-top: 16px"><h5 style="color: #909399">京东订单状态查询(可多选,不选为全部状态):</h5></div>
        <a-checkbox-group v-model:value="searchParams.orderStatus" :options="orderStatusCheckOptions" />
      </a-col>
      <a-col :span="18" style="margin-top: 14px">
        <div style="text-align: left;top: 14px"><h5 style="color: #909399">外部订单状态查询(可多选,不选为全部状态):</h5></div>
        <a-checkbox-group v-model:value="searchParams.buyOrderStatus" :options="buyOrderStatusCheckOptions" />
      </a-col>

      <a-col :span="24" style="margin-top: 0px;">
        <a-collapse ghost :bordered="false" style="margin-left: 0">
          <a-collapse-panel :showArrow="false" key="1">
            <template #header>
              <a style="font-size: 14px;margin-top: 12px" type="primary" size="small" @click="searchParams.moreSearchParamsDiv=!searchParams.moreSearchParamsDiv" >
                <down-outlined v-if="!searchParams.moreSearchParamsDiv" />
                <up-outlined v-if="searchParams.moreSearchParamsDiv" />
                &nbsp;{{!searchParams.moreSearchParamsDiv?'点击展开更多搜索条件':'点击收起更多搜索条件'}}
              </a>
            </template>
            <a-row class="animate__animated animate__flipInX" :gutter="86" style="margin-top: -12px">
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">下单账号:</h5></div>
                <a-input size="small" placeholder="下单账号关键字"   v-model:value="searchParams.pin"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">上家快递单号:</h5></div>
                <a-input size="small" placeholder="上家快递单号关键字"   v-model:value="searchParams.shipNo"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">手机号码:</h5></div>
                <a-input size="small" placeholder="手机号码关键字"   v-model:value="searchParams.phone"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">收货人姓名:</h5></div>
                <a-input size="small" placeholder="收货人姓名关键字"   v-model:value="searchParams.customerName"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">采购账号:</h5></div>
                <a-input size="small" placeholder="采购账号关键字"   v-model:value="searchParams.buyerName"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">采购单号:</h5></div>
                <a-input size="small" placeholder="采购单号关键字"   v-model:value="searchParams.orderSn"></a-input>
              </a-col>
              <a-col :span="6" style="text-align: left;margin-top: 12px">
                <div style="text-align: left;top: 14px"><h5 style="color: #909399">采购快递单号:</h5></div>
                <a-input size="small" placeholder="采购快递单号关键字"   v-model:value="searchParams.expressNo"></a-input>
              </a-col>
            </a-row>
          </a-collapse-panel>
        </a-collapse>
      </a-col>
    </a-row>
  </a-card>
  <br>
  <a-card id="orderList" style="text-align: left;width: 99%">
    <a-row :gutter="20" >
      <a-col :span="6" style="text-align: left;margin-top: 12px">
        当前淘宝登录账号:<span id="taobaoUser">{{taobaoUser}}</span>
      </a-col>
      <a-col :span="6" style="text-align: left;margin-top: 12px">
        当前拼多登录账号:<span id="pddUser">{{pddUser}}</span>
      </a-col>
    </a-row>
    <a-divider></a-divider>
    <div style="text-align: center;margin-top: 10px">
      <a-pagination @change="pageChange" v-model:pageSize="pageInfo.pageSize" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
    <a-divider></a-divider>
    <div>
      <a-space :size="16">
        <a-checkbox v-model:checked="allSelect" @click="orderList.forEach(order=>{order.select=true})">全部选中</a-checkbox>
        <a-button size="small" type="primary">导出选中订单
          <template #icon>
            <export-outlined />
          </template>
        </a-button>
      </a-space>
    </div>
    <a-divider></a-divider>
    <a-empty v-if="!orderList.length > 0"></a-empty>
    <orderList :orderList="orderList"></orderList>
    <div style="text-align: center;margin-top: 56px">
      <a-pagination @change="pageChange" v-model:pageSize="pageInfo.pageSize" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
  </a-card>
</template>


<script>
import dayjs from "dayjs";
import orderList from "@/components/page/ziYinOrder/components/orderList.vue";
import {orders, chromeZYApp, getShops} from "@/components/page/ziYinOrder/api";
import {message} from "ant-design-vue";
import {columns} from "@/components/page/ziYinOrder/components/orderTable";
import {ElNotification} from "element-plus";


export default {
  name: "ziYinOrder",
  components: {
    orderList

  },
  mounted(){
    this.getShopList()
    this.getOrderList()
    chromeZYApp.callback((e)=> {
      try {
        let msg = e.data
        switch (msg.type) {
          case 'taobaoUser':
            this.taobaoUser = msg.data.taobaoUserName
            break
          case 'pddUser':
            this.pddUser = msg.data.pddUserName
            break
        }
      }catch (e){
        console.error(e)
      }
    })
  },
  data(){
    return {
      allSelect : false,
      orderList:[],
      pageInfo : {
        page : 1,
        pageSize : 10,
        total : 0,
      },
      taobaoUser:null,
      pddUser:null,
      searchParams : {
        tabId : "1",
        moreSearchParamsDiv : false,
        selectTime:  [
          dayjs(new Date(new Date().toLocaleDateString()).getTime()).subtract(
              15,
              'day'
          ),
          dayjs(
              new Date(new Date().toLocaleDateString()).getTime() +
              24 * 60 * 60 * 1000 -
              1
          )
        ],
        orderIds : "",
        orderStatus:'10',
        address:"",
        twoHour : false,
        pin:"",
        shipNo:"",
        phone:"",
        customerName:"",
        buyOrderStatus:null,
        buyerName:null,
        orderSn:null,
        expressNo:null

      },
      columns:columns,

      orderStatusCheckOptions : [
        { label: '等待出库', value: 7, },
        { label: '等待发货', value: 10, },
        { label: '等待确认发货', value: 16, },
        { label: '完成', value: 19,},
        { label: '删除', value: 20, },
      ],
      buyOrderStatusCheckOptions : [
        { label: '未拍单', value: -1 },
        { label: '待付款', value: 0 },
        { label: '待发货', value: 1 },
        { label: '待收货', value: 2 },
        { label: '待评价', value: 3 },
        { label: '交易已取消', value: 4 },
      ],
      shopList:[]
    }
  },
  methods:{
    pageChange(){
      this.getOrderList(false)
    },
    getShopList(){
      getShops({}).then(res=>{
        this.shopList = res.data
      })
    },
    getOrderList(init = false){
      if (init){
        this.pageInfo.page=1
      }
      orders({
        ...this.pageInfo,
        ...this.searchParams
      }).then(res =>{
        if (res.success){
          if (res.data.length < 1){
            this.orderList = []
            this.pageInfo.total = res.data.total
            return
          }
          res.data.data.forEach(order=>{
            order.select = this.allSelect?true:false;
            order.orderDetailList = JSON.parse(order.orderDetailList)
          })
          this.orderList = res.data.data
          this.pageInfo.total = res.data.total
          chromeZYApp.callback((e)=> {
            try {
              let msg = e.data
              switch (msg.type) {
                case 'refresh_order':
                  this.orderList.forEach(order=>{
                    if (order.orderId === msg.data.jdOrderSn){
                      order.orderDetailList.forEach(orderDetail =>{
                        if (orderDetail.sku === msg.data.sku.jdSkuId){
                          order.purchase_order = 1
                          orderDetail.purchase_order = msg.data
                          if (msg.data.notify === true){
                            ElNotification({
                              title: '刷新订单',
                              message: '订单：'+order.orderId.toString()+' 下单成功，已转移到已拿货列表。',
                              type: 'success',
                              position: 'bottom-right',
                            })
                          }

                        }
                      })
                    }
                  })
                  break
                case 'refreshJdOrder':
                  this.orderList.forEach(order=>{
                    if (order.orderId === msg.data.orderId){
                      order = msg.data
                    }
                  })
                  break
                case 'jdOrderDeliver':
                  this.orderList.forEach(order=>{
                    if (order.id === msg.data.id){
                      order = msg.data
                    }
                  })
                  break
              }
            }catch (e){
              console.error(e)
            }
          })
        }else{
          this.orderList = []
          this.pageInfo.total = 0
          message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>