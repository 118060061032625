<template>
  <div class="orderList">
    <a-row>
      <a-col :span="24">
        <a-space direction="vertical" :size="24">
          <a-card class="animate__animated animate__flash" hoverable v-for="(order, orderIndex) in orderList"
            :key="order.id" size="small" style="width: 100%">
            <div :class="'order_' + orderIndex + '_head'"
              style="text-align: left;margin-top: 0px;width: 100%;overflow: hidden;">
              <a-row>
                <a-col :span="16">
                  <a-space :size="14" style="">
                    <a-checkbox v-model:checked="order.select" style="text-align: left;"
                      size="small"></a-checkbox><a-divider type="vertical" />
                    <a-dropdown>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item @click="copy('' + order.shopId)" key="1">
                            <copy-outlined />&nbsp;复制店铺ID
                          </a-menu-item>
                          <a-menu-item @click="copy('' + order.shopName)" key="2">
                            <copy-outlined />&nbsp;复制店铺名
                          </a-menu-item>
                          <a-menu-item key="2"
                            @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId=' + order.shopInfo.shopId)"><exclamation-circle-outlined />&nbsp;查看店铺</a-menu-item>
                        </a-menu>
                      </template>
                      <a-button type="text"
                        :style="{ 'color': isOnline(order.shopId) ? '#409EFF' : '#F56C6C', 'font-size': '12px' }"
                        size="small"><shop-outlined />{{ order.shopName }}{{ isOnline(order.shopId) ? '' : '(掉线)' }}
                        <DownOutlined />
                      </a-button>
                    </a-dropdown><a-divider type="vertical" />
                    <a-dropdown>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item @click="copy('' + order.orderId)" key="1">
                            <copy-outlined />&nbsp;复制订单
                          </a-menu-item>
                          <a-menu-item key="2"
                            @click="openWindow('https://order.cklllll.com/extension.html?type=orderInfo&shopId=' + order.shopInfo.shopId + '&orderid=' + order.orderId)"><exclamation-circle-outlined />&nbsp;查看订单</a-menu-item>
                        </a-menu>
                      </template>
                      <a-button type="text" style="color: #409EFF;font-size: 12px"
                        size="small"><file-text-outlined />单号:{{ order.orderId }}
                        <DownOutlined />
                      </a-button>
                    </a-dropdown>
                    <a-divider type="vertical" />
                    <a-button type="text"
                      :style="{ 'color': Math.round(new Date().getTime() / 1000) - (1 * 60 * 60 * 2) < order.orderCreateTime ? '#E6A23C' : '#409EFF', 'font-size': '12px' }"
                      size="small"><clock-circle-outlined />{{ transformTime(order.orderCreateTime)
                      }}</a-button><a-divider type="vertical" />
                    <a-button type="text" :style="{ 'color': orderStatus(order.orderStatus).color, 'font-size': '12px' }"
                      size="small"><calendar-outlined />
                      {{ orderStatus(order.orderStatus).label }}
                    </a-button><a-divider type="vertical" />
                    <a-button type="text"
                      :style="{ 'color': order.paymentTypeName == '在线支付' ? '#409EFF' : '#F56C6C', 'font-size': '12px' }"
                      size="small"><account-book-outlined />
                      {{ order.paymentTypeName }}
                    </a-button><a-divider type="vertical" />
                    <a-button type="text" style="font-size: 12px" size="small"><warning-outlined />{{
                      order.invoiceTypeName }}</a-button><a-divider type="vertical" />
                  </a-space>
                </a-col>
                <a-col style="text-align: right" :span="8">
                  <a-popconfirm :title="block == 0 ? '是否隔离本订单?' : '是否移除本隔离订单'" ok-text="确定"
                    @confirm="blockOrder(order.orderId, orderIndex, order.block)" cancel-text="取消">
                    <a :style="{ 'color': order.block == 0 ? '#ECB96D' : 'red' }"><delete-outlined />{{ order.block == 0 ?
                      '隔离订单' : "订单已被隔离" }}</a>
                  </a-popconfirm>
                  <a-divider type="vertical" />
                  <a-popover style="min-width: 360px" placement="topLeft">
                    <template #content>
                      <span>支付减免:({{ order.orderBankDetailDTO.payDiscounts.length }}):</span><br>
                      <div style="text-align: right" v-for="payDiscounts in order.orderBankDetailDTO.payDiscounts"
                        :key="payDiscounts.payId">
                        <span style="font-size:12px">{{ payDiscounts.payTypeName }}&nbsp;&nbsp;-{{ payDiscounts.payMoney
                        }}
                          元</span>
                      </div>
                      <span>客户实际支付:</span><br>
                      <div style="text-align: right">
                        <span style="font-size:12px">{{ order.realPrice }}&nbsp;&nbsp;元</span>
                      </div>
                    </template>
                    <template #title>
                      <span>付款账单信息</span>
                    </template>
                    <a-button type="text" style="font-size: 12px" size="small"><account-book-outlined />实付:{{
                      (parseFloat(order.realPrice) + parseFloat(order.orderBankDetailDTO.payDiscount)).toFixed(2)
                    }}元</a-button>
                  </a-popover><a-divider type="vertical" />
                  <a-dropdown>
                    <template #overlay>
                      <a-menu>
                        <a-menu-item @click="refresh(order, orderIndex)" key="1">
                          <reload-outlined />&nbsp;&nbsp;更新订单
                        </a-menu-item>
                        <a-menu-item @click="outWindow(order, item)" key="2">
                          <send-outlined />&nbsp;&nbsp;订单出库
                        </a-menu-item>
                      </a-menu>
                    </template>
                    <a-button style="font-size: 12px" type="link"
                      size="small"><setting-outlined />订单操作<down-outlined /></a-button>
                  </a-dropdown>
                  <a-divider type="vertical" />
                </a-col>
              </a-row>
            </div>
            <a-divider style="margin-top: 12px;margin-bottom: 12px"></a-divider>
            <div style="margin-top: 12px" v-for="(item, itemIndex) in order.orderItems" :key="item.id"
              :class="'itemIndex' + itemIndex">
              <a-row :gutter="20">
                <a-col :span="2" style="text-align: center">
                  <img style="width: 64px;height: 64px" :src="item.orderItems.skuimage"
                    @click="viewImage(item.orderItems.skuimage.replace('N5', 'N0').replace('N4', 'N0'))" />
                </a-col>
                <a-col :span="6" style="text-align: left">
                  <i @click="browserWindow(item.orderItems.skuName, 'https://item.m.jd.com/product/' + item.skuId + '.html')"
                    style="font-size:12px;color:  #409EFF;font-style:normal;">{{ item.orderItems.skuName }}</i><br>
                  <i style="font-size: 12px;color:  #606266;font-style:normal;">规格:{{ item.orderItems.color
                  }}</i>&nbsp;&nbsp;
                  <i style="font-size: 12px;color:  #606266;font-style:normal;">购买数量:x{{ item.orderItems.skuNum }}</i>
                  <div style="margin-top: 0px"
                    v-if="order.warnType > -1 || order.breakAgreementType > -1 || order.refunded > -1">
                    <a-space>
                      <a-button style="font-size: 9px;" v-if="order.warnType > -1" warning type="text"
                        size="small"><warning-outlined />
                        {{ order.warnType == 1 ? '出库预警' : order.warnType == 3 ? '揽收预警' : '未知预警' }}</a-button>
                      <a-button style="font-size: 9px;" v-if="order.breakAgreementType > -1" danger type="text"
                        size="small"><warning-outlined />{{ order.breakAgreementType == 1 ? '出库超时' :
                          order.breakAgreementType == 3 ? '揽收超时' : '未知超时' }}</a-button>
                      <a-button style="font-size: 9px;" v-if="order.refunded > -1" danger type="text"
                        size="small"><warning-outlined /> 本单已经退款</a-button>
                    </a-space>
                  </div>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <div v-if="item.chuanmeiExpressNo !== '' && item.chuanmeiExpressNo != null">
                    <a-button type="primary" target="_blank"
                      :href="'https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd=' + item.chuanmeiExpressNo">
                      {{ item.chuanmeiExpressNo !== "" &&
                        item.chuanmeiExpressNo != null ? '云仓运单:' + item.chuanmeiExpressNo : "未云仓出库" }}
                    </a-button>
                  </div>
                  <div
                    :style="{ 'margin-top': item.chuanmeiExpressNo !== '' && item.chuanmeiExpressNo != null ? '12px' : '0px' }">

                    <a size="small" style="font-size: 12px;color:  #606266;">京东价:&nbsp;&nbsp;{{ item.orderItems.jdPrice }}
                      元</a><br>
                    <a size="small" style="font-size: 12px;color:  #606266;">运费:&nbsp;&nbsp;{{ order.freight.replace("¥",
                      "") }} 元</a><br>
                  </div>
                  <div v-if="typeof (order.userRemark) == 'string' && order.userRemark != ''" style="margin-top: 0px">
                    <!--                    <a-button style="font-size: 12px" danger size="small" type="primary" >客户备注:{{order.userRemark}}</a-button>-->
                    <a-typography-text :style="{ color: 'red', 'font-size': '12px' }"
                      :ellipsis="{ tooltip: order.userRemark }" :content="'备注:' + order.userRemark" />
                  </div>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <a-textarea style="color: #303133;height: 30px;font-size: 12px" v-model:value="item.remark"
                    @change="itemRemark(order.orderId, item.skuId, item.remark)" size="mini" placeholder="本地备注信息">
                  </a-textarea>
                  <a-textarea show-count :maxlength="450" @click="remarkWindow(order, item)"
                    :style="{ 'height': '30px', 'margin-top': '10px', 'font-size': '12px' }"
                    v-model:value="order.halleyOrderisDetail.remark" size="small" placeholder="商家备注信息">
                  </a-textarea>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <a-button style="font-size: 12px" size="small" v-if="item.status == 0" @click="buyWindow(order, item)"
                    type="primary"><shopping-cart-outlined />立即采购</a-button>
                  <a-button style="font-size: 12px" size="small" v-if="item.status == 99" @click="buyWindow(order, item)"
                    type="primary"><shopping-cart-outlined />正在下单</a-button>
                  <a-button style="font-size: 12px" size="small" v-if="item.status > 0 && item.status < 99"
                    @click="buyWindow(order, item)" type="danger"><shopping-cart-outlined />重新采购</a-button>
                  <br>
                  <a-button style="margin-top: 4px;font-size: 12px" @click="bindWindow(order, item)"
                    size="small"><apartment-outlined />手动关联</a-button><br>
                  <a-button style="margin-top: 4px;font-size: 12px" @click="buyHistoryWindow(order, item)"
                    size="small"><fund-projection-screen-outlined />采购记录</a-button><br>
                  <a-popconfirm title="是否解除关联已采购的订单?" v-if="item.order_sn != '' && item.order_sn != null" ok-text="确定"
                    cancel-text="取消" @confirm="confirm(order, item)">
                    <a-button style="margin-top: 4px;font-size: 12px" size="small" danger>
                      <delete-outlined />解除关联
                    </a-button>
                  </a-popconfirm>
                </a-col>
                <a-col :span="4" style="text-align: right" name="buyOrder" :data-orderIndex="orderIndex"
                  :data-itemIndex="itemIndex" :id="order.orderId + '_' + item.skuId" :data-update="0"
                  :data-orderId="order.orderId" :data-skuId="item.skuId">
                  <a-row>
                    <a-col :span="22" style="text-align: left">
                      <a-spin tip="更新中..." :spinning="item.reload != null || item.reload != undefined">
                        <div style="text-align: center" v-if="item.order_sn == '' || item.order_sn == null">
                          <div>
                            <svg class="ant-empty-img-default" width="64" height="64" viewBox="0 0 184 152">
                              <g fill="none" fill-rule="evenodd">
                                <g transform="translate(24 31.67)">
                                  <ellipse class="ant-empty-img-default-ellipse" cx="67.797" cy="106.89" rx="67.797"
                                    ry="12.668"></ellipse>
                                  <path class="ant-empty-img-default-path-1"
                                    d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z">
                                  </path>
                                  <path class="ant-empty-img-default-path-2"
                                    d="M101.537 86.214L80.63 61.102c-1.001-1.207-2.507-1.867-4.048-1.867H31.724c-1.54 0-3.047.66-4.048 1.867L6.769 86.214v13.792h94.768V86.214z"
                                    transform="translate(13.56)"></path>
                                  <path class="ant-empty-img-default-path-3"
                                    d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z">
                                  </path>
                                  <path class="ant-empty-img-default-path-4"
                                    d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z">
                                  </path>
                                </g>
                                <path class="ant-empty-img-default-path-5"
                                  d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z">
                                </path>
                                <g class="ant-empty-img-default-g" transform="translate(149.65 15.383)">
                                  <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815"></ellipse>
                                  <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z"></path>
                                </g>
                              </g>
                            </svg>
                          </div>
                          <div>
                            <a-button style="font-size: 9px" @click="buyWindow(order, item)" danger
                              size="small"><shopping-cart-outlined />采购</a-button>
                            &nbsp;<span style="font-size: 9px">或</span>&nbsp;
                            <a-button style="font-size: 9px;color: blue" class="refreshOrder"
                              @click="refreshPdd(item, orderIndex)" :data-skuId="item.skuId" :data-orderId="order.orderId"
                              size="small"><reload-outlined />刷新</a-button>
                          </div>
                        </div>
                        <div v-if="!item.order_sn == '' || !item.order_sn == null">
                          <a-row>
                            <a-col :span="5" style="text-align: left;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">买手号</span></a-col>
                            <a-col :span="19" style="text-align: right;color: #409EFF">
                              <a-typography-paragraph
                                @click="openWindow(item.buyOrderType == 0 ? 'https://order.cklllll.com/extension.html?type=pddOrderInfo&order_sn=' + item.order_sn + '&duoduoId=' + item.duoduoId : 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id=' + item.order_sn)"
                                :copyable="{ 'text': item.nickname }"><span style="color: #409EFF;font-size: 11px">{{
                                  item.nickname }}</span></a-typography-paragraph>
                            </a-col>
                            <a-col :span="4" style="text-align: left;color: #409EFF;margin-top: -14px"><span
                                style="color: #409EFF;font-size: 11px">订单号</span></a-col>
                            <a-col :span="20" style="text-align: right;color: #409EFF;margin-top: -14px">
                              <a-typography-paragraph
                                @click="openWindow(item.buyOrderType == 0 ? 'https://order.cklllll.com/extension.html?type=pddOrderInfo&order_sn=' + item.order_sn + '&duoduoId=' + item.duoduoId : 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id=' + item.order_sn)"
                                :copyable="{ 'text': item.order_sn }"><span style="color: #409EFF;font-size: 11px">{{
                                  item.order_sn }}</span></a-typography-paragraph>
                            </a-col>
                            <a-col :span="5" style="margin-top: -14px;text-align: left;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">时 间：</span></a-col>
                            <a-col :span="19" style="margin-top: -14px;text-align: right;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">{{ transformTime(item.buyOrderPayTime)
                                }}</span></a-col>
                            <a-col :span="5" style="text-align: left;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">状 态：</span></a-col>

                            <a-col :span="19" style="text-align: right;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">{{ item.buyOrderStatusStrCN }}</span>&nbsp;&nbsp;<a
                                :data-orderId="order.orderId" :data-skuId="item.skuId" class="refreshOrder"
                                @click="refreshPdd(item, orderIndex)"><reload-outlined
                                  style="color: #409EFF;font-size: 11px"></reload-outlined></a></a-col>
                            <a-col v-if="item.expressNo != null && item.expressNo != ''" :span="8"
                              style="text-align: left;color: #409EFF"><span style="color: #409EFF;font-size: 11px">{{
                                item.expressName }}</span></a-col>
                            <a-col v-if="item.expressNo != null && item.expressNo != ''" :span="16"
                              style="text-align: right;color: #409EFF">
                              <a-typography-paragraph
                                @click="openWindow('https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd=' + item.expressNo)"
                                :copyable="{ 'text': item.expressNo }"><span style="color: #409EFF;font-size: 11px">{{
                                  item.expressNo }}</span></a-typography-paragraph>
                            </a-col>
                            <a-col :span="8"
                              :style="{ 'text-align': 'left', 'color': '#409EFF', 'margin-top': item.expressNo != null && item.expressNo != '' ? '-14px' : '0px' }"><span
                                style="color: #409EFF;font-size: 11px">采购价格：</span></a-col>
                            <a-col :span="16"
                              :style="{ 'text-align': 'right', 'color': '#409EFF', 'margin-top': item.expressNo != null && item.expressNo != '' ? '-14px' : '0px' }"><span
                                :style="{ 'color': profit(order) < 0 ? 'red' : '#409EFF', 'font-size': '11px' }">{{
                                  item.total_fee }}
                                元</span></a-col>
                            <a-col :span="8" style="text-align: left;color: #409EFF"><span
                                style="color: #409EFF;font-size: 11px">预计利润：</span></a-col>
                            <a-col :span="16" style="text-align: right;color: #409EFF"><span
                                :style="{ 'color': profit(order) < 0 ? 'red' : '#409EFF', 'font-size': '11px' }">{{
                                  profit(order) }}
                                元</span></a-col>
                          </a-row>
                        </div>
                      </a-spin>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
              <a-divider style="margin-top: 12px;margin-bottom: 12px"></a-divider>
            </div>
            <a-descriptions v-if="vuex.state.setting.showBuyerUserInfo" :column="6" size="small"
              style="margin-bottom: -12px;margin-top: 0px">
              <a-descriptions-item :labelStyle="{ 'color': '#606266', 'font-size': '12px' }"
                :contentStyle="{ 'color': '#606266', 'font-size': '12px' }" label="注册时间">{{ order.buyerInfo.data.user.reg
                }}</a-descriptions-item>
              <a-descriptions-item
                :labelStyle="{ 'color': order.buyerInfo.data.user.jdScore < 2000 ? 'red' : '#606266', 'font-size': '12px' }"
                :contentStyle="{ 'color': order.buyerInfo.data.user.jdScore < 2000 ? 'red' : '#606266', 'font-size': '12px' }"
                label="京 享 值">{{ order.buyerInfo.data.user.jdScore }}</a-descriptions-item>
              <a-descriptions-item :labelStyle="{ 'color': '#606266', 'font-size': '12px' }"
                :contentStyle="{ 'color': '#606266', 'font-size': '12px' }" label="好 评 率">{{
                  order.buyerInfo.data.user.goodRate }}</a-descriptions-item>
              <a-descriptions-item :labelStyle="{ 'color': '#606266', 'font-size': '12px' }"
                :contentStyle="{ 'color': '#606266', 'font-size': '12px' }" label="收 货 人">{{ order.buyAddressInfo.address
                }}&nbsp;&nbsp;&nbsp;{{ order.buyAddressInfo.name }}&nbsp;&nbsp;&nbsp;{{ order.buyAddressInfo.phone }}
              </a-descriptions-item></a-descriptions>
          </a-card>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
import { message } from "ant-design-vue";
import bind from "@/components/server/bindWindow";
import buy from "@/components/server/buyWindow";
import out from "@/components/server/outWindow";
import browser from "@/components/server/browserWindow";
import buyHistory from "@/components/server/buyHistoryWindow";
import remarkWindow from "@/components/server/remarkWindow";
import { blockOrder, refreshOrder, refreshOrderPdd, remark, getMobileApi } from "@/api/order";
import store from '@/store/index'
import viewImage from "@/components/utils/components/viewImage";


export default {
  name: "orderList",
  props: {
    orderList: [],
    shopIdList: [],
  },
  data () {
    return {
      flagColor: ["#9CA7B5", "#FF3333", "#FFA824", "#24C57C", "#41A8FF", "#B698FF"],
      flag: false
    }
  },
  watch: {
    orderList: {
      handler (val) {
        if (val.length) {
          this.flag = true
          this.updatePhone()
        }
      }
    }
  },
  setup () {
    const { toClipboard } = useClipboard()
    const vuex = store
    const copy = async (text) => {
      try {
        await toClipboard(text)
        message.success('复制成功:' + text)

      } catch (e) {
        message.success('复制失败:' + e)
      }
    }
    return {
      copy, refreshOrder, refreshOrderPdd, remark, vuex, viewImage
    }
  },
  methods: {
    updatePhone () {
      if (this.flag) {
        this.orderList.map(async item => {
          if (item.orderId && item.shopName && item.shopId) {
            const phone = await this.getPhone({
              orderId: item.orderId,
              shopName: item.shopName,
              shopId: item.shopId
            })
            item.buyAddressInfo.phone = phone
            return item
          }
        })
        this.flag = false
      }
    },
    async getPhone (param) {
      try {
        const res = await getMobileApi(param)
        if (res.success) return JSON.parse(res.data).mobile
      } catch (error) {
        console.log(error)
      }
    },
    orderStatus (status) {
      const statusList = [
        { label: '等待出库', value: '8', color: "#409EFF" },
        { label: '等待收货', value: '16', color: "#E6A23C" },
        { label: '完成订单', value: '19', color: "#67C23A" },
        { label: '已取消', value: '-1', color: "#F56C6C" },
      ]
      for (let i = 0; i < statusList.length; i++) {
        if (statusList[i].value == status) {
          return statusList[i]
        }
      }
      return { label: '未知状态,' + status, value: status, color: "#909399" }
    },
    buyWindow (order, item, index = 0) {
      item.status = 99
      buy.open(order, item)
      return
      // eslint-disable-next-line no-unreachable
      try {
        item.status = 99
        refreshOrder(order, true).then(res => {
          buy.open(order, item)
          if (res.success) {
            // eslint-disable-next-line no-empty
            try { res.data.address = JSON.parse(res.data.address) } catch (e) { }
            // eslint-disable-next-line no-empty
            // eslint-disable-next-line no-empty
            try { res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo) } catch (e) { }
            if (typeof (res.data.halleyOrderisDetail) === 'string') {
              res.data.halleyOrderisDetail = JSON.parse(res.data.halleyOrderisDetail)
            }
            // eslint-disable-next-line no-empty
            try {
              res.data.buyerInfo = JSON.parse(res.data.buyerInfo)
              // eslint-disable-next-line no-prototype-builtins
              if (res.data.buyerInfo.hasOwnProperty("buyerInfo")) {
                res.data.buyerInfo = res.data.buyerInfo.buyerInfo
              } else {
                res.data.buyerInfo = {
                  data: {
                    user: {
                      jdScore: 0,
                      reg: null,
                      goodRate: null,

                    }
                  }
                }
              }
              console.log(res.data.buyerInfo)
              // eslint-disable-next-line no-empty
            } catch (e) { }
            // eslint-disable-next-line no-empty
            try { res.data.shopInfo = JSON.parse(res.data.shopInfo) } catch (e) { }
            // eslint-disable-next-line no-empty
            try { res.data.orderBankDetailDTO = JSON.parse(res.data.orderBankDetailDTO) } catch (e) { }
            console.log(res.data)
            res.data.orderItems.forEach(item => {
              item.expressInfo = JSON.parse(item.expressInfo)
              item.orderItems = JSON.parse(item.orderItems)
              item.orderItems.visible = false
            })
            res.data.checkBoxSelect = false;
            // eslint-disable-next-line vue/no-mutating-props
            this.orderList[index] = res.data
            message.success("更新订单状态成功")
          } else {
            message.error(res.msg)
          }
        })
        // eslint-disable-next-line no-unreachable
      } catch (err) {
        item.status = 99
        buy.open(order, item)
        message.error("更新订单信息失败")
      }

    },
    bindWindow (order, item) {
      bind.open(order, item)
    },
    buyHistoryWindow (order, item) {
      buyHistory.open(order, item)
    },
    outWindow (order) {
      out.open(order)
    },
    browserWindow (title, url) {
      browser.open(title, url)
    },
    openWindow (url) {
      window.open(url)
    },
    transformTime (timestamp) {
      let time = new Date(timestamp * 1000);
      let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
      let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
      let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
      M = M < 10 ? "0" + M : M;
      d = d < 10 ? "0" + d : d;
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    refresh (order, index) {
      refreshOrder(order).then(res => {
        if (res.success) {
          // eslint-disable-next-line no-empty
          try { res.data.address = JSON.parse(res.data.address) } catch (e) { }
          // eslint-disable-next-line no-empty
          // eslint-disable-next-line no-empty
          try { res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo) } catch (e) { }
          // eslint-disable-next-line no-empty
          try {
            res.data.buyerInfo = JSON.parse(res.data.buyerInfo)
            // eslint-disable-next-line no-prototype-builtins
            if (res.data.buyerInfo.hasOwnProperty("buyerInfo")) {
              res.data.buyerInfo = res.data.buyerInfo.buyerInfo
            }
            console.log(res.data.buyerInfo)
            // eslint-disable-next-line no-empty
          } catch (e) { }
          if (typeof (res.data.halleyOrderisDetail) === 'string') {
            // eslint-disable-next-line no-empty
            try { res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo) } catch (e) { }
            // eslint-disable-next-line no-empty
            try {
              res.data.buyerInfo = JSON.parse(res.data.buyerInfo)
              // eslint-disable-next-line no-prototype-builtins
              if (res.data.buyerInfo.hasOwnProperty("buyerInfo")) {
                res.data.buyerInfo = res.data.buyerInfo.buyerInfo
              } else {
                res.data.buyerInfo = {
                  data: {
                    user: {
                      jdScore: 0,
                      reg: null,
                      goodRate: null,

                    }
                  }
                }
              }
              console.log(res.data.buyerInfo)
              // eslint-disable-next-line no-empty
            } catch (e) { }
            if (typeof (res.data.halleyOrderisDetail) === 'string') {
              res.data.halleyOrderisDetail = JSON.parse(res.data.halleyOrderisDetail)
            }
            // eslint-disable-next-line no-empty
            try { res.data.shopInfo = JSON.parse(res.data.shopInfo) } catch (e) { }
            // eslint-disable-next-line no-empty
            try { res.data.orderBankDetailDTO = JSON.parse(res.data.orderBankDetailDTO) } catch (e) { }
            res.data.orderItems.forEach(item => {
              item.expressInfo = JSON.parse(item.expressInfo)
              item.orderItems = JSON.parse(item.orderItems)
              item.orderItems.visible = false
            })
            res.data.checkBoxSelect = false;
            // eslint-disable-next-line vue/no-mutating-props
            this.orderList[index] = res.data
            message.success("更新订单状态成功")
          } else {
            message.error(res.msg)
          }
        }
      })
    },
    refreshPdd (item, index) {
      item.reload = true
      refreshOrderPdd(item).then(res => {
        if (res.success) {
          item.reload = null
          // eslint-disable-next-line no-empty
          try { res.data.address = JSON.parse(res.data.address) } catch (e) { }
          // eslint-disable-next-line no-empty
          // eslint-disable-next-line no-empty
          try { res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo) } catch (e) { }
          if (typeof (res.data.halleyOrderisDetail) === 'string') {
            res.data.halleyOrderisDetail = JSON.parse(res.data.halleyOrderisDetail)
          }
          // eslint-disable-next-line no-empty
          try {
            res.data.buyerInfo = JSON.parse(res.data.buyerInfo)
            // eslint-disable-next-line no-prototype-builtins
            if (res.data.buyerInfo.hasOwnProperty("buyerInfo")) {
              res.data.buyerInfo = res.data.buyerInfo.buyerInfo
            } else {
              res.data.buyerInfo = {
                data: {
                  user: {
                    jdScore: 0,
                    reg: null,
                    goodRate: null,

                  }
                }
              }
            }
            console.log(res.data.buyerInfo)
            // eslint-disable-next-line no-empty
          } catch (e) { }
          // eslint-disable-next-line no-empty
          try { res.data.shopInfo = JSON.parse(res.data.shopInfo) } catch (e) { }
          // eslint-disable-next-line no-empty
          try { res.data.orderBankDetailDTO = JSON.parse(res.data.orderBankDetailDTO) } catch (e) { }
          res.data.orderItems.forEach(item => {
            item.expressInfo = JSON.parse(item.expressInfo)
            item.orderItems = JSON.parse(item.orderItems)
            item.orderItems.visible = false
          })
          res.data.checkBoxSelect = true;

          // eslint-disable-next-line vue/no-mutating-props
          this.orderList[index] = res.data
          message.success("采购订单信息已同步")
        } else {
          item.reload = null
          message.error(res.msg)
        }
      })
    },
    itemRemark (orderId, itemId, remarkStr) {
      remark(orderId, itemId, remarkStr).then(res => {
        if (res.success) {
          message.success("备注已修改")
        } else {
          message.info("备注未修改")
        }
      })
    },
    isOnline (shopId) {
      for (let i = 0; i < this.$props.shopIdList.length; i++) {
        if (shopId == this.$props.shopIdList[i].shopId) {
          if (this.$props.shopIdList[i].status > -1) {
            return true
          }
        }
      }
      return false
    },
    profit (order) {
      let profit = (parseFloat(order.realPrice) + parseFloat(order.orderBankDetailDTO.payDiscount)).toFixed(2)
      order.orderItems.forEach(item => {
        if (typeof (item.total_fee) == "string") {
          profit = profit - item.total_fee.replace("¥", "");
        } else {
          profit = profit - item.total_fee
        }
      })
      return profit.toFixed(2)
    },
    confirm (order, item) {
      console.log(order, item);
      message.success('Click on Yes');
    },
    blockOrder (orderId, orderIndex, block) {
      let stauts = block == 0 ? 1 : 0
      if (block == 0) {
        // eslint-disable-next-line vue/no-mutating-props
        this.orderList.splice(orderIndex, 1)
      }
      blockOrder(orderId, stauts).then(res => {
        if (res.success) {
          message.success(`订单${orderId}已被${block == 0 ? "加入" : "移除"}隔离订单列表`)
        } else {
          message.error(`操作订单${orderId}隔离失败,${res.msg}`)
        }
      })
    },
    remarkWindow (order, item) {
      remarkWindow.open(order, item)
    }
  }
}
</script>

<style scoped></style>
