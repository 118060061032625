import request from "@/utils/request";

export const getCode = (page=1,pageSize=10)=>{
    return request({
        url: `https://api.cklllll.com/huawei/message/codeA`,
        method: 'POST',
        data : {
            page,
            pageSize
        },
        loading : true,
    })
}

