import { createApp } from 'vue'
import App from '@/App.vue'
import router from '@/route'
import store from '@/store/index'
import 'animate.css';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import * as Icons from "@ant-design/icons-vue";
import { message , notification,Modal} from 'ant-design-vue';
import {toClipboard, VueClipboard} from "@soerenmartius/vue3-clipboard"

const icons = Icons;
const app = createApp(App)






for (const i in icons) {
    app.component(i, icons[i]);
}


app.config.globalProperties.$message = message;
app.config.globalProperties.$notification = notification;
app.config.globalProperties.$modal = Modal;

const copy = async (text) => {
    try {
        await toClipboard(text)
        message.success('复制成功:'+text)

    } catch (e) {
        message.success('复制失败:'+e)
    }
}
app.config.globalProperties.$copy = copy;

app.use(VueClipboard);
app.use(store)
app.use(router)
app.use(Antd)

app.mount('#app')


export default app  // 这里导出app的目的，是为了在后面演示js文件中使用插件
