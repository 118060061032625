<template>
  <div v-if="$store.state.isLogin" class="float">
    <a-tooltip placement="leftTop">
      <template #title>软件设置</template>
      <a-button @click="open()" type="primary" shape="circle">
        <template #icon><setting-filled /></template>
      </a-button><br><br>
    </a-tooltip>
    <a-tooltip placement="leftTop">
      <template #title>客服微信:zhoujunqian8</template>
      <a-button type="primary" shape="circle">
        <template #icon><wechat-outlined /></template>
      </a-button><br><br>
    </a-tooltip>
    <a-tooltip placement="leftTop">
      <template #title>客服QQ:574120727</template>
      <a-button type="primary" shape="circle">
        <qq-outlined />
      </a-button><br><br>
    </a-tooltip>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import setting from "@/components/server/setting/index"
export default {
  name: "floatWindow",
  setup(){
    const store = useStore()
    return{
      store
    }
  },
  methods:{
    open(){
      setting.open()
    }
  }
}
</script>

<style scoped>
  .float{
    position: fixed;
    top: 70%;
    right:12px;
    margin-top: -.1rem;
    font-size: .13rem;
    text-align: right;
    z-index: 19999999
  }
</style>
