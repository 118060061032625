<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择你要分配智云上货的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="选择您要使用进行标题净化的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>
    <div style="text-align: center">
      <a-textarea
          v-model:value="urls"
          @change="inputUrls"
          style="width: 98%;"
          placeholder="请在这里输入要上货的淘宝链接或者淘宝URL(一行一个)"
          :auto-size="{ minRows: 10, maxRows: 10}"
      />
    </div>
    <div v-if="checkUseShopIds.length > 0 || failUseShopIds.length > 0" style="margin-left: 16px;margin-top: 16px">
      <span>检测到可使用智云上货的店铺:</span><br><br>
      <a-tag @click="$copy(shop.shopName)" color="#87d068" v-for="shop in checkUseShopIds" :key="shop.shopId"><a>{{shop.shopName}}</a></a-tag>
      <br><br>
      <span>检测到不可使用智云上货的店铺:</span><br><br>
      <a-tag @click="$copy(shop.shopName)" color="#f50" v-for="shop in failUseShopIds" :key="shop.shopId"><a>{{shop.shopName}}</a></a-tag>
      <br>
    </div>
    <div style="margin-left: 16px;margin-top: 16px">
      <a-divider></a-divider>
      <span>当前输入上货商品数量:{{itemUrl.length}}个</span>
    </div>
  </div>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" >
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="10">
              分配类型:&nbsp;&nbsp;
              <a-select
                  size="small"
                  v-model:value="checkType"
                  placeholder="选择上货任务分配类型"
              >
                <a-select-option name="平均分配" value="mean">
                  平均分配
                </a-select-option>
                <a-select-option name="数量分配" value="num">
                  数量分配
                </a-select-option>
              </a-select>
              <a-divider v-if="checkType=='num'"  type="vertical"></a-divider>
              <a-input v-if="checkType=='num'" size="small" style="width: 128px" placeholder="分配数量" v-model:value="num"></a-input>
            </a-col>
            <a-col :span="13" style="text-align: right">
              <a-checkbox v-model:checked="uploadChecked">直接提交上货</a-checkbox>
              <a-divider  type="vertical"></a-divider>
              <a-button @click="useCheckShopIds">
                <svg t="1679470074344" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5024" width="32" height="32"><path d="M369.792 704.32L930.304 128 1024 223.616 369.984 896l-20.288-20.864-0.128 0.128L0 516.8 96.128 423.68l273.664 280.64z" fill="#515151" p-id="5025"></path></svg>
                检测店铺
              </a-button><a-divider  type="vertical"></a-divider>
              <a-button @click="useImgzoneClean" :disabled="checkUseShopIds.length == 0" type="primary"><template #icon>
                <delete-outlined />
              </template>清理图片</a-button><a-divider  type="vertical"></a-divider>
              <a-button @click="upLoad" :disabled="checkUseShopIds.length == 0" type="primary"><template #icon>
                <svg t="1679470047900" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3942" width="32" height="32"><path d="M938.666667 1024 85.333333 1024c-46.933333 0-85.333333-38.4-85.333333-85.333333L0 597.333333c0-23.466667 19.2-42.666667 42.666667-42.666667s42.666667 19.2 42.666667 42.666667l0 320c0 12.8 8.533333 21.333333 21.333333 21.333333l810.666667 0c12.8 0 21.333333-8.533333 21.333333-21.333333L938.666667 597.333333l0 0c0-23.466667 19.2-42.666667 42.666667-42.666667s42.666667 19.2 42.666667 42.666667l0 341.333333C1024 985.6 985.6 1024 938.666667 1024zM226.133333 268.8l256-256 0 0C488.533333 4.266667 499.2 0 512 0s23.466667 4.266667 29.866667 12.8l0 0 256 256 0 0c8.533333 8.533333 12.8 19.2 12.8 29.866667 0 23.466667-19.2 42.666667-42.666667 42.666667-12.8 0-23.466667-4.266667-29.866667-12.8l0 0L554.666667 145.066667 554.666667 725.333333c0 23.466667-19.2 42.666667-42.666667 42.666667s-42.666667-19.2-42.666667-42.666667L469.333333 145.066667l-183.466667 183.466667 0 0C279.466667 337.066667 268.8 341.333333 256 341.333333c-23.466667 0-42.666667-19.2-42.666667-42.666667C213.333333 285.866667 217.6 275.2 226.133333 268.8L226.133333 268.8z" p-id="3943" fill="#ffffff"></path></svg>
              </template>开始上货</a-button>
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import { message,Modal } from 'ant-design-vue'
import { createVNode ,getCurrentInstance ,reactive,ref } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {getItem as storageGet} from "@/utils/storage";
import {checkShopIds, imgzoneClean, uploadTasks} from "./api/item"

export default {
  name: "zhiyun",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      checkType : "mean",
      num : 2000,
      checkUseShopIds : [],
      failUseShopIds : [],
      itemUrl : [],
      urls : "",
      uploadChecked : false
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(!useShopIds.value.includes(shop.shopId)){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods:{
    openWindow(url){
      window.open(url)
    },
    clearItem(){
      if (this.words==""){
        Modal.error({
          title: '执行失败',
          content: '使用的检测关键词不能为空',
        });
        return
      }
      const appContext = getCurrentInstance()
      const shopGroup = reactive(this)
      Modal.confirm({
        title: '确认询问',
        content: '是否清理选中的店铺的商品标题?',
        okText: '确认',
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: '取消',
        appContext,
        onOk(data=shopGroup) {
          if (data.useShopIds.length<1){
            Modal.error({
              title: '执行失败',
              content: '你还没有选择店铺',
            });
          }
        },
      });
    },
    useCheckShopIds(){
      checkShopIds(this.useShopIds).then(res=>{
        if (res.success){
          message.success("获取智云管家使用权限完毕,您可以分配上货了")
          this.checkUseShopIds = []
          this.failUseShopIds = []
          res.data.forEach(shop=>{
            if (shop.success){
              this.checkUseShopIds.push(shop)
            }else{
              this.failUseShopIds.push(shop)
            }
          })
        }else {
          Modal.warning({
            title : "检查智云管家错误",
            content : res.msg
          })
        }
      })
    },
    inputUrls (){
      this.itemUrl = []
      this.urls.split("\n").forEach(url=>{
        let itemId =  url.match(/[1-9][0-9]{4,}/g)
        if (itemId && itemId.length > 0){
          this.itemUrl.push(itemId[0])
        }
      });
    },
    upLoad(){
      if (this.checkType == "mean"){
        let index = 0
        this.checkUseShopIds.forEach(shop=>{
          shop.itemIds = []
        })
        //分配任务
        this.itemUrl.forEach(itemId=>{
          if (index >= this.checkUseShopIds.length){
            index = 0
          }
          this.checkUseShopIds[index].itemIds.push(itemId)
          index++
        })
      }else {
        let index = 0
        this.checkUseShopIds.forEach(shop=>{
          shop.itemIds = []
        })
        //分配任务
        this.itemUrl.forEach(itemId=>{
          if (index >= this.checkUseShopIds.length){
            index = 0
          }
          if (this.checkUseShopIds[index].itemIds.length >= this.num){
            index++
          }else{
            this.checkUseShopIds[index].itemIds.push(itemId)
          }
        })
      }

      uploadTasks(this.checkUseShopIds,this.uploadChecked).then(res=>{
        if (res.success){
          Modal.success({
            title : "成功",
            content : "提交上货任务成功"
          })
        }else{
          Modal.error({
            title : "失败",
            content : res.msg
          })
        }
      })
    },
    useImgzoneClean(){
      imgzoneClean(this.checkUseShopIds).then(res=>{
        if (res.success){
          Modal.success({
            title : "成功",
            content : "提交清理图片成功"
          })
        }else{
          Modal.error({
            title : "失败",
            content : res.msg
          })
        }
      })
    }
  },
  watch:{

  }
}
</script>

<style>
.bottom{
  position: fixed;
  bottom:0px;
  left:0px;
  height:72px;
  width:100%;
}


</style>
