import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const checkShopIds = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarketXiongDa/server`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在检测熊大店铺是否可用,详细进度请查看获芸增强组件"
        })
    }
}

export const exprotServerOrderList = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarket/serverOrder`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在拉取服务市场订单信息,详细进度请查看获芸增强组件"
        })
    }
}


// this.checkUseShopIds,uploadChecked,this.price,this.ThreadPool,jdKey,tbKey
export const uploadTasks = (uploadTasks,uploadChecked,multiply,ThreadPool,jdKey,tbKey)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarketXiongDa/xiongda`,
                parameter : JSON.stringify({
                    "tasks" : uploadTasks,
                    "upload" : uploadChecked,
                    multiply,
                    ThreadPool,
                    jdKey,
                    tbKey
                }),
                uninstall : false
            },
            loading : true,
            text : "正在提交上货任务"
        })
    }
}
export const delItems = (shopIds,words,num,model,ThreadPool)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarketXiongDa/delItems`,
                parameter : JSON.stringify({
                    shopIds,
                    words,
                    num,
                    model,
                    ThreadPool
                }),
                uninstall : false
            },
            loading : true,
            text : "正在提交上货任务"
        })
    }
}
export const failItems = (shopIds,jdKey,tbKey,multiply,OrdAmtIndex,OrdNumIndex,ThreadPool)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarketXiongDa/replenish`,
                parameter : JSON.stringify({
                    shopIds,
                    jdKey,
                    tbKey,
                    multiply,
                    OrdAmtIndex,
                    OrdNumIndex,
                    ThreadPool
                }),
                uninstall : false
            },
            loading : true,
            text : "正在提交上货任务"
        })
    }
}

export const imgzoneClean = (uploadTasks)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarketXiongDa/imgzoneClean`,
                parameter : JSON.stringify({
                    "tasks" : uploadTasks,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在请求清理"
        })
    }
}


export default {
    checkShopIds,uploadTasks,imgzoneClean
}
