<template>
  <a-modal :centered="true" v-model:visible="visible" :width="width" :footer="null" :destroyOnClose="true" :maskClosable="true"  @cancel="cancel">
    <div style="text-align: center">
      <img :src="src" />
    </div>
  </a-modal>
</template>
<script>
import { defineComponent} from 'vue'
import { Modal} from 'ant-design-vue'


export default defineComponent({
  name : "image",
  components:{
    aModal : Modal,
  },
  props: {
    src : null,
    width : null,
  },
  data(){
    return{
      visible : false
    }
  },
  setup() {

  },
  mounted() {
    console.log(11111111111)
    this.visible = true
  },
  methods:{

  }
})
</script>
