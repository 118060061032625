<template>
  <a-card id="tool" hoverable style="text-align: left;width: 99%" >
    <a-tabs v-model:activeKey="searchParams.tabId" @change="getOrderList(true)">
      <a-tab-pane key="1" tab="全部订单"></a-tab-pane>
      <a-tab-pane key="2" tab="隔离订单" force-render></a-tab-pane>
      <a-tab-pane key="3" tab="已拿货待发货订单"></a-tab-pane>
      <a-tab-pane key="4" tab="已发货订单"></a-tab-pane>
      <a-tab-pane key="5" tab="已完成订单"></a-tab-pane>
      <a-tab-pane key="6" tab="已取消订单"></a-tab-pane>
    </a-tabs>
    <span style="font-size: 14px"><Shop-outlined />&nbsp;选择店铺(可输入关键字搜索):</span><br>
    <a-row :gutter="20" style="margin-top: 12px">
      <a-col :span="18" >
        <a-select
            v-model:value="useShopIds"
            mode="multiple"
            style="width: 100%;"
            :allowClear="true"
            show-search
            :filter-option="filterOption"
            @change="selectShopIds"
            :max-tag-count="10"
            placeholder="请选择您要使用的店铺(可多选)"
        >
          <a-select-option  v-for="shop in shopList" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
            {{shop.shopName}}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="6" style="text-align: center">
        <a-button  @click="seleceGroup=true" type="primary" size="small">分组选择</a-button>&nbsp;&nbsp;
        <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
          <div>
            <a-checkbox-group v-model:value="selectGroupId">
              <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
            </a-checkbox-group>
            <a-divider></a-divider>
            <div style="text-align: right;margin-top: 12px">
              <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
            </div>
          </div>
        </a-modal>
        <a-button  @click="useShopIds=[];shopList.forEach(shop=>{useShopIds.push(shop.shopId)})" type="primary" size="small">全部选择</a-button>&nbsp;&nbsp;
        <a-button @click="useShopIds=[]" size="small">取消选择</a-button>
      </a-col>
    </a-row>
    <br>
    <a-row :gutter="20">
      <a-col :span="6" >
        <span style="font-size: 14px"><field-time-outlined />&nbsp;选择日期:</span><br>
        <a-range-picker
            style="margin-top: 12px;"
            :showTime="true"
            :showNow="true"
            :showToday="true"
            v-model:value="searchParams.selectTime"
            size="small" />
      </a-col>
      <a-col :span="6" >
        <span style="font-size: 14px"><file-search-outlined />&nbsp;订单号:</span><br>
        <a-textarea
            style="margin-top: 12px"
            :pressEnter="getOrderList"
            size="small"
            v-model:value="searchParams.orderIds"
            placeholder="请输入内容,多个订单号请以,号或者换行分开"
            :auto-size="{ minRows: 2, maxRows: 2 }"

        />
      </a-col>
      <a-col :span="6" >
        <span style="font-size: 14px"><file-search-outlined />&nbsp;省份:</span><br>
        <a-textarea
            style="margin-top: 12px"
            size="small"
            placeholder="请输入内容,多个订单号请以,号或者换行分开"
            :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </a-col>
      <a-col :span="6"  style="text-align: right">
          <br><br>
          <a-checkbox v-model:checked="searchParams.twoHour">只显示下单超过2小时的订单</a-checkbox><a-divider type="vertical" />&nbsp;
          <a-button @click="getOrderList(true)" type="primary"><search-outlined />搜索订单</a-button>
      </a-col>
      <a-col :span="6">
        <div style="text-align: left;margin-top: 16px"><h5 style="color: #909399">京东订单状态查询(可多选,不选为全部状态):</h5></div>
        <a-checkbox-group v-model:value="searchParams.orderStatus" :options="orderStatusCheckOptions" />
      </a-col>
      <a-col :span="18" style="margin-top: 14px">
        <div style="text-align: left;top: 14px"><h5 style="color: #909399">外部订单状态查询(可多选,不选为全部状态):</h5></div>
        <a-checkbox-group v-model:value="searchParams.buyOrderStatus" :options="buyOrderStatusCheckOptions" />
      </a-col>
      <a-col :span="24" style="margin-top: 0px;">
        <a-collapse ghost :bordered="false" style="margin-left: 0">
          <a-collapse-panel :showArrow="false" key="1">
            <template #header>
                <a style="font-size: 14px;margin-top: 12px" type="primary" size="small" @click="searchParams.moreSearchParamsDiv=!searchParams.moreSearchParamsDiv" >
                  <down-outlined v-if="!searchParams.moreSearchParamsDiv" />
                  <up-outlined v-if="searchParams.moreSearchParamsDiv" />
                  &nbsp;{{!searchParams.moreSearchParamsDiv?'点击展开更多搜索条件':'点击收起更多搜索条件'}}
                </a>
            </template>
              <a-row class="animate__animated animate__flipInX" :gutter="86" style="margin-top: -12px">
                <a-col :span="5" style="text-align: left;margin-top:12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">超时预警:</h5></div>
                  <a-checkbox-group v-model:value="searchParams.warnType" :options="warnTypeCheckOptions" />
                </a-col>
                <a-col :span="5" style="text-align: left;margin-top:12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">超时揽发:</h5></div>
                  <a-checkbox-group v-model:value="searchParams.breakAgreementType" :options="breakAgreementTypeCheckOptions" />
                </a-col>
                <a-col :span="5" style="text-align: left;margin-top:12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">商家备注:</h5></div>
                  <a-checkbox-group v-model:value="searchParams.isRemark" :options="isRemarkCheckOptions" />
                </a-col>
                <a-col :span="5" style="text-align: left;margin-top:12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">云仓状态:</h5></div>
                  <a-checkbox-group v-model:value="searchParams.cloudOutExpressNo" :options="cloudOutExpressNoCheckOptions" />
                </a-col>
                <a-col :span="4" style="text-align: left;margin-top:12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">上家物流:</h5></div>
                  <a-checkbox-group v-model:value="searchParams.expressStatusStrCN" :options="expressStatusStrCNCheckOptions" />
                </a-col>
                <a-col :span="6" style="text-align: left;margin-top: 12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">采购账号:</h5></div>
                  <a-input size="small" placeholder="采购账号关键字"   v-model:value="searchParams.nickname"></a-input>
                </a-col>
                <a-col :span="6" style="text-align: left;margin-top: 12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">采购单号:</h5></div>
                  <a-input size="small" placeholder="外部关联订单号"   v-model:value="searchParams.order_sn"></a-input>
                </a-col>
                <a-col :span="6" style="text-align: left;margin-top: 12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">收货人:</h5></div>
                  <a-input size="small" placeholder="购买人姓名"   v-model:value="searchParams.buyerName"></a-input>
                </a-col>
                <a-col :span="6" style="text-align: left;margin-top: 12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">sku:</h5></div>
                  <a-input size="small" placeholder="请输入sku编码"   v-model:value="searchParams.sku"></a-input>
                </a-col>
                <a-col :span="6" style="text-align: left;margin-top: 12px">
                  <div style="text-align: left;top: 14px"><h5 style="color: #909399">上家快递单号:</h5></div>
                  <a-textarea size="small" placeholder="请输入内容,多个订单号请以,号或者换行分开"   v-model:value="searchParams.expressNo"></a-textarea>
                </a-col>
              </a-row>
          </a-collapse-panel>
        </a-collapse>
      </a-col>
    </a-row>
  </a-card>
  <br>
  <a-card id="orderList" style="text-align: left;width: 99%">
    <div style="text-align: center">
      <a-pagination @change="pageChange" v-model:pageSize="pageInfo.pageSize" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
    <a-divider></a-divider>
    <div>
      <a-space :size="16">
        <a-checkbox v-model:checked="allSelect" @click="orderList.forEach(order=>{order.select=true})">全部选中</a-checkbox>
        <a-button size="small" type="primary">导出选中订单
          <template #icon>
            <export-outlined />
          </template>
        </a-button>
      </a-space>
    </div>
    <a-divider></a-divider>
    <a-empty v-if="!orderList.length > 0"></a-empty>
    <orderList :orderList="orderList" :shopIdList="shopList"></orderList>
    <div style="text-align: center;margin-top: 56px">
      <a-pagination @change="pageChange" v-model:pageSize="pageInfo.pageSize" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
    <br>
  </a-card>


</template>

<script>
import dayjs from 'dayjs';
import {getOrderList,updateOrder} from "@/api/order";
import {useRouter} from "vue-router";
import {getItem,setItem} from "@/utils/session";
import {getItem as storageGet} from "@/utils/storage";
import { message } from 'ant-design-vue';
import useClipboard from 'vue-clipboard3'
import orderList from "../../components/page/orderNew/orderList.vue"
import {bindShopIdsDetail} from "@/api/shop";
import {chromeApp} from "@/components/page/orderNew/api";
import { ElNotification } from 'element-plus'

export default {
  name: "home",
  components:{
    orderList
  },
  data(){
    return{
      tabId : "0",
      seleceGroup : false,
      jdStatusOptions:[{
        "dStatus": "21",
        "statusCode": 5,
        "jStatus": "-3",
        "dStatusStr": "已锁定",
        "jStatusStr": "已锁定"
      }, {
        "dStatus": "-100",
        "jStatus": "-2",
        "statusCode": 4,
        "dStatusStr": "已取消",
        "jStatusStr": "已取消"
      }, {
        "statusCode": 3,
        "dStatus": "18",
        "jStatus": "19",
        "dStatusStr": "已完成",
        "jStatusStr": "已完成"
      }, {
        "statusCode": 2,
        "dStatus": "15",
        "jStatus": "16",
        "dStatusStr": "等待确认收货",
        "jStatusStr": "等待确认收货"
      }, {
        "statusCode": 1,
        "dStatus": "7",
        "jStatus": "8",
        "dStatusStr": "等待出库",
        "jStatusStr": "等待出库"
      }],
      orderStatusCheckOptions : [
        { label: '待出库', value: '8' },
        { label: '已出库', value: '16' },
        { label: '已完成', value: '19' },
        { label: '已取消', value: '-1' },
      ],
      warnTypeCheckOptions : [
        { label: '出库预警', value: '1' },
        { label: '揽件预警', value: '3' },
      ],
      breakAgreementTypeCheckOptions : [
        { label: '出库超时', value: '1' },
        { label: '揽件超时', value: '3' },
      ],
      isRemarkCheckOptions : [
        { label: '有备注', value: '1' },
        { label: '无备注', value: '-1' },
      ],
      cloudOutExpressNoCheckOptions : [
        { label: '云仓未转发', value: '-1' },
        { label: '云仓已转发', value: '1' },
      ],
      expressStatusStrCNCheckOptions : [
        { label: '已签收', value: '已签收' },
      ],
      buyOrderStatusCheckOptions : [
        { label: '未拍单', value: '-1' },
        { label: '同步异常', value: '-2' },
        { label: '待支付', value: '2' },
        { label: '待发货', value: '3' },
        { label: '待收货', value: '4' },
        { label: '待评价', value: '5' },
        { label: '交易已取消', value: '7' },
        { label: '退款成功', value: '6' },
        { label: '退款待用户处理', value: '303' },
        { label: '退款待商家处理', value: '304' },
        { label: '退货待商家签收', value: '305' },
      ],
      shopIds :[],
      pickerOptions :{
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近15天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近90天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      useShopIds :[],
      allSelect : false,
      orderList : [],
      shopList : [],
      pageInfo : {
        page : 1,
        pageSize : 10,
        total : 0,
      },
      searchParams : {
        tabId : "1",
        moreSearchParamsDiv : false,
        selectTime: [dayjs(new Date()).subtract(1, 'month'),dayjs(new Date())],
        orderIds : "",
        province : [],
        nickname : "",
        order_sn : "",
        twoHour : false,
        sku : "",
        block : false,
        buyerName : "",
        updateTime : null,
        orderStatus : ["8"],
        buyOrderStatus : [],
        buyOrderStatusStrCN : [],
        cloudExpressNo : [],
        cloudOutExpressNo : [],
        breakAgreementType : [],
        expressNo : "",
        warnType: [],
        expressStatusStrCN : [],
        isRemark : [],
      },
      selectGroupId : [],
      userInfo : storageGet("userinfo"),
      group : storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group'])
    }
  },
  setup(){
    const router = useRouter()
    const { toClipboard } = useClipboard()
    const copy = async (text) => {
      try {
        await toClipboard(text)
        message.success('复制成功:'+text)

      } catch (e) {
        message.success('复制失败:'+e)
      }
    }
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return{
      router,copy,filterOption,storageGet
    }

  },
  mounted(){
    if(getItem("data")!=null && getItem("data")!=undefined){
        const data = getItem("data")
        this.shopIds = data.shopIds
        this.pageInfo = data.pageInfo
        this.searchParams.orderStatus = data.searchParams.orderStatus
        this.searchParams.buyOrderStatus = data.searchParams.buyOrderStatus
        this.searchParams.buyOrderStatusStrCN = data.searchParams.buyOrderStatusStrCN
      }
    this.getShopIds()
  },
  methods:{
    getJdStatus(status){
      let value = null
      for(let index in this.jdStatusOptions){
        const item = this.jdStatusOptions[index]
        if (item.jStatus.toString() === status.toString()){
          value = item
          break
        }
      }
      return value
    },
    getShopIds(list=[]){
      bindShopIdsDetail().then(res=>{
        if (res.success){
          this.shopIds = []

          if (list.length == 0){
            res.data.forEach(shop=>{
              this.useShopIds.push({
                label : shop.shopName,
                value : shop.shopId,
              })
              this.shopIds.push({
                label : shop.shopName,
                value : shop.shopId,
              })
            })
            this.shopList = res.data
          }
          this.getOrderList()
        }else {
          message.error(res.msg)
        }
      })
    },
    selectShopIds(){
      console.log(this.useShopIds)
    },
    getOrderList(page=false){
      if (this.searchParams.orderIds=="\n"){
        this.searchParams.orderIds==""
      }
      let shopIds = ""
      this.useShopIds.forEach((shop,index)=>{
        if (typeof(shop)!= "object"){
          shopIds+= shop
        }else{
          shopIds+= shop.value
        }

        if (index+1<this.useShopIds.length){
          shopIds+=","
        }
      })
      if (page){
        this.pageInfo.page=0
      }
      const data = `page=${this.pageInfo.page}&pagesize=${this.pageInfo.pageSize}&shopIds=${shopIds}&searchParams=${JSON.stringify(this.searchParams)}`
      setItem("data",this.$data)
      getOrderList(data).then(res=>{
        if (res.success){
          //转换数据某些数据为object
          if (res.data.length < 1){
            this.orderList = []
            this.pageInfo.total = res.data.total
            return
          }
          res.data.data.forEach(order=>{
            order.select = this.allSelect?true:false;
            try {
              order.address = JSON.parse(order.address)
              // eslint-disable-next-line no-empty
            } catch (e) {}
            if (typeof (order.halleyOrderisDetail) === 'string'){
              order.halleyOrderisDetail = JSON.parse(order.halleyOrderisDetail)
            }            if (typeof(order.realPrice)=="string"){
              order.realPrice = order.realPrice.replace("¥","");
            }
            try {
              order.orderBankDetailDTO = JSON.parse(order.orderBankDetailDTO)
              // eslint-disable-next-line no-empty
            } catch (e) {}
            try {
              order.order_Info = JSON.parse(order.order_Info)
            }
                // eslint-disable-next-line no-empty
            catch (e) {}
            try {
              order.buyAddressInfo = JSON.parse(order.buyAddressInfo)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
              order.buyerInfo = JSON.parse(order.buyerInfo)
              // eslint-disable-next-line no-prototype-builtins
              if ( order.buyerInfo.hasOwnProperty("buyerInfo")){
                order.buyerInfo =  order.buyerInfo.buyerInfo
              }else{
                order.buyerInfo = {
                  data:{
                    user:{
                      jdScore:0,
                      reg:null,
                      goodRate:null,

                    }
                  }
                }
              }
              // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
              order.shopInfo = JSON.parse(order.shopInfo)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            order.orderItems.forEach(item=>{
              item.expressInfo = JSON.parse(item.expressInfo)
              item.orderItems = JSON.parse(item.orderItems)
              item.buyAddressInfo = JSON.parse(item.buyAddressInfo)
            })
          })
          this.orderList = res.data.data
          this.shopIds = res.data.shopIds
          this.pageInfo.total = res.data.total


          chromeApp.callback((e)=>{
            try {
              let msg = e.data
              switch (msg.type){
                case 'refresh_order':
                  this.orderList.forEach(order=>{
                    if (order.orderId.toString() === msg.data.orderId.toString()){
                      order.orderItems.forEach(item=>{
                        if (item.skuId.toString() === msg.data.skuId.toString()){
                          item.order_sn = msg.data.order_sn
                          item.buyOrderType = msg.data.buyOrderType
                          item.buyCreateTime = msg.data.buyCreateTime
                          if (msg.data.buyOrderStatus !== null){
                            item.buyOrderStatus = msg.data.buyOrderStatus
                          }
                          if (msg.data.buyOrderStatusStrCN !== null){
                            item.buyOrderStatusStrCN = msg.data.buyOrderStatusStrCN
                          }
                          if (msg.data.nickname){
                            item.nickname = msg.data.nickname
                          }
                          if(msg.data.buySellerName){
                            item.buySellerName = msg.data.buySellerName
                          }
                          item.buyDeliveryAddress = msg.data.buyDeliveryAddress
                          if (msg.data.notifi === true){
                            ElNotification({
                              title: '刷新订单',
                              message: '订单：'+order.orderId.toString()+' 下单成功，已转移到已拿货列表。',
                              type: 'success',
                              position: 'bottom-right',
                            })
                          }

                        }
                      })
                    }
                  })
                  break
                case 'checkOrderStatus':
                  // 更新京东订单信息
                  if (msg.data.newOrderInfo === null){
                    message.error('订单信息获取失败')
                    return;
                  }
                  this.orderList.forEach(order=>{
                    const jdStatus = this.getJdStatus(msg.data.newOrderInfo.orderStatus)
                    if (jdStatus !== null){
                      order.orderStatus = parseInt(msg.data.newOrderInfo.orderStatus)
                      order.orderStatusStrCN = msg.data.newOrderInfo.orderStatusStrCN
                    }
                  })
                  updateOrder({
                    orderId:msg.data.newOrderInfo.orderId?msg.data.newOrderInfo.orderId:null,
                    orderStatus:msg.data.newOrderInfo.orderStatus?msg.data.newOrderInfo.orderStatus:null,
                    orderStatusStrCN:msg.data.newOrderInfo.orderStatus?msg.data.newOrderInfo.orderStatusStrCN:null,
                  }).then(res =>{
                    if(res.success === false){
                      message.error(res.msg)
                    }
                  })
                  break
              }
            }catch (e){
              console.error(e)
            }
          })



        }else{
          this.orderList = []
          this.pageInfo.total = 0
          message.error(res.msg)
        }
      })
    },
    transformTime(timestamp) {
      let time = new Date(timestamp*1000);
      let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
      let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
      let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
      M = M < 10 ? "0"+ M : M;
      d = d < 10 ? "0"+ d : d;
      h = h < 10 ? "0"+ h : h;
      m = m < 10 ? "0"+ m : m;
      s = s < 10 ? "0"+ s : s;
      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    pageChange(){
      this.getOrderList(false)
    },
    importGroup(){
      console.log(this.selectGroupId)
      if (this.selectGroupId.length < 1) {
        this.seleceGroup = false
        message.warning("你没有选择任何店铺")
        return
      }
      this.useShopIds = []
      this.group.forEach(g=>{
        this.selectGroupId.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(this.useShopIds.includes(shop.shopId)==false){
                this.useShopIds.push(parseInt(shop.shopId))
              }
            })
          }
        })
      })
      this.seleceGroup = false
      message.info("已选择您选择的分组店铺")
    }
  }
}
</script>

<style>
  .shop{
    text-align: left;
  }

  .a-select__tags {
    width: 100%;
  }

</style>
