/*
 * @Descripttion:
 * @version:
 * @Date: 2021-04-20 11:06:21
 * @LastEditors: huzhushan@126.com
 * @LastEditTime: 2021-04-21 09:36:55
 * @Author: huzhushan@126.com
 * @HomePage: https://huzhushan.gitee.io/vue3-element-admin
 * @Github: https://github.com/huzhushan/vue3-element-admin
 * @Donate: https://huzhushan.gitee.io/vue3-element-admin/donate/
 */
import request from '@/utils/request'


// 获取登录用户信息
export const ShopIds = () => {
    return request({
        url: 'shop/manage/bindShopIds',
        method: 'GET',
    })
}

// 获取店铺信息
export const info = (shopIds,loading=true) => {
    return request({
        url: 'shop_manage/info/info?shopIds='+JSON.stringify(shopIds),
        method: 'GET',
        loading : loading
    })
}

// 获取店铺分组信息
export const shopGroup = (loading=false) => {
    return request({
        url: 'shop_manage/info/shopGroup',
        method: 'GET',
        loading : loading
    })
}



// 设置店铺登录账号密码
export const setShopUser = (shopId,userName,passWord,loading=false) => {
    return request({
        url: 'shop_manage/info/setShopUser',
        method: 'POST',
        data : {
            shopId : shopId,
            userName : userName,
            passWord : passWord,
        },
        loading : loading
    })
}


// 保存店铺分组信息
export const savaGroup= (group,loading=false) => {
    return request({
        url: 'shop_manage/info/savaGroup',
        method: 'POST',
        data : {
            group : group,
        },
        loading : loading
    })
}
