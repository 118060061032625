<template>
  <a-modal v-model:visible="visibleValue" width="80%" title="订单采购记录" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <a-table :columns="columns" :data-source="list" :pagination="false" bordered>
      <template #bodyCell="{ column, record }">
        <template v-if="column.key === 'shopName'">
          <div>{{record.shopName}}</div>
        </template>
        <template v-if="column.key === 'jdOrderSn'">
          <a href="javascript:void(0)"   @click="viewJdOrder(record.jdOrderSn,thor)"> {{record.jdOrderSn}}</a>
          <a href="javascript:void(0)"  @click="copyVal(''+record.orderSn)"> <copy-outlined /></a>
        </template>
        <template v-if="column.key === 'buyPlat'">
          <div>{{transformOrderType(record.buyPlat)}}</div>
        </template>
        <template v-if="column.key === 'orderSn'">
          <a @click="jumpBuyPlat(record.buyPlat,record.orderSn,record.buyerName)">{{record.orderSn}} </a>
          <a href="javascript:void(0)"  @click="copyVal(''+record.orderSn)"> <copy-outlined /></a>
        </template>
        <template v-if="column.key === 'goodsName'">
          <div style="display: flex;flex-flow: row;justify-content: flex-start">
            <a-image
                :width="50"
                :src="record.image"
            ></a-image>
            <a style="margin-left: 5px;" :href="record.linkUrl"  target="_blank" >{{record.goodsName}}<br/>{{record.skuName}}</a>
          </div>
        </template>
        <template v-if="column.key === 'orderCreateTime'">
          <div>{{record.orderCreateTime}}</div>
        </template>
        <template v-if="column.key === 'buyerName'">
          <div>{{record.buyerName}}</div>
        </template>
        <template v-if="column.key === 'action'">
          <div>
            <a-popconfirm
                title="是否解除关联已采购的订单?"
                ok-text="确定"
                cancel-text="取消"
                @confirm="removeSku(record.id)"
            >
              <a-button   style="margin-top: 12px"  size="small" danger>
                <delete-outlined />解除关联
              </a-button>
            </a-popconfirm>
          </div>
        </template>
      </template>
    </a-table>
    <div style="margin-top: 20px">
      <a-pagination @change="pageChange" v-model:pageSize="pageInfo.pageSize" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
  </a-modal>
</template>
<script >

import {defineComponent} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {copy} from "@/api/utils"
import {chromeZYApp, delSku, getSkus} from "@/components/page/ziYinOrder/api";
export default defineComponent({
  name : "buyHistoryWindow",
  components:{
    aModal : Modal,
  },
  data(){
    return{
      visibleValue:false,
      list:[],
      jdOrderSn:null,
      jdSkuId: null,
      visible: false,
      pageInfo : {
        page : 1,
        pageSize : 10,
        total : 0,
      },
      thor:null,
      columns:[
        {
          title: '店铺名',
          dataIndex: 'shopName',
          key: 'shopName',
        },
        {
          title: '订单号',
          dataIndex: 'jdOrderSn',
          key: 'jdOrderSn',
        },
        {
          title: '采购平台',
          dataIndex: 'buyPlat',
          key: 'buyPlat',
        },
        {
          title: '采购订单号',
          dataIndex: 'orderSn',
          key: 'orderSn',
        },
        {
          title: '采购商品名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
        },
        {
          title: '采购账号',
          dataIndex: 'buyerName',
          key: 'buyerName',
        },
        {
          title: '采购时间',
          dataIndex: 'orderCreateTime',
          key: 'orderCreateTime',
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
        },
      ]
    }
  },
  mounted() {

  },
  methods:{
    pageChange(){
      this.getSkuList(false)
    },
    copyVal(value){
      return copy(value)
    },
    initData(jdOrderSn,jdSkuId,visible = false,thor){
      this.jdOrderSn = jdOrderSn
      this.jdSkuId = jdSkuId
      this.visible = visible
      this.visibleValue = this.visible === null?false:this.visible
      this.thor = thor
      this.getSkuList()
    },
    transformOrderType(value){
      if (value === 'taobao'||value === 'tmall'){
        return '淘宝'
      }else if (value === '1688'){
        return '1688'
      }else if(value === 'pdd'){
        return '拼多多'
      }
    },
    removeSku(id){
      delSku({
        id:id
      }).then(res =>{
        if (res.success){
          message.success(res.msg)
          this.getSkuList()
        }else{
          message.error(res.msg)
        }
      })
    },
    getSkuList(init = false){
      if (init){
        this.pageInfo.page=1
      }
      getSkus({
        ...this.pageInfo,
        jdOrderSn:this.jdOrderSn,
        jdSkuId:this.jdSkuId
      }).then(res =>{
        if (res.success){
          if (res.data.length < 1){
            this.list = []
            this.pageInfo.total = res.data.total
          }else{
            this.list = res.data.data
            this.pageInfo.total = res.data.total
          }
        }else{
          message.error(res.msg)
        }
      })
    },
     viewJdOrder(jdOrderSn,thor){
      chromeZYApp.send(JSON.stringify({
        type:'viewJdOrder',
        data:{
          thor:thor,
          jdOrderSn:jdOrderSn
        },
        success : false,
      }))
    },
    jumpBuyPlat(buyPlat,orderSn,username){
      let url = null
      if (buyPlat === 'pdd'){
        // 拼多多
        url = 'https://mobile.yangkeduo.com/order.html?order_sn='+orderSn
      }else if (buyPlat === 'taobao' || buyPlat === 'tmall'){
        // 淘宝
        url = 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+orderSn
      }else if ( buyPlat === '1688'){
        // 1688
      }
      let data ={
        url:url,
        username:username,
        buyPlat:buyPlat
      }
      chromeZYApp.send(JSON.stringify({
        type:'viewPurchaseOrder',
        data:data,
        success : false,
      }))
    }
  }
})
</script>
