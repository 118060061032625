import { createVNode, render } from 'vue'
import buyWindow from '@/components/server/buyWindow/buyWindow'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)




// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}



const buy = {
    open:(order,item)=>{
        const vnode = createVNode(buyWindow, { order:order, item:item,onClose : render(null, div)})
        renderMessage(vnode)
    },
}

export default buy
