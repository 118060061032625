import { createVNode, render } from 'vue'
import image from './viewImage'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)




// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}



const viewImage = (src,width="60%")=>{
    const vnode = createVNode(image, { src,width ,onClose : render(null, div)})
    renderMessage(vnode)
}

export default viewImage
