

export const chromeApp = {
    send : (data)=>{
        if (typeof(data)==='object'){
            window.postMessage(JSON.stringify(data))
        }else{
            window.postMessage(data)
        }
    },
    callback : (call)=>{
        window.addEventListener("message",(e)=>{
            if (e.origin === window.location.origin){
                call(e)
            }
        })
    }
}



