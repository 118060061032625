import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const getBrands = (shopIds,authStatus=null,checkStatus=null)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/brand/getBrand`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "authStatus" : authStatus,
                    'checkStauts' : checkStatus
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}



export const savaBrand = (shopIds,brands,authorizationImages=[],businessImages=[])=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/savaBrand/savaBrand`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "brands" : brands,
                    'authorizationImages' :authorizationImages,
                    'businessImages' : businessImages
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
