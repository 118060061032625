import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const clearSale = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/clearForSale`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export const recoverItem = (shopIds,time)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/recoverItem`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "time" : time
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const upForSale = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/upForSale`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const downOnSale = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/downOnSale`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const exportSystemDown = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/exportSystemDown`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export const exportItem = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/exportItem`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export default {
    clearSale,exportSystemDown,downOnSale,upForSale,exportItem
};
