import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const newIcon = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/newIcon`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export const savaBrand = (shopIds,brands,authorizationImages=[],businessImages=[])=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/savaBrand/savaBrand`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "brands" : brands,
                    'authorizationImages' :authorizationImages,
                    'businessImages' : businessImages
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}




export const add = (data)=>{
    return request({
        url: `jingdong/brand/add`,
        method: 'POST',
        loading : true,
        data
    })
}




export const query = (data)=>{
    return request({
        url: `jingdong/brand/query`,
        method: 'POST',
        loading : true,
        data
    })
}
