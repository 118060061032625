<template>
  <div v-if="$store.state.isLogin" style="margin-top: 12px" >
    <a-row>
      <a-col :span="18" style="text-align: left">
        <a-space :size="12">
        <a-avatar size="small" :src="require('@/assets/avatar.png')" />
        <a-button color="#2db7f5" style="font-size: 12px" size="small"><user-outlined />&nbsp;&nbsp;{{userinfo.userName}}</a-button>
        <a-button color="#2db7f5" style="font-size: 12px" size="small"><shop-outlined />&nbsp;&nbsp;绑定店铺: {{JSON.parse(userinfo.shopIds).length}} 个</a-button>
        <a-button color="#2db7f5" style="font-size: 12px" size="small"><field-time-outlined />&nbsp;&nbsp;待出库: {{waiout}} 单</a-button>
        <a-button color="#2db7f5" style="font-size: 12px" size="small"><money-collect-outlined />&nbsp;&nbsp;拍单总额: {{profit.totalMoney.toFixed(2)}} 元</a-button>
        <a-button color="#2db7f5" style="font-size: 12px" size="small"><money-collect-outlined />&nbsp;&nbsp;拍单利润: {{profit.totalProfit.toFixed(2)}} 元</a-button>
        </a-space>
      </a-col>
      <a-col :span="6" style="text-align: right">
        <a-button @click="GetUserinfo(true)" style="font-size: 12px" size="small"><reload-outlined />刷新</a-button>&nbsp;&nbsp;
        <a-button @click="open()" style="font-size: 12px" size="small"><setting-outlined />设置</a-button>&nbsp;&nbsp;
        <a-button @click="logout" type="danger" style="font-size: 12px" size="small"><logout-outlined />退出</a-button>&nbsp;&nbsp;&nbsp;&nbsp;
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { useStore } from 'vuex'
import {reactive ,createVNode ,ref} from "vue"
import { getItem,removeItem } from '@/utils/storage'
import { Modal } from 'ant-design-vue'
import setting from "@/components/server/setting/index"
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {bindShopIdsDetail} from "@/api/shop";
import {toDayProfit} from "@/api/record";
import {GetUserinfo} from "@/api/login";


export default {
  name: "userInfo",
  setup(){
    const store = useStore()
    const userinfo = reactive(getItem("userinfo"))
    const setting = ref(false)
    const showSetting = ()=>{
      setting.value = true
    }
    const logout = ()=>{
        Modal.confirm({
          title: '是否退出当前登录账号?',
          icon: createVNode(ExclamationCircleOutlined),
          onOk() {
            removeItem("token")
            window.location.reload()
          },
          onCancel() {
            console.log('Cancel');
          },
        });
    }
    return{
      store,userinfo,setting,showSetting,logout,GetUserinfo
    }
  },
  data(){
    return{
      shopList : [],
      waiout : 0,
      profit : {
        "totalMoney": 0,
        "totalProfit": 0,
        "totalPurchase": 0,
        "yesterDayTotalMoney": 0,
        "yesterDayTotalProfit": 0,
        "yesterDayTotalPurchase": 0
      },
    }
  },
  mounted() {
    this.reload(false);
    setInterval(()=>{
      this.reload(false)
    },60000)
  },
  methods:{
    reload(loading=false){
      bindShopIdsDetail(loading).then(res=>{
        if (res.success){
          this.shopList = res.data
          this.waiout = 0
          this.shopList.forEach(shopId=>{
            this.waiout +=shopId.waitout
          })
        }
      })
      toDayProfit(loading).then(res=>{
            if (res.success){
              this.profit = res.data
            }
      })
    },
    open(){
      setting.open()
    }
  }
}
</script>

<style scoped>
.userInfoFloat{
  position: fixed;
  z-index: 19999999
}
</style>

