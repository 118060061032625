const summaryDay = {
    "shopDataModuleItemVOS": [{
    "thisMonthNumber": 0,
    "boxClstag": "pageclick|keycount|shop_data_box_20200701|1001",
    "realTimeClstag": "pageclick|keycount|shop_data_current_20200701|1001",
    "yesterdayClstag": "pageclick|keycount|shop_data_yesterday_20200701|1001",
    "realTimeNumber": 0,
    "thisMonthLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "offLineItemStatus": 1,
    "thisMonthClstag": "pageclick|keycount|shop_data_month_20200701|1001",
    "yesterdayLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "yesterdayNumber": 0,
    "defaultDefinition": "统计时间内，用户付款的总金额，包括先款订单的金额(在线支付、公司转账、邮局汇款等)和货到付款的金额。##统计时间范围如下：##实时：从今日00:00至您最近一次刷新本页面或点击刷新按钮。##昨日：昨日00:00-24:00。##本月：本月1日至昨日24:00。",
    "name": "成交总金额",
    "id": "1001",
    "realTimeLinkUrl": "//sz.jd.com/sz/view/realTime/realSummarys.html",
    "status": 1
}, {
    "thisMonthNumber": 0,
    "boxClstag": "pageclick|keycount|shop_data_box_20200701|1002",
    "realTimeClstag": "pageclick|keycount|shop_data_current_20200701|1002",
    "yesterdayClstag": "pageclick|keycount|shop_data_yesterday_20200701|1002",
    "realTimeNumber": 0,
    "thisMonthLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "offLineItemStatus": 1,
    "thisMonthClstag": "pageclick|keycount|shop_data_month_20200701|1002",
    "yesterdayLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "yesterdayNumber": 0,
    "defaultDefinition": "统计时间内，用户付款的总订单量，包括先款订单量(在线支付、公司转账、邮局汇款等)和货到付款订单量。##统计时间范围如下：##实时：从今日00:00至您最近一次刷新本页面或点击刷新按钮。##昨日：昨日00:00-24:00。##本月：本月1日至昨日24:00。",
    "name": "成交总单量",
    "id": "1002",
    "realTimeLinkUrl": "//sz.jd.com/sz/view/realTime/realSummarys.html",
    "status": 1
}, {
    "thisMonthNumber": 0,
    "boxClstag": "pageclick|keycount|shop_data_box_20200701|1003",
    "realTimeClstag": "pageclick|keycount|shop_data_current_20200701|1003",
    "yesterdayClstag": "pageclick|keycount|shop_data_yesterday_20200701|1003",
    "realTimeNumber": 0,
    "thisMonthLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "offLineItemStatus": 1,
    "thisMonthClstag": "pageclick|keycount|shop_data_month_20200701|1003",
    "yesterdayLinkUrl": "//sz.jd.com/sz/view/dealAnalysis/dealSummarys.html",
    "yesterdayNumber": 0,
    "defaultDefinition": "统计时间内，用户付款的总订单量，包括先款订单量(在线支付、公司转账、邮局汇款等)和货到付款订单量。##统计时间范围如下：##实时：从今日00:00至您最近一次刷新本页面或点击刷新按钮。##昨日：昨日00:00-24:00。##本月：本月1日至昨日24:00。",
    "name": "成交客户数",
    "id": "1003",
    "realTimeLinkUrl": "//sz.jd.com/sz/view/realTime/realSummarys.html",
    "status": 1
}, {
    "thisMonthNumber": 0,
    "boxClstag": "pageclick|keycount|shop_data_box_20200701|1004",
    "realTimeClstag": "pageclick|keycount|shop_data_current_20200701|1004",
    "yesterdayClstag": "pageclick|keycount|shop_data_yesterday_20200701|1004",
    "realTimeNumber": 0,
    "thisMonthLinkUrl": "//sz.jd.com/sz/view/viewflow/viewStats.html",
    "offLineItemStatus": 1,
    "thisMonthClstag": "pageclick|keycount|shop_data_month_20200701|1004",
    "yesterdayLinkUrl": "//sz.jd.com/sz/view/viewflow/viewStats.html",
    "yesterdayNumber": 0,
    "defaultDefinition": "统计时间内，用户付款的总订单量，包括先款订单量(在线支付、公司转账、邮局汇款等)和货到付款订单量。##统计时间范围如下：##实时：从今日00:00至您最近一次刷新本页面或点击刷新按钮。##昨日：昨日00:00-24:00。##本月：本月1日至昨日24:00。",
    "name": "总访客数",
    "id": "1004",
    "realTimeLinkUrl": "//sz.jd.com/sz/view/realTime/realSummarys.html",
    "status": 1
}, {
    "thisMonthNumber": 0,
    "boxClstag": "pageclick|keycount|shop_data_box_20200701|1005",
    "realTimeClstag": "pageclick|keycount|shop_data_current_20200701|1005",
    "yesterdayClstag": "pageclick|keycount|shop_data_yesterday_20200701|1005",
    "realTimeNumber": 0,
    "thisMonthLinkUrl": "//sz.jd.com/sz/view/viewflow/viewStats.html",
    "offLineItemStatus": 1,
    "thisMonthClstag": "pageclick|keycount|shop_data_month_20200701|1005",
    "yesterdayLinkUrl": "//sz.jd.com/sz/view/viewflow/viewStats.html",
    "yesterdayNumber": 0,
    "defaultDefinition": "统计时间内，用户付款的总订单量，包括先款订单量(在线支付、公司转账、邮局汇款等)和货到付款订单量。##统计时间范围如下：##实时：从今日00:00至您最近一次刷新本页面或点击刷新按钮。##昨日：昨日00:00-24:00。##本月：本月1日至昨日24:00。",
    "name": "总浏览量",
    "id": "1005",
    "realTimeLinkUrl": "//sz.jd.com/sz/view/realTime/realSummarys.html",
    "status": 1
}],
    "noAuth": false,
    "shopDataWareInfo": [{
    "visitCount": 25,
    "wareUrl": "//item.jd.com/10055308983970.html",
    "dealAmount": 43.92000000000001,
    "clstag": "pageclick|keycount|shop_data_ware_20200701|2-0",
    "imageUrl": "//img10.360buyimg.com/n5/jfs/t30121/283/956541494/114896/2e0f010a/5c037e8aNf147c1c9.jpg",
    "name": "致巧青瓷50毫升陶瓷小酒杯1两白酒酒杯烈酒酒盅家用茶杯清酒杯具 采莲",
    "spu": null,
    "dealProductCount": 8
}, {
    "visitCount": 53,
    "wareUrl": "//item.jd.com/10057896277710.html",
    "dealAmount": 89.6,
    "clstag": "pageclick|keycount|shop_data_ware_20200701|2-1",
    "imageUrl": "//img10.360buyimg.com/n5/jfs/t1/165722/32/24192/158265/617439e8Ea39d3cc5/167afc98caba11d1.jpg",
    "name": "致巧泡酒瓶1斤2斤3斤 泡酒玻璃瓶子泡酒瓶药养生酒鹿茸酒瓶空瓶子 6两装 鹿茸酒(300ml) 满十只",
    "spu": null,
    "dealProductCount": 8
}, {
    "visitCount": 297,
    "wareUrl": "//item.jd.com/10050240622072.html",
    "dealAmount": 121.00999999999999,
    "clstag": "pageclick|keycount|shop_data_ware_20200701|2-2",
    "imageUrl": "//img10.360buyimg.com/n5/jfs/t1/78372/17/19804/90236/62ae1dc3Ee91c1569/d1a6504172c82454.jpg",
    "name": "致巧304不锈钢热奶锅迷你小锅特厚不粘锅宝宝辅食锅煮面汤锅适用 20cm单柄奶锅(加厚304)",
    "spu": null,
    "dealProductCount": 4
}, {
    "visitCount": 51,
    "wareUrl": "//item.jd.com/10050238479071.html",
    "dealAmount": 113.78999999999999,
    "clstag": "pageclick|keycount|shop_data_ware_20200701|2-3",
    "imageUrl": "//img10.360buyimg.com/n5/jfs/t1/103072/20/30139/154635/62ae1c17E03d302f3/edaa4c7c4a3dcc5b.jpg",
    "name": "致巧蒸锅配件 适用蒸屉加高加厚不锈钢家用蒸锅蒸层配件卅馒头蒸 凹口20cm加高加厚(具体尺寸看图",
    "spu": null,
    "dealProductCount": 3
}, {
    "visitCount": 58,
    "wareUrl": "//item.jd.com/10050237642195.html",
    "dealAmount": 0,
    "clstag": "pageclick|keycount|shop_data_ware_20200701|2-4",
    "imageUrl": "//img10.360buyimg.com/n5/jfs/t1/194763/8/26163/95806/62ae1b5bE17275081/f14f5aeeb958f2f7.jpg",
    "name": "致巧 茶瓶家用不锈钢热水瓶外壳温水瓶皮暖壶外壳家用壶皮开水瓶 大号3.2升8磅鸭嘴+瓶塞+密封圈 【没有内胆 加厚外壳】",
    "spu": null,
    "dealProductCount": 3
}]
}



const fengxiangbiaoInfo = {
    "venderId": "0",
    "shopId": "0",
    "date": null,
    "scoreRankRate": 0,
    "isPassGood": 0,
    "generalAuthValidityStartTime": "2023-01-31",
    "generalAuthValidityEndTime": "2023-02-13",
    "isPassYohome": 0,
    "categoryAuthValidityStartTime": "2023-01-10",
    "categoryAuthValidityEndTime": "2023-02-09",
    "isOpenMenu": 1,
    "totalScore": null,
    "venderVsPrimaryTotal": "",
    "limitFlag": 0,
    "transDisputeRate": "54.02",
    "avgStandScore": null,
    "tbLimitFlag": 1,
    "dsrScoreRate": "",
    "isGreater": 0,
    "openTime": 0,
    "scoreRankRateGrade": "0",
    "vaneRateLimit": 0,
    "transactionRateLimit": 1,
    "shopName": "0",
    "scndCategoryId": "0",
    "scndCategoryName": "0",
    "venderType": 0,
    "commonGoodShopType": 1001,
    "categoryGoodShopType": 1002,
    "userEvaluateScoreRate":0,
    "customServiceConsultScoreRate": 0,
    "logisticsLvyueScoreRate": 0,
    "afterServiceScoreRate": 0,
    "userEvaluateRankRateUD": 0,
    "customServiceRankRateUD": 0,
    "logisticsRankRateUD": 0,
    "afterServiceRankRateUD": 0,
    "disputeRankRateUD": 0,
    "hscoreRankRate": 0
}

export default {
    summaryDay,
    fengxiangbiaoInfo,
};
