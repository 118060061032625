<template>
  <a-card hoverable>
    <a-row :gutter="36">
      <a-col :span="24">
        <a-divider><h2 style="color: #606266">获芸订单管理系统优势</h2></a-divider>
        <div style="text-align: center">
          <a-row :gutter="20">
            <a-col :span="8">
              <a-card hoverable>
                <div style="width: 100%; text-align: center;">
                  <a-image :src="require('../../assets/img.png')"></a-image>
                  <br><h5>高效管理店铺、拍单</h5>
                  <div style="text-align: center">
                    <a>多店绑定,一个账号即可绑定所有店铺</a><br>
                    <a>高效拍单,更符合直觉的订单采购</a><br>
                    <a>安全云仓,解决平台判别无货源</a><br>
                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card hoverable>
                <div style="width: 100%; text-align: center;">
                  <a-image :src="require('@/assets/img_1.png')"></a-image>
                  <br><h5>售后更省心</h5>
                  <div style="text-align: center">
                    <a>急速跟踪,一键直达采购订单信息</a><br>
                    <a>高效搜索,更多订单分类一键搜索处理</a><br>
                    <a>账单盈亏,收入支持一目了然</a><br>
                  </div>
                </div>
              </a-card>
            </a-col>
            <a-col :span="8">
              <a-card hoverable>
                <div style="width: 100%; text-align: center;">
                  <a-image :src="require('../../assets/img_2.png')"></a-image>
                  <br><h5>提效双赢</h5>
                  <div style="text-align: center">
                    <a>解决拼多多退单问题,采购利润更高</a><br>
                    <a>一键云仓出库,避免平台追查无货源</a><br>
                    <a>免费使用,仅收取云仓派件成本价格</a><br>
                  </div>
                </div>
              </a-card>
            </a-col>
          </a-row>
        </div>
        <br>
        <a-divider><h2 style="color: #606266">支持采购平台</h2></a-divider>
        <br>
        <a-row :gutter="20">
          <a-col :span="6">
            <a-card hoverable>
              <div style="width: 100%; text-align: center;">
                <a-image style="width: 128px; height: 42px" :src="require('@/assets/jingdong.png')"></a-image>
              </div>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card hoverable>
              <div style="width: 100%; text-align: center;">
                <a-image style="width: 128px; height: 42px" :src="require('@/assets/pinduoduo.png')"></a-image>
              </div>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card hoverable>
              <div style="width: 100%; text-align: center;">
                <a-image style="width: 128px; height: 42px" :src="require('@/assets/taobao.png')"></a-image>
              </div>
            </a-card>
          </a-col>
          <a-col :span="6">
            <a-card hoverable>
              <div style="width: 100%; text-align: center;">
                <a-image style="width: 128px; height: 42px" :src="require('@/assets/1688.png')"></a-image>
              </div>
            </a-card>
          </a-col>
        </a-row>
        <br><br>

      </a-col>
    </a-row>
  </a-card>
</template>

<script>
import { useRouter } from 'vue-router'
import { Modal } from 'ant-design-vue';
import {h} from "vue";
export default {
  name: 'home',
  props: {
    msg: String
  },
  setup () {
    const router = useRouter()
    const toLogin = (() => {
      router.push({
        name: 'login'
      })
    })
    return {
      toLogin
    }
  },
  mounted() {
    Modal.info({title: "收费公告",content: h('div', {}, [
        h('span',{
          "style" : {"color":"black"},
        } ,'如果您在登录之后还是看到本提示'),
        h('br'),
        h('span',{
          "style" : {"color":"black"},
        } ,'由于本系统免费开放,用户激增。服务器压力过大'),
        h('br'),
        h('span',{
          "style" : {"color":"black"},
        } ,'现在开启预充值200元开通资格使用'),
        h('br'),
        h('span',{
          "style" : {"color":"red"},
        } ,'(余额可用于中转仓和本系统绑定店铺使用)'),
        h('br'),
        h('a',{
          "style" : {"color":"blue"},
          "href" : "https://express.cklllll.com/charge.html",
          "target" : "_blank",
        } ,'点击此处进行充值'),
      ]),})
  }
}
</script>


