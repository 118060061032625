<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择您要使用进行操作的的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="请选择您要使用进行操作的的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});console.log(useShopIds)" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
  </div><br>
  <div style="text-align: center">
    <a-card style="text-align: center">
      <a-row :gutter="[16,16]">
        <a-col :span="2" v-for="(businessImage,index) in businessImages" :key="index">
          <a-image style="width: 64px" :src="businessImage"></a-image>
        </a-col>
      </a-row>
      <a-upload
          name="file"
          :beforeUpload="beforeUpload"
      >
        <a-button  size="small"  type="primary"><plus-outlined />增加经营许可证图片</a-button>
      </a-upload>
    </a-card><br><br>
    <a-card style="text-align: center">
      <a-row :gutter="[16,16]">
        <a-col :span="2" v-for="(authorizationImage,index) in authorizationImages" :key="index">
          <a-image style="width: 64px" :src="authorizationImage"></a-image>
        </a-col>
      </a-row>
      <a-upload
          name="file"
          :beforeUpload="beforeUpload2"
      >
        <a-button size="small" type="primary"><plus-outlined />增加销售授权书图片</a-button>
      </a-upload>
    </a-card><br><br>
    <a-input v-model:value="authorizationExpireDate" placeholder="有效日期,格式为:2027-06-13"></a-input>
  </div>
  <div>
    <a-tabs v-model:activeKey="activeKey">
      <a-tab-pane key="1" :tab="'品牌列表('+brands.length+')'">
        <div style="text-align: left">
          &nbsp;&nbsp;
          授权状态:&nbsp;<a-select
              size="small"
              v-model:value="data.authStatus"
              style="width: 128px;"
          >
            <a-select-option :value="null">全部</a-select-option>
            <a-select-option value="1">未授权</a-select-option>
            <a-select-option value="2">已授权</a-select-option>
            <a-select-option value="3">已停用</a-select-option>
          </a-select>&nbsp;&nbsp;
          审核状态:&nbsp;<a-select
              size="small"
              v-model:value="data.checkStatus"
              style="width: 128px;"
          >
            <a-select-option :value="null">全部</a-select-option>
            <a-select-option value="1">正在审核</a-select-option>
            <a-select-option value="2">审核驳回</a-select-option>
            <a-select-option value="3">审核通过</a-select-option>
          </a-select>&nbsp;&nbsp;
          <a-button size="small"  type="primary" @click="handGetBrands">批量查询</a-button>&nbsp;&nbsp;
          <span v-if="brands.length !== 0">查询到符合条件的共有{{brands.length}}个品牌数据</span>
        </div>
        <br><br>
        <div style="text-align: left">
          <a-empty v-if="brands.length === 0"></a-empty>
          <div v-else>
            <a-row :gutter="[16,16]">
              <a-col :span="2" v-for="brand in brands" :key="brand.brandId">
                <div><a-checkbox v-model:checked="brand.checked"></a-checkbox>&nbsp;{{brand.brandName}}</div>
              </a-col>
            </a-row>
          </div>
          <br><br><br><br>
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="z-index:10001">
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="5">
              <span>已选:{{selectNum}}个品牌</span>&nbsp;&nbsp;
              <a-button @click="selecAll" size="small">全部选择</a-button>&nbsp;
              <a-button @click="selecAnti" size="small">全部反选</a-button>&nbsp;
            </a-col>
            <a-col :span="18" style="text-align: right">
              <a-button @click="handeSavaBrand" type="primary" size="small">开始上传品牌</a-button>&nbsp;
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import { message } from 'ant-design-vue'
import {getItem as storageGet} from "@/utils/storage";
import {reactive, ref} from "vue";
import {get} from "@/api/store"
import {getBrands,savaBrand} from "./api/item";
export default {
  name: "addBrandNew",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      activeKey : "1",
      data : {
        checkStatus : null,
        authStatus : null
      },
      selectNum : 0,
      brands : [],
      businessImages : [],
      authorizationImages : [],

    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)==false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
    this.handeGetList();
  },
  methods:{
    f2b(file){
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    beforeUpload(file) {
      // 将图片转换为base64格式，如果在上传时遇到+被转译为空字符串则需要把+转译成%2B
      String.prototype.replaceAll = function (s1, s2) {
        return this.replace(new RegExp(s1, 'gm'), s2)
      }
      let base64Url = this.f2b(file)
      base64Url.then(res=>{
        this.businessImages.push(res)
      })

      return false
    },
    beforeUpload2(file) {
      // 将图片转换为base64格式，如果在上传时遇到+被转译为空字符串则需要把+转译成%2B
      String.prototype.replaceAll = function (s1, s2) {
        return this.replace(new RegExp(s1, 'gm'), s2)
      }
      let base64Url = this.f2b(file)
      base64Url.then(res=>{
        this.authorizationImages.push(res)
      })

      return false
    },
    handeGetList(){

    },
    handeSavaBrand(){
      let brands = [];
      this.brands.forEach(item=>{
        if (item.checked){
          let data = item.data
          if (typeof (data)==='string'){
            data = JSON.parse(item.data)
          }
          try {
            data.applyBrandVo.brandId = null
            for (let i = 0 ; i < data.applyBrandVo.venderApplyBrandQuaInfoList.length; i++){
              if (data.applyBrandVo.venderApplyBrandQuaInfoList[i].name==="销售授权书"){
                if (this.authorizationExpireDate){
                  data.applyBrandVo.venderApplyBrandQuaInfoList[i].expireDate = this.authorizationExpireDate
                }
              }
            }
            item.data = JSON.stringify(data);
            // eslint-disable-next-line no-empty
          }catch (e){

          }
          brands.push(item)
        }
      })
      if (brands.length == 0){
        message.error("没有要授权的品牌")
        return
      }
      savaBrand(this.useShopIds,brands,this.authorizationImages,this.businessImages).then(res=>{
        if (res.success){
          message.success("提交批量申请品牌授权请求成功")
        }else{
          message.error("提交批量申请品牌授权请求失败")
        }
      })
    },
    handGetBrands(){
      getBrands(this.useShopIds,this.data.authStatus,this.data.checkStatus).then(res=>{
        if (res.success){
          this.brands = res.data
          this.brands.forEach(brand=>{
            brand.checked = false
          })
        }else{
          message.warning(res.msg)
        }
      })
    },
    selecAll(){
      this.brands.forEach(item=>{
        if (!item.checked){
          item.checked = true
        }
      })
    },
    selecAnti(){
      this.brands.forEach(item=>{
        item.checked = !item.checked
      })
    },
  },
  watch:{
    brands:{
      // 此处监听obj属性a值变量
      // eslint-disable-next-line no-unused-vars
      handler(item1,item2){
        this.selectNum=0
        item1.forEach(item=>{
          if (item.checked){
            this.selectNum++
          }
        })
      },
      deep:true
    }
  }
}
</script>

<style>
  .bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height:72px;
    width:100%;
  }
</style>
