<template>
    <a-badge-ribbon :color="item.checked?'#1890FF':item.delete?'#F56C6C':'#909399'">
      <template #text>
        <a-checkbox v-if="item.delete==false" v-model:checked="item.checked"></a-checkbox>
        <span v-if="item.delete">已删除</span>
      </template>
      <div @click="checkBox()">
        <a-card hoverable>
          <template #cover>
            <img class="img" :src="'https://img10.360buyimg.com/n0/'+item.ware.logo" />
          </template>
          <a-card-meta>
            <template #description>
              <div style="text-align: left">
                <a-popover title="检测到违规关键字" v-if="item.words.length > 0">
                  <template #content>
                    <div style="text-align: right">
                      <span v-for="(word,index) in item.words" :key="index">{{word}}&nbsp;&nbsp;</span>
                    </div>
                  </template>
                  <a class="title" :style="{'font-size': '12px','color':item.wordChecked?'red':'#606266'}">{{item.ware.title}}</a>
                </a-popover>
                <a v-else class="title" :style="{'font-size': '6px','color':item.wordChecked?'#red':'#606266'}">{{item.ware.title}}</a>
                <div style="text-align: left">
                  <span style="color: #FF4500;font-size:12px">售价:{{item.ware.jdPriceMoney.amount}}&nbsp;元</span><a-divider  type="vertical"></a-divider>
                  <span :style="{'color' : item.store <= 80 && item.store >= 60 ?'#E6A23C':item.store <= 60?'#F56C6C':'#67C23A','font-size':'6px'}">质量分:{{item.store}}</span>
                </div>
                <div style="text-align: left">
                  <span style="font-size:12px">{{item.ware.wareId}}</span><a-divider  type="vertical"></a-divider><span style="font-size:12px">{{item.wareCategoryName}}</span>
                </div>

              </div>
            </template>
          </a-card-meta>
        </a-card>
      </div>
      <div style="text-align: right">
        <a-card hoverable size="small">
          <a-popconfirm
              title="是否上架该商品?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="upItems"
          >
            <a-button type="link" style="width: 50%;color: #00abf3" size="small" ><vertical-align-top-outlined />&nbsp;&nbsp;上架</a-button>
          </a-popconfirm>
          <a-popconfirm
              title="是否删除该商品?"
              ok-text="确定"
              cancel-text="取消"
              @confirm="deleteItems"
          >
            <a-button type="text" style="width: 50%" danger size="small" ><delete-outlined />&nbsp;&nbsp;删除</a-button>
          </a-popconfirm>
        </a-card>
      </div>
    </a-badge-ribbon>
</template>

<script>
import {deleteItem,upItem} from "@/components/page/itemCheck/api/item";
import {message} from "ant-design-vue";

export default {
  name: "itemCard",
  data(){
    return{}
  },
  props:{
    item : {},
    useShopId:null
  },
  mounted(){
    this.item.checked = false
  },
  methods:{
    checkBox(){
      if (this.item.delete){
        return
      }
      this.item.checked = !this.item.checked
    },
    deleteItems(){
      let deleteItems = []
      deleteItems.push(this.item)
      deleteItem(this.useShopId,deleteItems).then(res=>{
        if (res.success){
          this.item.delete = true;
          this.item.checked = false;
          message.info(`已提交删除${deleteItems.length}个商品`)
        }else{
          message.error(res.msg)
        }
      })
    },
    upItems(){
      let upItems = []
      upItems.push(this.item)
      upItem(this.useShopId,upItems).then(res=>{
        if (res.success){
          this.item.delete = true;
          this.item.checked = false;
          message.info(`已提交上架${upItem.length}个商品`)
        }else{
          message.error(res.msg)
        }
      })
    },
  }
}
</script>

<style scoped>
  .title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }


</style>
