<template>
<!--  <a-divider orientation="left"><span style="font-size: 12px">风向标</span></a-divider>-->
  <br>
  <a-row :gutter="20" align="middle" >
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">店铺星级:{{item.fengxiangInfo.scoreRankRateGrade}}星</span><br>
      <a-rate size="small" style="margin-top: -12px" :allowHalf="true" :value="parseInt(item.fengxiangInfo.scoreRankRateGrade)" disabled ></a-rate>
    </a-col>
    <a-col :span="4" style="text-align: center">
      <span style="font-size: 10px">星级排名率</span><br>
      <p :style="{'color': item.fengxiangInfo.hscoreRankRate > 0 ?'#F56C6C':item.fengxiangInfo.hscoreRankRate < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.hscoreRankRate > 0" />
        <fall-outlined v-if="item.fengxiangInfo.hscoreRankRate < 0" />
        <line-outlined v-if="item.fengxiangInfo.hscoreRankRate == 0" />
        {{item.fengxiangInfo.scoreRankRate}}%&nbsp;&nbsp;<span style="font-size: 2px;color:#909399;">较昨天({{item.fengxiangInfo.hscoreRankRate}}%)</span>
      </p>
    </a-col>
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">售后服务</span><br>
      <p :style="{'color': item.fengxiangInfo.afterServiceRankRateUD > 0 ?'#F56C6C':item.fengxiangInfo.afterServiceRankRateUD < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.afterServiceRankRateUD > 0" />
        <fall-outlined v-if="item.fengxiangInfo.afterServiceRankRateUD < 0" />
        <line-outlined v-if="item.fengxiangInfo.afterServiceRankRateUD == 0 || item.fengxiangInfo.afterServiceRankRateUD==null" />
        {{item.fengxiangInfo.afterServiceScoreRate==null?'0':item.fengxiangInfo.afterServiceScoreRate}}%
      </p>
    </a-col>
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">物流履约</span><br>
      <p :style="{'color': item.fengxiangInfo.logisticsRankRateUD > 0 ?'#F56C6C':item.fengxiangInfo.logisticsRankRateUD < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.logisticsRankRateUD > 0" />
        <fall-outlined v-if="item.fengxiangInfo.logisticsRankRateUD < 0" />
        <line-outlined v-if="item.fengxiangInfo.logisticsRankRateUD == 0" />
        {{item.fengxiangInfo.logisticsLvyueScoreRate}}%
      </p>
    </a-col>
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">用户评价</span><br>
      <p :style="{'color': item.fengxiangInfo.userEvaluateRankRateUD > 0 ?'#F56C6C':item.fengxiangInfo.userEvaluateRankRateUD < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.userEvaluateRankRateUD > 0" />
        <fall-outlined v-if="item.fengxiangInfo.userEvaluateRankRateUD < 0" />
        <line-outlined v-if="item.fengxiangInfo.userEvaluateRankRateUD == 0" />
        {{item.fengxiangInfo.userEvaluateScoreRate}}%
      </p>
    </a-col>
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">客服咨询</span><br>
      <p :style="{'color': item.fengxiangInfo.customServiceRankRateUD > 0 ?'#F56C6C':item.fengxiangInfo.customServiceRankRateUD < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.customServiceRankRateUD > 0" />
        <fall-outlined v-if="item.fengxiangInfo.customServiceRankRateUD < 0" />
        <line-outlined v-if="item.fengxiangInfo.customServiceRankRateUD == 0" />
        {{item.fengxiangInfo.customServiceConsultScoreRate}}%
      </p>
    </a-col>
    <a-col :span="3" style="text-align: center">
      <span style="font-size: 10px">交易纠纷</span><br>
      <p :style="{'color': item.fengxiangInfo.disputeRankRateUD > 0 ?'#F56C6C':item.fengxiangInfo.disputeRankRateUD < 0?'#67C23A':''}">
        <rise-outlined v-if="item.fengxiangInfo.disputeRankRateUD > 0" />
        <fall-outlined v-if="item.fengxiangInfo.disputeRankRateUD < 0" />
        <line-outlined v-if="item.fengxiangInfo.disputeRankRateUD == 0" />
        {{item.fengxiangInfo.transDisputeRate}}%
      </p>
    </a-col>
    <a-col :span="2" style="text-align: left">
      <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://yj.shop.jd.com/vane/v2/init'"><bar-chart-outlined />查看风向标详情</a>
    </a-col>
  </a-row>
</template>
<script>


export default {
  name: "fengxiangbiao",
  data(){
    return{

    }
  },
  props:{
    item : {},
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped>

</style>
