<template>
  <a-row :gutter="20">
    <a-col v-for="schedule in schedules" :key="schedule.id" :span="4">
      <a-card size="small" :bordered="false" >
        <template #title>
          <div><img style="width: 16px;margin-top: -2px" :src="schedule.properties.iconUrl" /><span style="font-size: 13px" >&nbsp;&nbsp;{{schedule.name}}</span></div>
        </template>
        <a-row v-for="tag in schedule.data" :key="tag.id">
          <a-col :span="12"  style="text-align: left">
            <a @click="openWindow('https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+shopId+'&url='+tag.url)" style="font-size: 12px;color: #606266">{{tag.name}}</a>
          </a-col>
          <a-col :span="12" style="text-align: right">
            <a @click="openWindow('https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+shopId+'&url='+tag.url)"  :style="{'font-size': '12px','color' : tag.value > 0 ?'#409EFF':'#909399'}">{{tag.value}}</a>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>


export default {
  name: "sidebar",
  data(){
    return{

    }
  },
  props:{
    schedules : {},
    shopId : null
  },
  mounted(){

  },
  methods:{
    openWindow(url){
      window.open(url)
    },
  }
}
</script>

<style scoped>

</style>
