<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择您要使用审核的店铺">
      <a-select
          v-model:value="useShopId"
          style="width: 80%;"
          show-search
          size="small"
          @change="page.page=1;getItems()"
          :filter-option="filterOption"
          placeholder="请选择您要使用审核的店铺">
        <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
          {{shop.shopName}}
        </a-select-option>
      </a-select>
      <a-divider  type="vertical"></a-divider>
      <a-button :disabled="useShopId==null" @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+useShopId)" size="small">进入店铺</a-button>
      <a-divider  type="vertical"></a-divider><a-button :disabled="useShopId==null" @click="page.page=1;getItems()"  type="primary" size="small">刷新</a-button>
    </a-card>
    <br>
    <a-card>
    <a-empty v-if="page.total ==0"></a-empty>
      <a-row>
        <a-col :span="24">
          <a-row :gutter="20" align="top">
            <a-col :span="4" v-for="item in items" :key="item.ware.wareId">
              <itemCard :useShopId="useShopId" :selectNum="selectNum" :item="item"></itemCard>
              <br><br>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
      <br>
    </a-card>
    <br>
    <br>
    <br>
  </div>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="z-index:10001">
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="8">
              <span>已选:{{selectNum}}个商品</span>&nbsp;&nbsp;
              <a-button @click="selecAll" size="small">全部选择</a-button>&nbsp;
              <a-button @click="selecAnti" size="small">全部反选</a-button>&nbsp;
              <a-popconfirm
                  :title="`是否下架${selectNum}个选中的商品?`"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="deleteItem"
              >
                <a-button type="primary" danger size="small"><delete-outlined />全部下架</a-button>&nbsp;
              </a-popconfirm>&nbsp;
            </a-col>
            <a-col :span="16">
              <a-pagination @change="getItems" :pageSizeOptions="['10','20','30','50','100']"  v-model:pageSize="page.pageSize" v-model:current="page.page" size="small" :total="page.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import itemCard from "./components/itemCard"
import words from "./config/words"
import { ElMessageBox } from 'element-plus'

import { message } from 'ant-design-vue'
import {getOnSaleItems,downItem} from "./api/item";

export default {
  name: "SaleItemCheck",
  components:{
    itemCard
  },
  data(){
    return{
      userInfo : get("userinfo"),
      useShopId : [],
      items  : [],
      page : {
        page : 1,
        pageSize : 50,
        total  : 0
      },
      selectNum : 0,
      checkWords : true,
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    return {filterOption,message,words}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods:{
    getItems(){
      if (this.selectNum > 0){
        ElMessageBox.confirm(
            `已选择(${this.selectNum})个商品,是否确认不进行处理就转到下一页?`,
            '询问',
            {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'warning',
            }
        ).then(() => {
          getOnSaleItems(this.useShopId,this.page.page,this.page.pageSize).then(res=>{
                if (res.success){
                  res.data.data.forEach(item=>{
                    item.words = []
                    item.checked = false
                    item.wordChecked = false
                  })
                  this.items = res.data.data
                  this.selectNum = 0
                  this.page.total = res.data.totalItem
                  if (this.checkWords){
                    this.useCheckWords()
                  }
                }else{
                  this.items = []
                  this.selectNum = 0
                  this.page.total = 0
                  message.error(res.msg)
                }
              })})
      }else{
        getOnSaleItems(this.useShopId,this.page.page,this.page.pageSize).then(res=>{
          if (res.success){
            res.data.data.forEach(item=>{
              item.words = []
              item.checked = false
              item.wordChecked = false
            })
            this.items = res.data.data
            this.selectNum = 0
            this.page.total = res.data.totalItem
            if (this.checkWords){
              this.useCheckWords()
            }
          }else{
            this.items = []
            this.selectNum = 0
            this.page.total = 0
            message.error(res.msg)
          }
        })
      }
    },
    selecAll(){
      this.items.forEach(item=>{
        if (!item.checked && !item.delete){
          item.checked = true
        }
      })
    },
    selecAnti(){
      this.items.forEach(item=>{
        if (!item.delete){
          item.checked = !item.checked
        }
      })
    },
    useCheckWords(){
      this.items.forEach(item=>{
        words.forEach(word=>{
          if (word!="" && item.ware.title.indexOf(word) > -1){
            item.wordChecked = true
            item.words.push(word)
          }
        })
      })
    },
    deleteItem(){
      let deleteItems = []
      this.items.forEach(item=>{
        if (item.checked){
          deleteItems.push(item)
          item.checked = false
          item.delete = true
        }
      })

      downItem(this.useShopId,deleteItems).then(res=>{
        if (res.success){
          message.info(`已提交下架${deleteItems.length}个商品,正在重新拉取商品列表`)
          getOnSaleItems(this.useShopId,this.page.page,this.page.pageSize).then(res=>{
            if (res.success){
              res.data.data.forEach(item=>{
                item.words = []
                item.checked = false
                item.wordChecked = false
              })
              this.items = res.data.data
              this.selectNum = 0
              this.page.total = res.data.totalItem
              if (this.checkWords){
                this.useCheckWords()
              }
            }else{
              this.items = []
              this.selectNum = 0
              this.page.total = 0
              message.error(res.msg)
            }
          })
        }else{
          message.error(res.msg)
        }
      })
    },
    openWindow(url){
      window.open(url)
    },
  },
  watch:{
    items:{
      // 此处监听obj属性a值变量
      // eslint-disable-next-line no-unused-vars
      handler(item1,item2){
        this.selectNum=0
        item1.forEach(item=>{
          if (item.checked){
            this.selectNum++
          }
        })
      },
      deep:true
    }
  }
}
</script>

<style>
  .bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height:72px;
    width:100%;
  }


</style>
