<template>
  <a-modal v-model:visible="visibleValue" width="80%" title="订单采购记录" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <a-table :columns="columns" :data-source="list" :pagination="false" bordered>
            <template #bodyCell="{ column, record }">
              <template v-if="column.key === 'shopName'">
                <div>{{record.shopName}}</div>
              </template>
              <template v-if="column.key === 'orderId'">
                <a href="javascript:void(0)"  @click="copyVal(''+record.orderId)"> {{record.orderId}}<copy-outlined /></a>
              </template>
              <template v-if="column.key === 'orderType'">
                <div>{{transformOrderType(record.orderType)}}</div>
              </template>
              <template v-if="column.key === 'order_sn'">
                <a @click="jumpBuyPlat(record.orderType,record.order_sn)"   >{{record.order_sn}} </a>
                <a href="javascript:void(0)"  @click="copyVal(''+record.order_sn)"> <copy-outlined /></a>
              </template>
              <template v-if="column.key === 'buyGoodsName'">
                <div style="display: flex;flex-flow: row;justify-content: flex-start">
                  <a-image
                      :width="50"
                      :src="record.buyImg"
                  ></a-image>
                  <a style="margin-left: 5px;" :href="record.buyUrl"  target="_blank" >{{record.buyGoodsName}}<br/>{{record.buySkuName}}</a>
                </div>


              </template>
            </template>
    </a-table>
  </a-modal>
</template>
<script >

import {defineComponent} from 'vue'
import {message, Modal} from 'ant-design-vue'
import {orderChilds} from "@/api/order"
import {copy, NotSetUpBox} from "@/api/utils"
import {chromeApp} from "@/components/page/orderNew/api";
export default defineComponent({
  name : "buyHistoryWindow",
  components:{
    aModal : Modal,
  },
  data(){
    return{
      visibleValue:false,
      list:[],
      orderId:null,
      skuId: null,
      visible: false,
      username:null,
      setUp:false,
      columns:[
        {
          title: '店铺名',
          dataIndex: 'shopName',
          key: 'shopName',
        },
        {
          title: '订单编号',
          dataIndex: 'orderId',
          key: 'orderId',
        },
        {
          title: '采购平台',
          dataIndex: 'orderType',
          key: 'orderType',
        },
        {
          title: '采购订单号',
          dataIndex: 'order_sn',
          key: 'order_sn',
        },
        {
          title: '采购商品名称',
          dataIndex: 'buyGoodsName',
          key: 'buyGoodsName',
        },
        {
          title: '采购账号',
          dataIndex: 'nickname',
          key: '',
        },
        {
          title: '采购时间',
          dataIndex: 'buyCreateTime',
          key: '',
        },
      ]
    }
  },
  mounted() {

  },
  methods:{
    jumpBuyPlat(buyType,orderSn){
      let url = null
      if (buyType === 0){
        // 拼多多
        url = 'https://mobile.yangkeduo.com/order.html?order_sn='+orderSn
      }else if (buyType === 1){
        // 淘宝
        url = 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+orderSn
      }else if ( buyType === 2){
        // 1688
      }
      this.checkSetup()
      chromeApp.send(JSON.stringify({
        type:'viewOrder',
        data:{
          buyOrderType:buyType,
          order_sn: orderSn,
          url:url,
          username:this.username
        },
        success : false,
      }))
    },
    checkSetup(){
      if (this.setUp === false){
        NotSetUpBox()
      }
    },

    copyVal(value){
      return copy(value)
    },
    initData(orderId,skuId,visible,username,setUp){
      this.orderId = orderId
      this.skuId = skuId
      this.visible = visible
      this.visibleValue = this.visible === null?false:this.visible
      this.username = username
      this.setUp = setUp
      this.getOrderChilds()
    },
    transformOrderType(value){
      if (value === 1){
        return '淘宝'
      }else if (value === 2){
        return '1688'
      }else if(value === 0){
        return '拼多多'
      }
    },
    getOrderChilds(){
      orderChilds({
        orderId:this.orderId,
        skuId: this.skuId
      }).then(res =>{
        if (res.success){
          this.list = res.data
          console.log("list",this.list)
        }else{
          message.error(res.msg)
        }
      })
    }
  }
})
</script>
