<template>
  <div style="text-align: left">
    <div style="text-align: center;margin-bottom: 100px">
      <div>
        <a-form :model="parameter">
          <a-row :gutter="20">
            <a-col :span="4">
              <a-form-item label="服务类型">
                <a-select
                    ref="select"
                    v-model:value="parameter.type"
                    @change="getShopList"
                    style="width: 120px"
                >
                  <a-select-option value="3">卡仓出库</a-select-option>
                  <a-select-option value="4">品牌管理</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="店铺名称">
                <a-input placeholder="请输入店铺名称"  v-model:value="parameter.name"></a-input>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="是否购买">
                <a-select
                    ref="select"
                    v-model:value="parameter.isBuy"
                    style="width: 120px"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">是</a-select-option>
                  <a-select-option value="2">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-form-item label="是否到期">
                <a-select
                    ref="select"
                    v-model:value="parameter.isExpire"
                    style="width: 120px"
                >
                  <a-select-option value="">全部</a-select-option>
                  <a-select-option value="1">是</a-select-option>
                  <a-select-option value="2">否</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :span="2">
              <a-form-item>
                <a-button type="primary" @click="searchShops">搜索</a-button>
              </a-form-item>
            </a-col>
            <a-col :span="3" style="margin-left: auto">
              <a-form-item>
                <a-button type="primary" @click="useCard = true">批量充值</a-button>
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <a-table :columns="columns"
               :data-source="shopList.slice((pageData.page-1) * pageData.pageSize,pageData.page*pageData.pageSize)"
               bordered :pagination="false"
               :row-key="record => record.shopId"
               :row-selection="{
                 onChange:onChange,
                 onSelect:onSelect,
                 onSelectAll:onSelectAll
               }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'shopId'">
            <div>{{record.shopId}}</div>
          </template>
          <template v-if="column.key === 'shopName'">
            <div>{{record.shopName}}</div>
          </template>
          <template v-if="column.key === 'expiration'">
            <div v-if="record.is_expire === -1" style="color: red;font-size: 14px;font-weight: bold">未购买指定服务</div>
            <div v-if="record.is_expire === 0" >{{record.expiration}}</div>
            <div v-if="record.is_expire === 1" >{{record.expiration}}<span style="color: red;font-size: 14px;font-weight: bold;margin-left: 5px;">已过期</span></div>
          </template>
          <template v-if="column.key === 'operate'">
            <a-button type="primary" @click="selectedShopIds=[];selectedShopIds.push(record.shopId);useCard = true">
              店铺充值
            </a-button>
          </template>
        </template>
      </a-table>
      <a-pagination align="center" style="margin-top: 10px;margin-bottom: 10px"  @change="handleCurrentChange" :small="true"
                    v-model:current="pageData.page"
                    v-model:pageSize="pageData.pageSize"
                    :page-size-options="['10', '30', '50', '100','200','300','500']"
                    layout="total,  prev, pager, next, jumper"
                    :total="shopList.length">
        <template #buildOptionText="props">
          <span v-if="props.value !== '500'">{{ props.value }}条/页</span>
          <span v-else>全部店铺</span>
        </template>
      </a-pagination>
    </div>
  </div>
  <a-modal v-model:visible="useCard" title="请输入您购买的充值卡进行充值"  @ok="onChangeCard">
    <p>当前选择的店铺数量:{{selectedShopIds.length}}个</p>
    <a-textarea
        v-model:value="cardIds"
        :placeholder="'请在此处填入'+ (selectedShopIds.length) + '张充值卡号进行充值(一行一张)'" :auto-size="{ minRows: 10, maxRows: 10 }">
    </a-textarea>
  </a-modal>
</template>

<script>
import {get} from "@/api/store"
import {message, Modal} from 'ant-design-vue'
import {getItem as storageGet} from "@/utils/storage";
import {ref, reactive} from "vue"
import {shopList} from "@/api/shop";
import {useCards} from "./api/item";

export default {
  name: "stockOut",
  components:{

  },
  data(){
    return{
      taskType : "service",
      userInfo : get("userinfo"),
      cardIds : "",
      shopList : [

      ],
      oldShopList:[],
      columns:[
        {
          title: '店铺id',
          dataIndex: 'shopId',
          key: 'shopId',
          align:'center',
          width:200,
        },
        {
          title: '店铺名',
          dataIndex: 'shopName',
          key: 'shopName',
          align:'center',
          // width:300,
        },
        {
          title: '到期时间',
          dataIndex: 'expiration',
          key: 'expiration',
          align:'center',
          width:300,
        },
        {
          title: '操作',
          dataIndex: 'operate',
          key: 'operate',
          align:'center'
        },
      ],
      parameter:{
        shopId:null,
        isBuy:"",
        isExpire:"",
        type : "3"
      },
      pageData:{
        pageSize:10,
        page:1
      },
      selectedShopIds:[],
      visible:true,
      useCard : false,

    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)===false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
    this.getShopList()
  },
  methods:{
    onChangeCard(){
      this.useCard = false
      if (this.selectedShopIds.length !== this.cardIds.split("\n").length){
        Modal.warning({
          title : '提示',
          content : '请输入'+this.selectedShopIds.length+'张卡密进行充值,一店一码'
        })
        return
      }
      useCards(this.selectedShopIds,this.cardIds,this.parameter.type).then(res=>{
        if (res.success){
          Modal.success({
            title : '充值成功',
            content : res.msg
          })
          this.getShopList()
        }else{
          Modal.warning({
            title : '充值失败',
            content : res.msg
          })
        }
      })
    },
    onChange(selectedRowKeys, selectedRows){
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    onSelect(record, selected, selectedRows){
      let index = this.selectedShopIds.indexOf(record.shopId)
      if (selected){
        if (index === -1){
          this.selectedShopIds.push(record.shopId)
        }
      }else{
        if (index > -1){
          this.selectedShopIds=  this.selectedShopIds.splice(index,1)
        }
      }
      console.log(record, selected, selectedRows);
    },
    onSelectAll(selected, selectedRows, changeRows){
      if (selected){
        changeRows.forEach((item) =>{
          let index = this.selectedShopIds.indexOf(item.shopId)
          if (index === -1){
            this.selectedShopIds.push(item.shopId)
          }
        })
      }else{
        changeRows.forEach((item) =>{
          let index = this.selectedShopIds.indexOf(item.shopId)
          if (index > -1){
            this.selectedShopIds= this.selectedShopIds.splice(index,1)
          }
        })
      }
    },
    searchShops(){
      this.shopList = this.oldShopList
      if (this.parameter.name) {
        this.shopList = this.shopList.filter((item) => item.shopName.includes(this.parameter.name))
      }

      if (this.parameter.isBuy === "1") {

        this.shopList = this.shopList.filter((item) => item.is_expire !==-1)
      }
      if (this.parameter.isBuy === "2") {
        this.shopList = this.shopList.filter((item) => item.is_expire ===-1)
      }

      if (this.parameter.isExpire === "1") {
        this.shopList = this.shopList.filter((item) => item.is_expire ===1)
      }
      if (this.parameter.isExpire === "2") {
        this.shopList = this.shopList.filter((item) => item.is_expire ===0)
      }
    },
    getShopList(){
      shopList({
        ... this.parameter
      }).then(res =>{
        if(res.success){
          this.shopList = res.data
          this.oldShopList = res.data
        }
      })
    },
    handleCurrentChange (val) {
      this.pageData.page = val
    },
  },
  watch:{

  }
}
</script>

<style>
  .TypeBottom{
    position: fixed;
    bottom:22px;
    left:0px;
    height:42px;
    width:100%;
  }
</style>
