import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const updateShopInfo = (shopId)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/api/shopGetInfo`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopId" : shopId,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export const CloseService =  (shopId,type=0)=>{
    return request({
        url: `https://api.cklllll.com/jingdong/api/CloseService`,
        method: 'POST',
        data : {
            shopId,
            type
        },
        loading : true,
    })
}

