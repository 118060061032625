import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const clearSale = (shopIds,checkOption)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/clearSale0`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "checkOption" : checkOption
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export default {
    clearSale,
};
