<template>
  <a-drawer
      title="设置"
      :visible="visible"
      :closable="false"
      :maskClosable="true"
      z-index='29999999'
      :destroyOnClose="true"
  >
    <template #extra>
      <div style="text-align: right">
        <a-button danger  @click="close" size="small" style="margin-top: 0px;">
          <template #icon><close-outlined /></template>
        </a-button>
      </div>
    </template>
    <a-row :gutter="20">
      <a-col :span="24">
        <a-divider orientation="left"><span style="font-size: 11px">软件设置</span></a-divider>
        <a-checkbox v-model:checked="vuex.state.setting.showBuyerUserInfo" size="small">显示买家信息</a-checkbox><br>
      </a-col>
      <a-col :span="24">
        <a-divider orientation="left"><span style="font-size: 11px">采购设置</span></a-divider>
        <a-checkbox v-model:checked="vuex.state.setting.changeBuyerPhone" size="small">修改用户收货号码</a-checkbox><br>
      </a-col>
      <a-col :span="24">
        <a-divider orientation="left"><span style="font-size: 11px">支付设置</span></a-divider>
        <a-checkbox v-model:checked="vuex.state.setting.autoPay" size="small">自动输入支付密码</a-checkbox><br>
        <a-input-password class="animate__slideInUp animate__animated" v-model:value="vuex.state.setting.passWord" v-if="vuex.state.setting.autoPay" id="payPassword" style="margin-top: 12px"></a-input-password>
      </a-col>
    </a-row>
  </a-drawer>
</template>
<script>
import { defineComponent } from 'vue'
import { Drawer ,Button,Row,Col ,Divider,Checkbox,InputPassword} from 'ant-design-vue'
import store from '@/store/index'
import {CloseOutlined} from '@ant-design/icons-vue';
export default defineComponent({
  name : "setting",
  components:{
    aDrawer : Drawer,
    aButton : Button,
    aRow : Row,
    aCol : Col,
    aCheckbox : Checkbox,
    aInputPassword : InputPassword,
    aDivider : Divider,
    CloseOutlined : CloseOutlined
  },
  props: {

  },
  data(){
    return{
      visible :true,
    }
  },
  setup() {
    const vuex = store
    return {
      vuex
    }
  },
  mounted() {
    console.log(this.vuex)
  },

  methods:{
    close(){
      this.visible = false;
    }
  }
})

</script>
