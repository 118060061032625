import { createVNode, render } from 'vue'
import buyHistoryWindow from '@/components/server/buyHistoryWindow/buyHistoryWindow'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)

// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}

const buyHistory = {
    open:(order,item)=>{
        const vnode = createVNode(buyHistoryWindow, { order:order,item:item,onClose : render(null, div)})
        renderMessage(vnode)
    },
}

export default buyHistory
