import { createWebHistory, createRouter } from "vue-router";
import home from "../components/page/home";
import login from "../views/login";
import order from "../components/page/order";
import orderNew from "../components/page/orderNew";
import afs from "../components/page/afs";
import sendOut from "../components/page/sendOut"
import {GetUserinfo} from "@/api/login";
import record from "../components/page/record";
import store from '@/store/index'
import { setItem , removeItem } from '@/utils/storage'
import shopMange from  "@/components/page/shopMange/shopList"
import itemCheck from "@/components/page/itemCheck/itemCheck"
import onSaleItemCheck from "@/components/page/onSaleItemCheck/itemCheck"
import clearItem from "@/components/page/clearItem/clearItem"
import clearItemImg from "@/components/page/clearItemImg/clearItemImg"
import titleCheck from "@/components/page/titleCheck/titleCheck"
import forSale from "@/components/page/forSale/forSale"
import report from "@/components/page/report/report"
import legal from "@/components/page/legal/legal"
import itemPurifiedItemAI from "@/components/page/itemPurifiedItemAI/itemPurifiedItemAI"
import onSale from "@/components/page/onSale/onSale"
import itemNewIcon from "@/components/page/itemNewIcon/itemNewIcon"
import stockOut from "@/components/page/stockOut/stockOut"
import zhiyun from "@/components/page/zhiyun/zhiyun"
import xiongda from "@/components/page/xiongda/xiongda"
import addBrand from "@/components/page/addBrand/addBrand"
import setCoupon from "@/components/page/setCoupon/setCoupon"
import setCouponNew from "@/components/page/setCoupon/setCouponNew"
import cardCreate from "@/components/page/cardCreate/index"
import addBrandNew from "@/components/page/addBrandNew/index"
import ziYinOrder from "@/components/page/ziYinOrder/index"
import logistComMan from "@/components/page/logistComMan/logistComMan"
import service from "@/components/page/service/index"
import recycleBin from "@/components/page/recycleBin/index"
import jtk from "@/components/page/jtk/jtk"
import brandShangzhi from "@/components/page/brandShangzhi/brandShangzhi"
import productIndex from "@/components/page/productIndex/productIndex"
import price from "@/components/page/price/price"
import huaweiMessage from "@/components/page/huaweiMessage/huaweiMessage"
import {chargeHistory} from "../api/login";

const routes = [
    {
        path: "/",
        name: "/Home",
        component: home,
    },
    {
        path: "/home",
        name: "home",
        component: home,
    },
    {
        path: "/login",
        name: "login",
        component: login,
    },
    {
        path: "/order",
        name: "order",
        component: order,
    },
    {
        path: "/orderNew",
        name: "orderNew",
        component: orderNew,
    },
    {
        path: "/afs",
        name: "afs",
        component: afs,
    },
    {
        path: "/record",
        name: "record",
        component: record,
    },
    {
        path: "/sendOut",
        name: "sendOut",
        component: sendOut,
    },
    {
        path: "/shopMange",
        name: "shopMange",
        component: shopMange,
    },
    {
        path: "/itemCheck",
        name: "itemCheck",
        component: itemCheck,
    },
    {
        path: "/onSaleItemCheck",
        name: "onSaleItemCheck",
        component: onSaleItemCheck,
    },
    {
        path: "/clearItemImg",
        name: "clearItemImg",
        component: clearItemImg,
    },
    {
        path: "/clearItem",
        name: "clearItem",
        component: clearItem,
    },
    {
        path: "/titleCheck",
        name : "titleCheck",
        component : titleCheck,
    },
    {
        path: "/forSale",
        name : "forSale",
        component : forSale,
    },
    {
        path: "/onSale",
        name : "onSale",
        component : onSale,
    },
    {
        path: "/itemNewIcon",
        name : "itemNewIcon",
        component : itemNewIcon,
    },
    {
        path: "/report",
        name : "report",
        component : report,
    },
    {
        path: "/itemPurifiedItemAI",
        name : "itemPurifiedItemAI",
        component : itemPurifiedItemAI,
    },
    {
        path: "/stockOut",
        name : "stockOut",
        component : stockOut,
    },
    {
        path: "/zhiyun",
        name : "zhiyun",
        component : zhiyun,
    },
    {
        path: "/xiongda",
        name : "xiongda",
        component : xiongda,
    },
    {
        path: "/addBrand",
        name : "addBrand",
        component : addBrand,
    },
    {
        path: "/setCoupon",
        name : "setCoupon",
        component : setCoupon,
    }, {
        path: "/setCouponNew",
        name : "setCouponNew",
        component : setCouponNew,
    },{
        path: "/cardCreate",
        name : "cardCreate",
        component : cardCreate,
    },{
        path: "/addBrandNew",
        name : "addBrandNew",
        component : addBrandNew,
    }, {
        path: "/ziYinOrder",
        name : "ziYinOrder",
        component : ziYinOrder,
    }, {
        path: "/service",
        name : "service",
        component : service,
    }, {
        path: "/legal",
        name : "legal",
        component : legal,
    }, {
        path: "/logistComMan",
        name : "logistComMan",
        component : logistComMan,
    }
    , {
        path: "/recycleBin",
        name : "recycleBin",
        component : recycleBin,
    } , {
        path: "/jtk",
        name : "jtk",
        component : jtk,
    } , {
        path: "/brandShangzhi",
        name : "brandShangzhi",
        component : brandShangzhi,
    }, {
        path: "/productIndex",
        name: "productIndex",
        component: productIndex,
    }, {
        path: "/price",
        name: "price",
        component: price,
    },{
        path: "/huaweiMessage",
        name: "huaweiMessage",
        component: huaweiMessage,
    }
];



const router = createRouter({
    history: createWebHistory(),
    routes,
});



// 全局守卫：登录拦截 本地没有存token,请重新登录
router.beforeEach((to, from, next) => {
    // 判断有没有登录
    store.dispatch('updateCurrentPage',[to.name]);
    if (!localStorage.getItem('token')) {
        store.dispatch('updateLogin',false);
        if (to.name == "login") {
            next();
        } else {
            router.push('/login')
        }
    } else {
        localStorage.getItem('token')
        GetUserinfo().then(res=>{
            console.log(res)
            if (res.success){
                setItem("userinfo",res.data)
                if (res.data.points >= 20000){
                    store.dispatch('updateLogin',true);
                    next();
                } else {
                    // 判断是否有充值历史
                    chargeHistory().then(res=>{
                        if (res.success){
                            store.dispatch('updateLogin',true);
                            next();
                        }else{
                            removeItem("userinfo")
                            localStorage.removeItem('token')
                            localStorage.clear()
                            store.dispatch('updateLogin',false);
                            router.push('/login')
                        }
                    }).catch (e=>{
                        console.log(e)
                        removeItem("userinfo")
                        localStorage.removeItem('token')
                        localStorage.clear()
                        store.dispatch('updateLogin',false);
                        router.push('/login')
                    })
                }
            }else{
                removeItem("userinfo")
                localStorage.removeItem('token')
                localStorage.clear()
                store.dispatch('updateLogin',false);
                router.push('/login')
            }
        }).catch (e=>{
            console.log(e)
            removeItem("userinfo")
            localStorage.removeItem('token')
            localStorage.clear()
            store.dispatch('updateLogin',false);
            router.push('/login')
        })

    }
});

export default router;
