import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const getOnSaleItems = (shopId,page,pageSize)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/getOnSaleItems`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopId" : shopId,
                    "page" : page,
                    "pageSize" : pageSize
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export const downItem = (shopId, items)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/downItem`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "items" : items,
                    "shopId" : shopId,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


