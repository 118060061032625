<template>
  <a-modal v-model:visible="visible" width="40%" :title="shopInfo.shopName +'账号密码'" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <h3>{{shopInfo.shopName}}</h3>
    <h5>登录账号(店铺掉线时会用该账号自动重新登录):</h5>
    <a-input size="small" v-model:value="userName"></a-input>
    <br><br>
    <h5>登录密码:</h5>
    <a-input size="small" v-model:value="passWord"></a-input>
    <br><br>
    <div style="text-align: right">
      <a-button size="small" @click="savaUser">保存密码</a-button>
    </div>
  </a-modal>
</template>
<script>
import { defineComponent } from 'vue'
import {
  Modal,Input,Button,message
} from 'ant-design-vue'
import store from '@/store/index'
import {setShopUser} from "@/api/shopIds";


export default defineComponent({
  name : "editShopUserWindow",
  components:{
    aModal : Modal,
    aInput : Input,
    aButton : Button,
  },
  props: {
    shopInfo: [],
    onClose : Function
  },
  data(){
    return{
      visible :true,
      userName : "",
      passWord : "",
    }
  },
  setup() {
    return {
      store
    }
  },
  mounted() {
      this.userName = this.shopInfo.userName
  },
  methods:{
    savaUser(){
      setShopUser(this.shopInfo.shopId,this.userName,this.passWord).then(res=>{
        if (res.success){
          message.success("保存成功")
          // eslint-disable-next-line vue/no-mutating-props
          this.shopInfo.userName = this.userName
        }else{
          message.error("保存失败,"+res.msg)
        }
      })
    }
  }
})
</script>
