<template>
  <div class="login">
    <a-card hoverable>
      <div style="text-align: left">
        <loginUserForm  v-if="loginType==0"></loginUserForm>
        <codeLoginForm v-if="loginType==2"></codeLoginForm>
        <div v-if="loginType==0||loginType==2" style="margin-top: -32px">
          <a-button @click="loginType=1" style="width: 100%">注册账号</a-button><br><br>
        </div>
        <regUserForm v-if="loginType==1" />
        <div v-if="loginType==1" style="margin-top: -32px">
          <a-button @click="loginType=0" style="width: 100%">已有账号,去登录</a-button><br><br>
        </div>
        <a-divider><span style="font-size: 9px">更多登录方式</span></a-divider>
        <a-row :gutter="20">
          <a-col v-if="loginType==2" :span="6" style="margin-top:12px;text-align: center">
            <a-button @click="loginType=0" shape="circle"><template #icon><user-outlined type="user" /></template></a-button><br>
            <span style="font-size: 12px">账号登录</span>
          </a-col>
          <a-col v-if="loginType==0" :span="6" style="margin-top:12px;text-align: center">
            <a-button @click="loginType=2" shape="circle"><template #icon><mobile-filled /></template></a-button><br>
            <span style="font-size: 12px">手机登录</span>
          </a-col>

          <a-col :span="6" style="margin-top:12px;text-align: center">
            <a-button disabled shape="circle"><template #icon><wechat-filled /></template></a-button><br>
            <span style="font-size: 12px">微信登录</span>
          </a-col>
          <a-col :span="6" style="margin-top:12px;text-align: center">
            <a-button disabled shape="circle"><template #icon><taobao-circle-filled /></template></a-button><br>
            <span style="font-size: 12px">淘宝登录</span>
          </a-col>
        </a-row>
      </div>
    </a-card>
  </div>
</template>



<script>
import { reactive }  from 'vue'
import loginUserForm from '@/components/utils/loginUserForm'
import codeLoginForm from '@/components/utils/codeLoginForm'
import regUserForm from '@/components/utils/reg'

export default {
  name: 'login',
  props: {

  },
  components:{
    loginUserForm,
    regUserForm,
    codeLoginForm
  },
  data(){
    return{
      loginType : 0,
    }
  },
  setup () {

    const data = reactive({
        userName: '',
        passWord: '',
        code: ""
    })
    return {
      data
    }
  },
}
</script>

<style>
  .login{
    margin-left: 16px;
  }
</style>
