<script setup>
import itemCard from "@/components/page/ziYinOrder/components/itemCard.vue";
import {defineComponent, defineProps, onMounted} from "vue";
defineComponent({
  itemCard : itemCard
})
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  orderList: {
    type: Array,
    default: () => []
  },
})

onMounted(()=>{

})
</script>

<template>
  <div class="orderList">
    <a-row>
      <a-col :span="24">
        <a-space direction="vertical" :size="24" style="width: 100%">
          <div
              v-for="order in orderList"
              :key="order.id"
              style="width: 100%">
            <a-card
                hoverable
                size="small"
                style="width: 100%;background-image: linear-gradient(4deg,white 55.17%,#eef3f9 95.21%);"
            >
              <itemCard :order="order"></itemCard>

            </a-card>
          </div>

        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<style scoped>

.ant-table td { white-space: nowrap; }

.sender {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #979797;
}
.sendInfo {
  font-size: 14px;
  font-family: MicrosoftYaHei;
  color: #2B2B2B;
}
</style>