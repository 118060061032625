import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const savePlan = (shopIds,commisionRatio)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/jzt/jtk`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    commisionRatio
                }),
                uninstall : true
            },
            loading : true,
        })
    }
}


