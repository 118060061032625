<template>
    <a-modal v-model:visible="visible" width="55%" title="订单采购" :footer="null" :maskClosable="false" :destroyOnClose="true" @cancel="cancel">
        <a-row style="margin-top: -16px" :gutter="20"  justify="start"  align="middle ">
        <a-col :span="6">
          <a-card hoverable>
            <template #cover>
              <a-row>
                <a-col :span="12">
                  <a-image style="text-align: right" alt="example" :src="item.orderItems.skuimage" />
                </a-col>
                <a-col :span="12" style="text-align: right;margin-right: -16px">
                  <a-button @click="refresh(order)" size="small" style="margin-top: 0px;">
                    <template #icon><reload-outlined /></template>
                  </a-button>
                </a-col>
              </a-row>
            </template>
            <a-card-meta :title="order.shopInfo.shopName">
              <template #description>
                <div style="text-align: right">
                  <a-typography-text
                      style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                      :ellipsis="{ tooltip: item.orderItems.skuName,symbol: item.orderItems.skuName,expandable:true}"
                      :content="item.orderItems.skuName"/><br>
                  <a-typography-text
                      style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                      :ellipsis="{ tooltip: item.orderItems.color,symbol: item.orderItems.skuName,expandable:true}"
                      :content="item.orderItems.color"/><br>
                  <span style="font-size: 12px">{{item.orderItems.jdPrice}} 元</span><br>
                  <span :style="{'color' :orderStatus(order.orderStatus).color,fontSize:'12px' }">{{orderStatus(order.orderStatus).label}}</span><br>
                </div>
              </template>
            </a-card-meta>
          </a-card>
        </a-col>
        <a-col :span="18">
          <a-spin tip="正在获取采购链接" :spinning="spinning">
          <div style="margin-top: 12px;text-align: center" class="buyList" v-for="(buy,index) in buyInfo" :key="index">
            <a-input @change="changeBuyUrl" style="width:15%;" size="small" v-model:value="buy.remark" placeholder="链接备注"></a-input>&nbsp;
            <a-input @change="changeBuyUrl" v-model:value="buy.url" size="small" style="width:60%;" placeholder="采购链接"></a-input>&nbsp;
            <a-button @click="jumpBuyURL(buy.url)" size="small" type="primary"><shopping-outlined />采购</a-button>&nbsp;
            <a-button @click="buyInfo.splice(index,1);changeBuyUrl()" danger size="small"><delete-outlined />删除</a-button>
          </div>
          <br>
          <div  style="text-align: center"><a-button @click="buyInfo.push({remark : '', url : '' })" type="primary">
            <plus-outlined />添加一个采购链接</a-button>
          </div>
            <div style="text-align: center;margin-top: 12px">
              <h5 style="color: rgba(0, 0, 0, 0.45);">当前采购支持:淘宝、天猫、拼多多</h5>
            </div>
            <div style="text-align: center;margin-top: 12px">
              <a-button @click="useMiaoshouBuyUrls" :data-orderId="order.orderId" :data-shopId="order.shopInfo.shopId" :data-venderId="order.shopInfo.venderId" :data-skuId="item.skuId" id="getMiaoshouUrl" size="small" style="color: rgba(0, 0, 0, 0.45);"><import-outlined />使用妙手采购信息</a-button>
            </div>
          </a-spin>
        </a-col>
      </a-row>
    </a-modal>
</template>
<script>
import { defineComponent ,h  } from 'vue'
import { Modal ,Button,Card,CardMeta,Image,Row,Col,Input,Spin,TypographyText,message } from 'ant-design-vue'
import store from '@/store/index'
import { PlusOutlined ,ImportOutlined,ReloadOutlined,ShoppingOutlined,DeleteOutlined} from '@ant-design/icons-vue';
import {getBuyUrl, refreshOrder, setBuyUrl} from "@/api/order"
import {getItem,setItem} from "@/utils/session"
export default defineComponent({
  name : "buyWindow",
  components:{
    aModal : Modal,
    aButton : Button,
    aCard : Card,
    aCardMeta : CardMeta,
    aImage : Image,
    aRow : Row,
    aCol:Col,
    aSpin : Spin,
    plusOutlined : PlusOutlined,
    reloadOutlined : ReloadOutlined,
    ShoppingOutlined:ShoppingOutlined,
    DeleteOutlined : DeleteOutlined,
    aInput : Input,
    aTypographyText : TypographyText,
    importOutlined : ImportOutlined
  },
  props: {
    order: {},
    item: {},
    onClose : Function
  },
  data(){
    return{
      visible :false,
      spinning : true,
      buyInfo : [],
      time : null,
      time2 : null,
    }
  },
  setup() {
    const mystore = store
    return {
      mystore
    }
  },
  mounted() {
    setItem("buyUrls",{"result":"success","purchaseWareUrls":[],"purchaseWareUrlHtml":""})
    setItem("isBuys","false")
    if (!this.mystore.state.isInstall){
      Modal.error({title: "未安装获芸订单管理插件,请安装之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "./Chrome.zip",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})
      return
    }
    if (!this.mystore.state.isGetOrder){
      Modal.error({title: "未运行获芸订单同步组件客户端,请启动之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "https://huoyuntest.oss-cn-hangzhou.aliyuncs.com/huoyunOrderSysteam.exe",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})

      return
    }
    this.visible = true
    this.item.status == 99
    getBuyUrl(this.item.orderItems.skuName,this.item.orderItems.color).then(res=>{
      this.spinning = false
      if (res.success){
        if (res.data.info==undefined){
          this.buyInfo = [{
            url : "",
            remark : "",
          }]
          return
        }
        this.buyInfo = JSON.parse(res.data.info);
        if (this.buyInfo.length==0){
          this.buyInfo = [{
            url : "",
            remark : "",
          }]
        }
      }else{
        this.buyInfo = [{
          url : "",
          remark : "",
        }]
      }
    })
    this.time2 = setInterval(()=>{
      let buyUrls = getItem("buyUrls")
      if (buyUrls.purchaseWareUrls.length > 0) {
        message.info("有外部采购订单的采购链接可以导入")
        clearInterval(this.time2)
      }
    },100)
    this.time = setInterval(()=>{
      if(getItem("isBuys")){
        message.error("本单订单已在妙手或熊大订单下单,请注意重复下单")
        clearInterval(this.time)
      }
    },100)
  },
  methods:{
    orderStatus(status){
      const statusList =  [
        { label: '等待出库', value: '8' ,color : "#409EFF" },
        { label: '等待收货', value: '16',color : "#E6A23C" },
        { label: '完成订单', value: '19',color : "#67C23A" },
        { label: '已取消', value: '-1'  ,color : "#F56C6C"},
      ]
      for (let i = 0 ; i < statusList.length;i++){
        if (statusList[i].value == status){
          return statusList[i]
        }
      }
      return { label: '未知状态,'+status, value: status  ,color : "#909399"}
    },
    cancel(){
      clearInterval(this.time)
      clearInterval(this.time2)
    },
    changeBuyUrl(){
      setBuyUrl(this.item.orderItems.skuName,this.buyInfo,this.item.orderItems.color).then(res=>{
        this.spinning = false
        if (res.success){
          message.success("采购链接保存成功")
        }else{
          message.error("采购链接保存失败,请重试")
        }
      })
    },
    jumpBuyURL(url){
      this.visible = false
      let order = JSON.parse(JSON.stringify(this.order))
      let item = JSON.parse(JSON.stringify(this.item))

      order.buyerInfo = ""
      order.orderItems  = ""
      if ( store.state.setting.changeBuyerPhone){
        if (url.indexOf("yangkeduo")>-1 || url.indexOf("pdd")>-1 || url.indexOf("pinduoduo")>-1 ){
          let tel = order.buyAddressInfo.phone;
          let key = tel.substring(6,7)
          let key2 = Math.floor(Math.random()*10);
          while (key==key2){
            key2 = Math.ceil(Math.random()*10);
          }
          let phone = tel.substring(0,6) +key2+ tel.substring(7,11)
          console.log(phone)
          order.buyAddressInfo.phone = phone
        }
      }
      let data={
        cityId : this.order.address.cityId,
        type : 'buyItemEx',
        order : order,
        item : item,
        url : url,
      }
      let str = JSON.stringify(data)
      while (str.indexOf("%")>-1){
        str = str.replace("%","");
      }
      window.open(`https://order.cklllll.com/extension.html?&type=buyItemEx&data=${str}&afertfsdfgasdgghagh`)
      //window.open(`https://order.cklllll.com/extension.html?type=buyItemEx&orderId=${order.orderId}&skuId=${order.skuId}&url=${url}`)
    },
    useMiaoshouBuyUrls(){
      let buyUrls = getItem("buyUrls")
      if (buyUrls.purchaseWareUrls.length < 1) {
        message.info("妙手链接获取结果为空")
      }else{
        buyUrls.purchaseWareUrls.forEach((url,index)=>{
          this.buyInfo.push({remark : '妙手采购链接'+(index+1), url : url.purchaseUrl })
        })
        message.success("妙手订单链接同步成功")
        this.changeBuyUrl()
      }
    },
    refresh(order){
      refreshOrder(order).then(res=>{
        if (res.success){
          console.log(res.data)
          res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo);
          console.log(res.data.buyInfo)
          if (res.data.buyInfo!=undefined){
            res.data.buyInfo = JSON.parse(res.data.buyInfo);
          }
          res.data.shopInfo = JSON.parse(res.data.shopInfo);
          res.data.shopOnline = 0;
          res.data.orderItems.forEach(item=>{
            item.orderItems = JSON.parse(item.orderItems)
          })
          res.data.checkBoxSelect = order.checkBoxSelect;
          // eslint-disable-next-line vue/no-mutating-props
          order = res.data
          message.success("更新订单状态成功")
        }else{
          message.error(res.msg)
        }
      })
    },
  }
})
</script>
