<template>
  <div v-if="mystore.state.isLogin" style="margin-bottom: 16px">
    <div v-show="!isInstall || !isGetOrder" style="text-align: center">
      <a-card hoverable style="margin-top: 16px">
        <div v-show="!isInstall" class="animate__animated animate__flash">
          <a target="_blank" download="chrome.zip" style="color: red;font-size: 14px" :href="'./chrome.zip?t'+new Date().getTime()"><warning-outlined />&nbsp;检测到您的店群管理浏览器插件未安装,请点击此处下载安装后刷新本页面使用</a>
        </div>
        <div v-show="!isGetOrder" style="text-align: center" class="animate__animated animate__flash">
          <a target="_blank" download="huoyunOrderSysteam.exe" style="color: red;font-size: 14px" href="https://huoyuntest.oss-cn-hangzhou.aliyuncs.com/huoyunOrderSysteam.exe"><warning-outlined />&nbsp;
            {{updataVersion==false?'获芸插件通用增强组件已经更新,请点击此处下载安装后启动订单同步系统':'检测到您的电脑未运行获芸插件通用增强组件,请点击此处下载安装后启动订单同步系统'}}
          </a>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
import {ref} from "vue"
import config from "@/config/config";
import store from '@/store'
import axios from "axios";

export default {
  name: "downLoad",
  setup(){
    const mystore = store
    const isInstall = ref(false)
    const updataVersion = ref(true)
    //初始化是否安装插件
    const installTime = window.localStorage.getItem('antdInstallVerify');
    if (installTime!=null){
      if (new Date().getTime()-installTime < 3000){
        isInstall.value = true
        store.dispatch('updateIsInstall',isInstall.value);
      }else{
        isInstall.value = false
        store.dispatch('updateIsInstall',isInstall.value);
      }
    }else{
      isInstall.value = false
      store.dispatch('updateIsInstall',isInstall.value);
    }
    setInterval(()=>{
      const installTime = window.localStorage.getItem('antdInstallVerify');
      if (installTime!=null){
        if (new Date().getTime()-installTime < 3000){
          isInstall.value = true
          store.dispatch('updateIsInstall',isInstall.value);
        }else{
          isInstall.value = false
          store.dispatch('updateIsInstall',isInstall.value);
        }
      }else{
        isInstall.value = false
        store.dispatch('updateIsInstall',isInstall.value);
      }
    },1000)




    //初始化是否运行订单同步系统

    const isGetOrder = ref(false)
    store.dispatch('updateIsGetOrder',false);
    setInterval(()=>{
      axios.get(`http://localhost:8081/test?token=${store.state.token}`).then(res=>{
        if (res.data.version==config.GetOrderVersion){
          isGetOrder.value = true
          updataVersion.value = false
          store.dispatch('updateIsGetOrder',isGetOrder.value);
        }else{
          isGetOrder.value = false
          store.dispatch('updateIsGetOrder',isGetOrder.value);
        }
      }).catch(()=>{
        isGetOrder.value = false
        store.dispatch('updateIsGetOrder',isGetOrder.value);
      })
    },1000)
    return{
      isInstall,isGetOrder,mystore,updataVersion
     }
  },
}
</script>

<style scoped>

</style>
