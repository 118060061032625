export const columns = [

    {
        title: '店铺信息',
        dataIndex: 'shop',
        align: 'center',
        width: 150,
    },
   {
        width: 400,
        title: '商品信息',
        dataIndex: 'goods',
        align: 'center',
    },
    {
        width:200,
        title: '金额',
        dataIndex: 'money',
        align: 'center'
    },
    {
        title: '地址信息',
        dataIndex: 'address',
        align: 'center',
        width: 300
    },
    {
        title: '客单状态',
        dataIndex: 'customerStatus',
        align: 'center',
        width:300
    },
    {
        title: '承运信息',
        dataIndex: 'express',
        align: 'center',
        width: 300
    },
    {
        title: '商家仓库名称',
        dataIndex: 'vendor',
        align: 'center',
        width: 300
    },
    {
        width:120,
        title: '操作',
        dataIndex: 'action',
        align: 'center',
        fixed:'right'
    }
]
