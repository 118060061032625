<template>
  <a-form layout="vertical" :model="form" @finish="onFinish" name="basic" @finishFailed="onFinishFailed">
    <a-form-item autocomplete="off" name="userName" label="账号" validateFirst :rules="[
        { required: true, message: '账号不能为空' ,trigger:'change'},
        { min: 3, message: '账号长度不能低于6位数',trigger:'change' },
        { max: 18, message: '账号长度不能大于18位数',trigger:'change' },
    ]">
      <a-input placeholder="登录用户名"  v-model:value="form.userName">
        <template #prefix>
          <user-outlined type="user" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item autocomplete="off"  name="passWord"  label="密码" validateFirst :rules="[
        { required: true, message: '密码不能为空',trigger:'change' },
        { min: 6, message: '密码长度不能低于6位数',trigger:'change' },
        { max: 18, message: '密码长度不能大于18位数',trigger:'change' },
        ]">
      <a-input-password  placeholder="登录密码"  v-model:value="form.passWord">
        <template #prefix>
          <lock-outlined type="user" />
        </template>
      </a-input-password>
    </a-form-item>
    <a-form-item style="text-align: center">
      <a-button html-type="submit" style="width: 100%" type="danger">
        <send-outlined type="user" />
        登录
      </a-button><br><br>
    </a-form-item>
  </a-form>
</template>

<script>
import { reactive} from 'vue'
import { Login } from "@/api/login"
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue';

export default {
  name: "loginUserForm",
  setup(){
    const router = useRouter()
    const form = reactive({
      userName: '',
      passWord: '',
    })
    const onFinish = () => {
       Login(form).then(res=>{
         if (res.success){
           message.success("登录成功")
           window.location.href="./home"
         }else{
           message.error(res.msg)
         }
       })
    };
    const onFinishFailed = (errorInfo) => {
      console.log(errorInfo)
      message.error("登录失败,输入信息有误");
    };
    return {
      form,router,onFinish,onFinishFailed
    }
  }

}


</script>

<style scoped>

</style>
