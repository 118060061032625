<template>
  <a-form layout="vertical" :model="form" @finish="onFinish" name="basic" @finishFailed="onFinishFailed">
    <a-form-item name="userName" label="账号" autocomplete="off" validateFirst :rules="[
        { required: true, message: '账号不能为空',trigger:'change'},
        { min: 6, message: '账号最短为6位数字或加大小字母组合',trigger:'change'},
        { max: 18, message: '账号最短为18位数字或加大小字母组合',trigger:'change'},
    ]">
      <a-input placeholder="用户名"  v-model:value="form.userName">
        <template #prefix>
          <user-outlined type="user" />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item name="passWord" label="密码"  autocomplete="off" validateFirst :rules="[
        { required: true, message: '密码不能为空',trigger:'change'},
        { min: 6, message: '密码最短为6位数字或加大小字母组合',trigger:'change'},
        { max: 18, message: '密码最短为18位数字或加大小字母组合',trigger:'change'},
    ]">
      <a-input-password  placeholder="登录密码"  v-model:value="form.passWord">
        <template #prefix>
          <lock-outlined />
        </template>
      </a-input-password>
    </a-form-item>
    <a-form-item name="repassWord" label="重复密码"  autocomplete="off" validateFirst :rules="[
        { required: true, message: '重复密码不能为空',trigger:'change'},
        { min: 6, message: '密码最短为6位数字或加大小字母组合',trigger:'change'},
        { max: 18, message: '密码最短为18位数字或加大小字母组合',trigger:'change'},
    ]">
      <a-input-password  placeholder="重复密码"  v-model:value="form.repassWord">
        <template #prefix>
          <lock-outlined />
        </template>
      </a-input-password>
    </a-form-item>
    <a-form-item name="phone" label="手机号码"  autocomplete="off" validateFirst :rules="[
        { required: true, message: '手机号码不能为空',trigger:'change'},
        { len: 11, message: '手机号码输入错误',trigger:'change'},
    ]">
      <a-input placeholder="手机号码"  v-model:value="form.phone">
        <template #prefix>
          <mobile-outlined />
        </template>
      </a-input>
    </a-form-item>
    <a-form-item name="phoneCode" label="手机验证码"   autocomplete="off" validateFirst :rules="[
        { required: true, message: '手机验证码不能为空',trigger:'change'},
        { len: 6, message: '手机验证码输入错误',trigger:'change'},
    ]">
      <a-row>
        <a-col :span="16">
          <a-input style="width: 90%" placeholder="手机验证码"  v-model:value="form.phoneCode">
            <template #prefix>
              <mail-outlined />
            </template>
          </a-input>
        </a-col>
        <a-col :span="8" style="text-align: right">
          <a-button   :loading="iconLoading" @click="sendSMS"> <mail-outlined v-if="!iconLoading" />{{btnContent}}</a-button>
        </a-col>
      </a-row>
    </a-form-item>
    <br>
    <a-form-item style="text-align: center">
      <a-button type="primary" html-type="submit" style="width: 100%" >
        <send-outlined />
        注册账号</a-button><br><br>
    </a-form-item>
  </a-form>
</template>

<script>
import {reactive, ref} from 'vue'
import {GetSMS, Register} from "@/api/login"
import { useRouter } from 'vue-router'
import { message } from 'ant-design-vue';

export default {
  name: "regUserForm",
  setup(){
    const router = useRouter()
    const iconLoading = ref(false)
    const btnContent = ref("获取验证码")
    const form = reactive({
      userName: '',
      passWord: '',
      repassWord: "",
      phone : "",
      phoneCode : "",
      code : "12345",
    })
    const sendSMS =()=>{
      if (form.phone.length !=11){
        message.error("手机号码输入有误")
        return
      }
      GetSMS({
        phone : form.phone,
        code : "test1",
        type : "register"
      }).then(res=>{
        if (res.success){
          let index = 0
          iconLoading.value = true
          let time = setInterval(()=>{
            index++
            btnContent.value = (30-index)+"秒可获取"

            if (index>=30){
              iconLoading.value = false
              btnContent.value = "获取验证码"
              clearInterval(time)
            }
          },1000)
        }else{
          message.error("获取短信失败,"+res.msg)
        }
      })
    }
    const onFinish = () => {
      Register(form).then(res=>{
        if (res.success){
          message.success().log("注册成功")
        }else{
          message.error("注册失败"+res.msg);
        }
      })
    };
    const onFinishFailed = (errorInfo) => {
      console.log(errorInfo)
      message.error("注册失败,输入信息有误");
    };
    return {
      form,router,onFinish,onFinishFailed,iconLoading,sendSMS,btnContent
    }
  }

}


</script>

<style scoped>

</style>
