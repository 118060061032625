<template>
  <a-config-provider :locale="locale">
    <headHTML></headHTML>
    <order></order>
    <bottomHTML v-if="!store.state.isLogin"></bottomHTML>
  </a-config-provider>
</template>

<script>
import headHTML from './components/utils/Head'
import bottomHTML from './components/utils/bottom'
import Order from "@/views/order";
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import { useStore } from 'vuex'
dayjs.locale('zh-cn');


export default {
  name: 'App',
  components: {
    Order,
    headHTML,
    bottomHTML,
  },
  setup(){
    let locale = zhCN;
    const store = useStore()  // 该方法用于返回store 实例
    return {
      locale,store
    };
  },
  mounted() {

  }
}
</script>


<style type="text/css">
.icon {
  width: 1em; height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
</style>
