const sortList = (orderList,type=0,desc="0")=>{
    switch (type){
        case "1":
            orderList.sort((itemA,itemB)=> {
                return  parseFloat(itemB.summary_day.shopDataModuleItemVOS[0].realTimeNumber) - parseFloat(itemA.summary_day.shopDataModuleItemVOS[0].realTimeNumber)
            })
            break
        case "2":
            orderList.sort((itemA,itemB)=> {
                return itemB.onSale - itemA.onSale
            })
            break
        case "3":
            orderList.sort((itemA,itemB)=> {
                return itemB.forSale - itemA.forSale
            })
            break
        case "4":
            orderList.sort((itemA,itemB)=> {
                return parseFloat(itemB.totalBalance) + parseFloat(itemB.currentAmount) + parseFloat(itemB.walletAccountBalance) + parseFloat(itemB.goodsAmount) - parseFloat(itemB.brokerageAmount)
                    - (parseFloat(itemA.totalBalance) + parseFloat(itemA.currentAmount) + parseFloat(itemA.walletAccountBalance) + parseFloat(itemA.goodsAmount) - parseFloat(itemA.brokerageAmount))
            })
            break
        case "5":
            orderList.sort((itemA,itemB)=> {
                return itemB.fengxiangInfo.scndCategoryId - itemA.fengxiangInfo.scndCategoryId
            })
            break
        case "6":
            orderList.sort((itemA,itemB)=> {
                return itemB.time - itemA.time
            })
            break
        default:
            orderList.sort((a)=>{
                return a.status + a.shopInfo.status
            })
            break

    }

    let 从未更新过的 = [];
    let 今天未更新过 = [];
    let 正常的 = [];
    let 掉线的 = [];
    let todayTime = new Date(new Date().toLocaleDateString()).getTime() / 1000;
    orderList.forEach(order=>{
        if (order.status > -1 && order.shopInfo.status > -1 && order.time > todayTime){
            正常的.push(order)
        }else if(order.status==-1){
            从未更新过的.push(order)
        }else if(order.shopInfo.status==-1){
            掉线的.push(order)
        }else if(order.time > todayTime){
            今天未更新过.push(order)
        }
    })
    if (desc=="1"){
        正常的.reverse()
    }
    今天未更新过.forEach(a=>{
        正常的.push(a)
    })
    掉线的.forEach(a=>{
        正常的.push(a)
    })
    从未更新过的.forEach(a=>{
        正常的.push(a)
    })
    return 正常的
}

export default sortList;
