<template>
  <div style="text-align: left;width: 98%">
    <div style="text-align: center">
      <a-alert
          message="本页面实时数据显示需要依靠运行获芸增强组件支持,如果您需要实时查看数据。请保持获芸增强组件的运行状态"
          type="warning"
          closable
      />
    </div>
    <br>
    <a-card size="small" title="选择分组">
      <a-row :gutter="20">
        <a-col :span="20">
          <a-select
              v-model:value="useShopGroupIds"
              style="width: 100%;"
              :allowClear="true"
              show-search
              size="small"
              @change="getInfo"
              placeholder="请选择你要查看数据的分组"
          >
            <a-select-option v-for="shop in shopGroup.group" :key="shop.id" :name="shop.name" :value="shop.id">
              {{shop.name}}{{'('+shop.data.length+'个店铺)'}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="4" style="text-align: right">
          <a-button  @click="editGorup.open(shopGroup)" type="primary" size="small"> <edit-outlined />编辑分组</a-button>&nbsp;
          <a-button  @click="editGorup.open(shopGroup)" size="small"><share-alt-outlined />分享分组</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>
    <a-card size="small" title="选择查看信息">
      <a-checkbox v-model:checked="viewOptions.infoVeiw">信息统计</a-checkbox>
      <a-checkbox v-model:checked="viewOptions.orderInfo">订单发货</a-checkbox>
      <a-checkbox v-model:checked="viewOptions.amountView">资产统计</a-checkbox>
      <a-checkbox v-model:checked="viewOptions.fengxiangbiaoView">风向标</a-checkbox>
      <a-divider  type="vertical"></a-divider>
      <a-radio-group @change="changeSort" size="small" v-model:value="byOrder">
        <a-radio-button size="small" value="0">默认排序</a-radio-button>
        <a-radio-button size="small" value="1">成交金额</a-radio-button>
        <a-radio-button size="small" value="2">在售商品</a-radio-button>
        <a-radio-button size="small" value="3">待售商品</a-radio-button>
        <a-radio-button size="small" value="4">资产排序</a-radio-button>
        <a-radio-button size="small" value="6">时间排序</a-radio-button>
      </a-radio-group>
      <a-divider  type="vertical"></a-divider>
      <a-radio-group @change="changeSort" size="small" v-model:value="descValue">
        <a-radio-button size="small" value="0">降序</a-radio-button>
        <a-radio-button size="small" value="1">升序</a-radio-button>
      </a-radio-group>
      <a-divider  type="vertical"></a-divider>
      <a-checkbox v-model:checked="simpleTable">简易模式</a-checkbox>
    </a-card>
    <br v-if="shopInfos.length > 0">
    <a-row v-if="shopInfos.length > 0" :gutter="20" align="middle">
      <a-col :span="4">
        <a-badge-ribbon :color="moneyRealTimeNumberTotal < moneyYesterdayNumberTotal?'#67C23A':'#F56C6C'">
          <template #text>
            <rise-outlined v-if="moneyRealTimeNumberTotal > moneyYesterdayNumberTotal" />
            <fall-outlined v-if="moneyRealTimeNumberTotal < moneyYesterdayNumberTotal"/>
            <line-outlined v-if="moneyRealTimeNumberTotal == moneyYesterdayNumberTotal" />
          </template>
          <a-card  size="small">
            <template #title>
              <span>总成交金额:</span>
            </template>
            <div style="text-align: right">
              <a-row>
                <a-col :span="10">
                  <div :style="{'font-size': '36px','color' : moneyRealTimeNumberTotal > moneyYesterdayNumberTotal?'#F56C6C':'#67C23A','text-align': 'center'}">
                    <p>
                      <rise-outlined  v-if="moneyRealTimeNumberTotal > moneyYesterdayNumberTotal" />
                      <fall-outlined  v-if="moneyRealTimeNumberTotal < moneyYesterdayNumberTotal" />
                    </p>
                    <p style="font-size: 16px;margin-top: -32px">{{Math.abs((((moneyRealTimeNumberTotal / moneyYesterdayNumberTotal)-1)) * 100).toFixed(2)+"%"}}</p>
                  </div>
                </a-col>
                <a-col :span="14">
                  <p :style="{'color' : moneyRealTimeNumberTotal < moneyYesterdayNumberTotal?'#67C23A':'#F56C6C'}">今日:&nbsp;&nbsp;{{moneyRealTimeNumberTotal.toFixed(2)}}元</p>
                  <p>昨日:&nbsp;&nbsp;{{moneyYesterdayNumberTotal.toFixed(2)}}元</p>
                  <p @click="setYear(2023)">本月:&nbsp;&nbsp;{{moneyMonthNumberTotal.toFixed(2)}}元 </p>
                  <a-modal v-model:visible="visible" title="历史记录" width="80%" :maskClosable="false" style="top: 20px">
                    <template #footer>
                    </template>
                    <a-spin :spinning="spinning">
                      <div style="text-align: center">
                        <a-button @click="setYear(2022)" :disabled="year==2022" style="top: -4px"><left-outlined />上一年</a-button>&nbsp;&nbsp;&nbsp;&nbsp;
                        <span style="font-size: 30px">{{year}}</span>&nbsp;&nbsp;&nbsp;&nbsp;
                        <a-button @click="setYear(2023)" :disabled="year==2023" style="top: -4px">下一年<right-outlined /></a-button>
                      </div>
                      <div v-if="year==2023" style="margin-top: 16px">
                        <a-row :gutter="[18,16]">
                          <a-col :span="8">
                            <a-card hoverable title="一月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="19227" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="865232.32" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="44222" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 123821</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="二月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="23874" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1265325.33" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="59685" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 161149</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="三月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="45756" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="2036523.31" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="45756" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 320290</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="四月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="39158" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="2623652.69" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="1756326" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 3975258</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="五月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="orderMonthNumberTotal" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="moneyMonthNumberTotal.toFixed(2)" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="userMonthNumberTotal" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ {{viewMonthNumberTotal}}</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                        </a-row>
                      </div>
                      <div v-else-if="year==2022" style="margin-top: 16px">
                        <a-row :gutter="[18,16]">
                          <a-col :span="8">
                            <a-card hoverable title="一月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="24191" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1136964.34" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="93041" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 195387</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="二月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="22115" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1083623.33" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="81907" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/196576 </span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="三月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="27933" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1536326.54" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="111733" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 324025</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="四月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="22706" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1362353.70" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="108123" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 270308</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="五月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="28176" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1436986.63" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="117401" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 270022</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="六月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="30355" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1365986.87" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="131979" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 316751</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="七月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="31926" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1564356.99" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="118243" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 342905</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="八月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="36281" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1632654.87" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="145125" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 362812</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="九月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="23579" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1532653.64" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="87331" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 235793</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="十月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="33380" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1635623.55" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="145131" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 377340</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="十一月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="33275" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1763562.53" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="114741" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 321274</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                          <a-col :span="8">
                            <a-card hoverable title="十二月份收入">
                              <a-row :gutter="[8,8]">
                                <a-col :span="12">
                                  <a-statistic title="成交单量" :value="24664" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">单</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="12">
                                  <a-statistic title="营业额" :precision="2" :value="1356523.35" >
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">元</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                                <a-col :span="24">
                                  <a-statistic title="总访客/总浏览量" :value="107235" style="margin-right: 50px">
                                    <template #suffix>
                                      &nbsp;<span style="font-size: 16px;color:#909399">/ 289535</span>
                                    </template>
                                  </a-statistic>
                                </a-col>
                              </a-row>
                            </a-card>
                          </a-col>
                        </a-row>
                      </div>
                    </a-spin>
                  </a-modal>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-badge-ribbon>
      </a-col>
      <a-col :span="4">
        <a-badge-ribbon :color="orderRealTimeNumberTotal < orderYesterdayNumberTotal?'#67C23A':'#F56C6C'">
          <template #text>
            <rise-outlined v-if="orderRealTimeNumberTotal > orderYesterdayNumberTotal" />
            <fall-outlined v-if="orderRealTimeNumberTotal < orderYesterdayNumberTotal"/>
            <line-outlined v-if="orderRealTimeNumberTotal == orderYesterdayNumberTotal" />
          </template>
          <a-card title="总成交单量" size="small">
            <div style="text-align: right">
              <a-row>
                <a-col :span="10">
                  <div :style="{'font-size': '36px','color' : orderRealTimeNumberTotal > orderYesterdayNumberTotal?'#F56C6C':'#67C23A','text-align': 'center'}">
                    <p>
                      <rise-outlined  v-if="orderRealTimeNumberTotal > orderYesterdayNumberTotal" />
                      <fall-outlined  v-if="orderRealTimeNumberTotal < orderYesterdayNumberTotal" />
                    </p>
                    <p style="font-size: 16px;margin-top: -32px">{{Math.abs((((orderRealTimeNumberTotal / orderYesterdayNumberTotal)-1)) * 100).toFixed(2)+"%"}}</p>
                  </div>
                </a-col>
                <a-col :span="14">
                  <p :style="{'color' : orderRealTimeNumberTotal < orderYesterdayNumberTotal?'#67C23A':'#F56C6C'}">今日:&nbsp;&nbsp;{{orderRealTimeNumberTotal}}单</p>
                  <p>昨日:&nbsp;&nbsp;{{orderYesterdayNumberTotal}}单</p>
                  <p>本月:&nbsp;&nbsp;{{orderMonthNumberTotal}}单</p>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-badge-ribbon>
      </a-col>
      <a-col :span="4">
        <a-badge-ribbon :color="orderRealTimeNumberTotal < userYesterdayNumberTotal?'#67C23A':'#F56C6C'">
          <template #text>
            <rise-outlined v-if="userRealTimeNumberTotal > userYesterdayNumberTotal" />
            <fall-outlined v-if="userRealTimeNumberTotal < userYesterdayNumberTotal"/>
            <line-outlined v-if="userRealTimeNumberTotal == userYesterdayNumberTotal" />
          </template>
          <a-card title="总访客数" size="small">
            <div style="text-align: right">
              <a-row>
                <a-col :span="10">
                  <div :style="{'font-size': '36px','color' : userRealTimeNumberTotal > userYesterdayNumberTotal?'#F56C6C':'#67C23A','text-align': 'center'}">
                    <p>
                      <rise-outlined  v-if="userRealTimeNumberTotal > userYesterdayNumberTotal" />
                      <fall-outlined  v-if="userRealTimeNumberTotal < userYesterdayNumberTotal" />
                    </p>
                    <p style="font-size: 16px;margin-top: -32px">{{Math.abs((((userRealTimeNumberTotal / userYesterdayNumberTotal)-1)) * 100).toFixed(2)+"%"}}</p>
                  </div>
                </a-col>
                <a-col :span="14">
                  <p :style="{'color' : userRealTimeNumberTotal < userYesterdayNumberTotal?'#67C23A':'#F56C6C'}">今日:&nbsp;&nbsp;{{userRealTimeNumberTotal}}</p>
                  <p>昨日:&nbsp;&nbsp;{{userYesterdayNumberTotal}}</p>
                  <p>本月:&nbsp;&nbsp;{{userMonthNumberTotal}}</p>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-badge-ribbon>
      </a-col>
      <a-col :span="4">
        <a-badge-ribbon :color="viewRealTimeNumberTotal < viewYesterdayNumberTotal?'#67C23A':'#F56C6C'">
          <template #text>
            <rise-outlined v-if="viewRealTimeNumberTotal > viewYesterdayNumberTotal" />
            <fall-outlined v-if="viewRealTimeNumberTotal < viewYesterdayNumberTotal"/>
            <line-outlined v-if="viewRealTimeNumberTotal == viewYesterdayNumberTotal" />
          </template>
          <a-card title="总浏览量" size="small">
            <div style="text-align: right">
              <a-row>
                <a-col :span="10">
                  <div :style="{'font-size': '36px','color' : viewRealTimeNumberTotal > viewYesterdayNumberTotal?'#F56C6C':'#67C23A','text-align': 'center'}">
                    <p>
                      <rise-outlined  v-if="viewRealTimeNumberTotal > viewYesterdayNumberTotal" />
                      <fall-outlined  v-if="viewRealTimeNumberTotal < viewYesterdayNumberTotal" />
                    </p>
                    <p style="font-size: 16px;margin-top: -32px">{{Math.abs((((viewRealTimeNumberTotal / viewYesterdayNumberTotal)-1)) * 100).toFixed(2)+"%"}}</p>
                  </div>
                </a-col>
                <a-col :span="14">
                  <p :style="{'color' : viewRealTimeNumberTotal < viewYesterdayNumberTotal?'#67C23A':'#F56C6C'}">今日:&nbsp;&nbsp;{{viewRealTimeNumberTotal}}</p>
                  <p>昨日:&nbsp;&nbsp;{{viewYesterdayNumberTotal}}</p>
                  <p>本月:&nbsp;&nbsp;{{viewMonthNumberTotal}}</p>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-badge-ribbon>
      </a-col>
      <a-col :span="4">
        <a-card title="店铺" size="small">
          <a-row>
            <a-col :span="24">
              <a-statistic title="统计店铺/总店铺数" :value="shopNum +'/'+(shopGroup.group.filter(shop => shop.id == useShopGroupIds)[0].data.length)">
                <template #suffix>
                  <span style="font-size: 16px">个</span>&nbsp;&nbsp;
                </template>
              </a-statistic>
              <br>
              <div style="margin-top: 0px;text-align: right">
                <span style="font-size: 12px;color: #606266">10分钟内未更新不统计</span>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
      <a-col :span="4">
        <a-card title="总资产" size="small">
          <a-row>
            <a-col :span="24">
              <a-statistic title="总资产值" :precision="2">
                <template #formatter>
                  <a-popover title="详细">
                    <template #content>
<!--                      goodsAmountAll : 0,
                      currentAmountAll : 0,
                      walletAccountBalanceAll  : 0,
                      totalBalanceAll  : 0,
                      brokerageAmountAll : 0,-->
                      <h5 style="text-align: left;"><span style="font-size:12px">待结算货款:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#F56C6C'}">{{'+' +goodsAmountAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">待扣除佣金:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#67C23A'}">{{'-' +brokerageAmountAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">钱包余额:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#F56C6C'}">{{'+' +walletAccountBalanceAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">营销钱包:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#F56C6C'}">{{'+' +totalBalanceAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">店铺保证金:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#F56C6C'}">{{'+' +currentAmountAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">风险保证金:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':'#F56C6C'}">{{'+' +riskAmountAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">本月总赔付:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':''}">{{toMonthCompensateAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">上月总赔付:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':''}">{{lastMonthCompensateAll.toFixed(2) + ' 元'}}</span></h5>
                      <h5 style="text-align: left;"><span style="font-size:12px">上上月总赔付:</span></h5>
                      <h5 style="text-align: right"><span :style="{'font-size': '12px','color':''}">{{firstTwoMonthCompensateAll.toFixed(2) + ' 元'}}</span></h5>
                    </template>
                    <span>{{amountAll}}</span>
                  </a-popover>
                </template>
                <template #suffix>
                  <span style="font-size: 16px">元</span>
                </template>
              </a-statistic>
              <br>
              <div style="margin-top: 0px;text-align: right">
                <span style="font-size: 12px;color: #606266">仅供参考,结算为准</span>
              </div>
            </a-col>
          </a-row>
        </a-card>
      </a-col>
    </a-row>
    <br>
    <div v-if="simpleTable">
      <a-table :columns="columns" :data-source="shopInfos" :pagination="false">
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'shopName'">
            <a :style="{'color':record.shopInfo.status > -1?'#409EFF':'#F56C6C','fontSize':'12px'}" @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+record.shopId)" >{{record.shopInfo.shopName}}&nbsp;{{record.shopInfo.status > -1?'':'(掉线)'}}</a>
          </template>
          <template v-if="column.key === 'MoneyToday'">
            {{parseFloat(record.summary_day.shopDataModuleItemVOS[0].realTimeNumber).toFixed(2)+ "元"}}
          </template>
          <template v-if="column.key === 'MoneyYesterday'">
            {{parseFloat(record.summary_day.shopDataModuleItemVOS[0].yesterdayNumber).toFixed(2)+ "元"}}
          </template>
          <template v-if="column.key === 'NumToday'">
            {{ record.summary_day.shopDataModuleItemVOS[1].realTimeNumber + "单"}}
          </template>
          <template v-if="column.key === 'NumYesterday'">
            {{ record.summary_day.shopDataModuleItemVOS[1].yesterdayNumber + "单"}}
          </template>
          <template v-if="column.key === 'PvToday'">
            {{ record.summary_day.shopDataModuleItemVOS[3].realTimeNumber }}
          </template>
          <template v-if="column.key === 'PvYesterday'">
            {{ record.summary_day.shopDataModuleItemVOS[3].yesterdayNumber }}
          </template>
          <template v-if="column.key === 'RateToday'">
            {{
              ((record.summary_day.shopDataModuleItemVOS[2].realTimeNumber / record.summary_day.shopDataModuleItemVOS[3].realTimeNumber)*100).toFixed(2)+"%"
            }}
          </template>
          <template v-if="column.key === 'RateYesterday'">
            {{
              ((record.summary_day.shopDataModuleItemVOS[2].yesterdayNumber / record.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)*100).toFixed(2)+"%"
            }}
          </template>
          <template v-if="column.key === 'wallet'">
            <a-popover placement="topRight">
              <template #content>
                <p style="text-align: right;">&nbsp;店铺近90天待结算货款&nbsp;<span style="color:#F56C6C;">+</span></p>
                <p style="text-align: right;margin-top: -12px">&nbsp;钱包余额&nbsp;<span style="color:#F56C6C;">+</span></p>
                <p style="text-align: right;margin-top: -12px">&nbsp;营销钱包余额&nbsp;<span style="color:#F56C6C;">+</span></p>
                <p style="text-align: right;margin-top: -12px">&nbsp;店铺保证金&nbsp;<span style="color:#67C23A;">-</span></p>
                <p style="text-align: right;margin-top: -12px">&nbsp;店铺近90天待扣除佣金&nbsp;</p>
                <p style="text-align: right;margin-top: -12px">=总资产</p>
              </template>
              <span style="font-size: 12px">
                          {{
                  (parseFloat(record.totalBalance) +
                      parseFloat(record.currentAmount) +
                      parseFloat(record.walletAccountBalance) +
                      parseFloat(record.goodsAmount) -
                      parseFloat(record.brokerageAmount))
                      .toFixed(2)
                }}元
                        </span>
            </a-popover>
          </template>
          <template v-if="column.key === 'tool'">
            <a-button target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+record.shopId+'&url=https://fw.jd.com/home/sers.action'" style="margin-top: 12px" type="primary" size="small">
              <template #icon>
                <svg t="1679452660239" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1068" width="60" height="60"><path d="M777.045333 128a85.333333 85.333333 0 0 1 85.205334 80.533333l33.6 597.333334A85.333333 85.333333 0 0 1 810.666667 896H213.333333a85.333333 85.333333 0 0 1-85.205333-90.133333l33.621333-597.333334A85.333333 85.333333 0 0 1 246.954667 128h530.090666zM682.666667 256a42.666667 42.666667 0 0 0-37.738667 62.528c-25.237333 56.768-72.789333 86.826667-132.928 86.826667-60.522667 0-107.882667-29.866667-132.949333-86.741334a42.666667 42.666667 0 1 0-63.36 14.165334C349.184 420.16 421.973333 469.333333 512 469.333333c89.642667 0 162.602667-49.408 196.266667-136.533333A42.666667 42.666667 0 0 0 682.666667 256z" fill="#ffffff" p-id="1069"></path></svg>
              </template>
              &nbsp;&nbsp; 服务市场
            </a-button>
          </template>
        </template>
      </a-table>
      <br>

      <h5>总待成交金额:{{moneyRealTimeNumberTotal}}元 总待成交订单:{{orderRealTimeNumberTotal}}单 总待售商品数量:{{forSaleTotal}}  总在售商品数量:{{onSaleTotal}}</h5>
    </div>
    <div v-else style="margin-top: 12px" id="summary">
      <a-list size="large" :data-source="shopInfos">
        <template #renderItem="{ item }" >
          <a-list-item  key="item.shopId">
            <a-list-item-meta>
              <template #description>
                <a-card size="small">
                  <template #title>
                    <a-row align="middle"  :gutter="20">
                      <a-col :span="5">
                        <a-tooltip placement="topLeft" :title="item.shopInfo.status > -1?'店铺在线状态正常':'店铺在线状态异常,可能已掉线。'+item.shopInfo.lastError">
                          <a :style="{'color':item.shopInfo.status > -1?'#409EFF':'#F56C6C','fontSize':'12px'}" @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+item.shopId)" >{{item.shopInfo.shopName}}&nbsp;{{item.shopInfo.status > -1?'':'(掉线)'}}</a>
                        </a-tooltip>
                        <a-divider  type="vertical"></a-divider>
                        <a @click="editShopUser.open(item.shopInfo)" type="text" size="small" style="font-size: 12px">{{item.shopInfo.userName==""?'点击编辑账号':item.shopInfo.userName}}&nbsp;</a>
                        <a v-if="!item.shopInfo.userName==''" @click="copy(item.shopInfo.userName)">&nbsp;<copy-outlined />复制</a>
                      </a-col>
                      <a-col :span="2">
                        <span :style="{'font-size': '12px','color' : item.onSale > 0 ? '':'#F56C6C'}">在售商品数:&nbsp;{{item.onSale}}</span>
                      </a-col>
                      <a-col :span="2">
                        <span style="font-size: 12px">待售商品数:&nbsp;{{item.forSale}}</span>
                      </a-col>
                      <a-col :span="2">
                        <span style="font-size: 12px">经营类目:&nbsp;{{item.fengxiangInfo!=null?item.fengxiangInfo.scndCategoryName:""}}</span>
                      </a-col>
                      <a-col :span="2">
                        <a-popover placement="topRight">
                          <template #content>
                            <p style="text-align: right;">&nbsp;店铺近90天待结算货款&nbsp;<span style="color:#F56C6C;">+</span></p>
                            <p style="text-align: right;margin-top: -12px">&nbsp;钱包余额&nbsp;<span style="color:#F56C6C;">+</span></p>
                            <p style="text-align: right;margin-top: -12px">&nbsp;营销钱包余额&nbsp;<span style="color:#F56C6C;">+</span></p>
                            <p style="text-align: right;margin-top: -12px">&nbsp;店铺保证金&nbsp;<span style="color:#67C23A;">-</span></p>
                            <p style="text-align: right;margin-top: -12px">&nbsp;店铺近90天待扣除佣金&nbsp;</p>
                            <p style="text-align: right;margin-top: -12px">=总资产</p>
                          </template>
                          <span style="font-size: 12px">资产:
                          {{
                              (parseFloat(item.totalBalance) +
                                  parseFloat(item.currentAmount) +
                                  parseFloat(item.walletAccountBalance) +
                                  parseFloat(item.riskAmount) +
                                  parseFloat(item.goodsAmount) -
                                  parseFloat(item.brokerageAmount))
                                  .toFixed(2)
                            }}元
                        </span>
                        </a-popover>
                      </a-col>
                      <a-col :span="3">
                        <a-tooltip placement="topLeft" :title="(item.uploadZoneAllSize - item.uploadZoneUsedSize) <  1024 *1024 * 5 ?'图片空间剩余不足5GB':'图片空间容量充足'">
                          <a target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://imgzone.shop.jd.com/'" :style="{'font-size': '12px','color':(item.uploadZoneAllSize - item.uploadZoneUsedSize) <  1024 *1024 * 5 ?'#E6A23C':'#67C23A'}">图片空间:&nbsp;{{item.uploadZoneUsedShow}}</a>
                        </a-tooltip>
                      </a-col>
                      <a-col :span="4">
                        <a-tooltip placement="topLeft" :title="(new Date().getTime() / 1000) - item.time > 60 * 10 ?'十分钟前更新过' : '十分钟之内更新过'">
                          <span  :style="{'font-size': '12px','color' : (new Date().getTime() / 1000) - item.time > 60 * 10 ?'#E6A23C' : '#67C23A' }">最后更新:&nbsp;{{transformTime(item.time)}} </span>
                        </a-tooltip>
                      </a-col>
                      <a-col :span="4" style="text-align: right">
                        <a style="color:#F56C6C;" @click="openWindow('https://passport.jd.com/common/loginPage?from=pop_vender&regTag=2&shopId='+item.shopId)" v-if="item.shopInfo.status == -1 && item.shopInfo.lastError!='' &&item.shopInfo.lastError!=null">自动登录错误:{{item.shopInfo.lastError}}</a>
                        <a-divider  v-if="item.shopInfo.status == -1 && item.shopInfo.lastError!='' &&item.shopInfo.lastError!=null" type="vertical"></a-divider>
                        <a target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fw.jd.com/home/sers.action'">
                          <svg t="1679451263299" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2782" width="200" height="200"><path d="M777.045333 128a85.333333 85.333333 0 0 1 85.205334 80.533333l33.6 597.333334A85.333333 85.333333 0 0 1 810.666667 896H213.333333a85.333333 85.333333 0 0 1-85.205333-90.133333l33.621333-597.333334A85.333333 85.333333 0 0 1 246.954667 128h530.090666zM682.666667 256a42.666667 42.666667 0 0 0-37.738667 62.528c-25.237333 56.768-72.789333 86.826667-132.928 86.826667-60.522667 0-107.882667-29.866667-132.949333-86.741334a42.666667 42.666667 0 1 0-63.36 14.165334C349.184 420.16 421.973333 469.333333 512 469.333333c89.642667 0 162.602667-49.408 196.266667-136.533333A42.666667 42.666667 0 0 0 682.666667 256z" fill="#1296db" p-id="2783"></path></svg>
                          服务市场
                        </a>&nbsp;&nbsp;
                        <a @click="updateShop(item)"><reload-outlined />&nbsp;刷新</a>&nbsp;&nbsp;
                        <a-dropdown>
                          <template #overlay>
                            <a-menu>
                              <a-menu-item key="1">
                                <a-button @click="copy(item.shopInfo.thor)" type="text" size="small"><reload-outlined />刷新信息</a-button>
                              </a-menu-item>
                              <a-menu-item key="1">
                                <a-button @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+item.shopId)" type="text" size="small"><shop-outlined />进入店铺</a-button>
                              </a-menu-item>
                              <a-menu-item key="4">
                                <a-button @click="editShopUser.open(item.shopInfo)" type="text" size="small"><editOutlined />编辑账号</a-button>
                              </a-menu-item>
                              <a-menu-item key="2">
                                <a-button @click="copy(item.shopInfo.thor)" type="text" size="small"><copyOutlined />复制CK</a-button>
                              </a-menu-item>
                              <a-menu-item key="3">
                                <a-button @click="copy(item.shopInfo.userName)" type="text" size="small"><copyOutlined />登录账号</a-button>
                              </a-menu-item>
                              <a-menu-item key="5">
                                <a-button @click="CloseService(item.shopInfo.shopId,0)" type="text" size="small">关闭极速审核</a-button>
                              </a-menu-item>
                              <a-menu-item key="6">
                                <a-button @click="CloseService(item.shopInfo.shopId,1)" type="text" size="small">关闭闪电退款</a-button>
                              </a-menu-item>
                            </a-menu>
                          </template>
                          <a-button type="primary" size="small">
                            <setting-outlined />更多
                            <DownOutlined />
                          </a-button>
                        </a-dropdown>
                      </a-col>
                    </a-row>
                  </template>
                  <a-row align="middle">
                    <a-col :span="2" style="text-align: center">
                      <a-avatar :size="64">{{item.shopInfo.shopName.substring(0,4)}}</a-avatar>
                    </a-col>
                    <a-col :span="22">
                      <a-row v-if="item.summary_day!=null && item.status != -1" :gutter="60" align="middle">
                        <a-col :span="4" style="text-align: center">
                          <a-row>
                            <a-col :span="12" style="text-align: left">
                                <span>
                                  今日成交金额:
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber  <
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        (item.summary_day.shopDataModuleItemVOS[0].realTimeNumber).toFixed(2)+
                                        "元"
                                  }}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>昨日成交金额:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.summary_day==null?'请运行获芸增强组件获取':
                                    item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber==null?
                                        "0单":parseFloat(
                                            item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber).toFixed(2) +
                                        "元"}}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>涨幅指数:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[0].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        (((
                                            item.summary_day.shopDataModuleItemVOS[0].realTimeNumber /
                                            item.summary_day.shopDataModuleItemVOS[0].yesterdayNumber)-1) * 100).toFixed(2) +
                                        "%"
                                  }}
                                </span>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-row>
                            <a-col :span="12" style="text-align: left">
                                <span>
                                  今日成交单量:
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        item.summary_day.shopDataModuleItemVOS[1].realTimeNumber+
                                        "单"
                                  }}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>昨日成交单量:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.summary_day==null?'请运行获芸增强组件获取':
                                    item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber==null?
                                        "0单":parseFloat(
                                            item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber) +
                                        "单"}}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>涨幅指数:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[1].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        (((
                                            item.summary_day.shopDataModuleItemVOS[1].realTimeNumber /
                                            item.summary_day.shopDataModuleItemVOS[1].yesterdayNumber)-1) * 100).toFixed(2) +
                                        "%"
                                  }}
                                </span>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-row>
                            <a-col :span="12" style="text-align: left">
                                <span>
                                  今日访客数量:
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        item.summary_day.shopDataModuleItemVOS[3].realTimeNumber
                                  }}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>昨日访客数量:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.summary_day==null?'请运行获芸增强组件获取':
                                    item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null?
                                        "0":parseFloat(
                                            item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber) +
                                        ""}}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>涨幅指数:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber?'#F56C6C':'#67C23A'}">
                                  <fall-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber >
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber" />
                                  <rise-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber <
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber" />
                                  <line-outlined v-if="
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber ==
                                  item.summary_day.shopDataModuleItemVOS[3].realTimeNumber  ||
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        (((
                                            item.summary_day.shopDataModuleItemVOS[3].realTimeNumber /
                                            item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)-1) * 100).toFixed(2) +
                                        "%"
                                  }}
                                </span>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-row>
                            <a-col :span="12" style="text-align: left">
                                <span>
                                  今日转换比率:
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) >
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber) ?'#F56C6C':'#67C23A'}">

                                  <rise-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) >
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)" />

                                  <fall-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) <
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)" />

                                  <line-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber) ==
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber  / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) ||
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null" />&nbsp;&nbsp;{{
                                    item.summary_day==null?'获取失败':
                                        ((item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber)*100).toFixed(2)+"%"
                                  }}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>昨日转换比率:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.summary_day==null?'请运行获芸增强组件获取':
                                    item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null?
                                        "0%":
                                          ((item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)*100).toFixed(2)+"%"
                                  }}
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>涨幅指数:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.summary_day==null?'#909399':
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) >
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber) ?'#F56C6C':'#67C23A'}">

                                  <rise-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) >
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)" />

                                  <fall-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) <
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber  / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber)" />

                                  <line-outlined v-if="
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber) ==
                                  parseFloat(item.summary_day.shopDataModuleItemVOS[2].realTimeNumber  / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) ||
                                  item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null" />
                                  &nbsp;&nbsp;
                                  {{
                                    item.summary_day==null?'获取失败':
                                        (((
                                            (item.summary_day.shopDataModuleItemVOS[2].realTimeNumber / item.summary_day.shopDataModuleItemVOS[3].realTimeNumber) /
                                            (item.summary_day.shopDataModuleItemVOS[2].yesterdayNumber / item.summary_day.shopDataModuleItemVOS[3].yesterdayNumber))-1) * 100).toFixed(2) +
                                        "%"
                                  }}
                                </span>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-row>
                            <a-col :span="12" style="text-align: left">
                                <span>
                                  本月赔付:
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span :style="
                                {'color' :item.toMonthCompensate > item.lastMonthCompensate ?'#F56C6C':'#67C23A'}">
                                  {{item.toMonthCompensate}}元
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>上月赔付:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.lastMonthCompensate}}元
                                </span>
                            </a-col>
                            <a-col :span="12" style="text-align: left">
                              <span>前两月赔付:</span>
                            </a-col>
                            <a-col :span="12" style="text-align: right">
                                <span>
                                  {{item.firstTwoMonthCompensate}}元
                                </span>
                            </a-col>
                          </a-row>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-popconfirm
                              title="是否解除绑定店铺?"
                              ok-text="确定"
                              @confirm="handUnbindShopId(item.shopId)"
                              cancel-text="取消"
                          >
                            <a-button type="primary" danger size="small"><delete-outlined />解除绑定</a-button>
                          </a-popconfirm><br>
                          <a-button target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fw.jd.com/home/sers.action'" style="margin-top: 12px" type="primary" size="small">
                            <template #icon>
                              <svg t="1679452660239" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1068" width="60" height="60"><path d="M777.045333 128a85.333333 85.333333 0 0 1 85.205334 80.533333l33.6 597.333334A85.333333 85.333333 0 0 1 810.666667 896H213.333333a85.333333 85.333333 0 0 1-85.205333-90.133333l33.621333-597.333334A85.333333 85.333333 0 0 1 246.954667 128h530.090666zM682.666667 256a42.666667 42.666667 0 0 0-37.738667 62.528c-25.237333 56.768-72.789333 86.826667-132.928 86.826667-60.522667 0-107.882667-29.866667-132.949333-86.741334a42.666667 42.666667 0 1 0-63.36 14.165334C349.184 420.16 421.973333 469.333333 512 469.333333c89.642667 0 162.602667-49.408 196.266667-136.533333A42.666667 42.666667 0 0 0 682.666667 256z" fill="#ffffff" p-id="1069"></path></svg>
                            </template>
                            &nbsp;&nbsp; 服务市场
                          </a-button>
                        </a-col>
                      </a-row>
                      <a-row v-if="item.status == -1" :gutter="60" align="middle">
                        <a-col :span="20" style="text-align: center">
                              <span style="font-size:12px;color: #F56C6C">
                                店铺从未更新过数据
                              </span>
                        </a-col>
                        <a-col :span="4" style="text-align: center">
                          <a-popconfirm
                              title="是否解除绑定店铺?"
                              ok-text="确定"
                              @confirm="handUnbindShopId(item.shopId)"
                              cancel-text="取消"
                          >
                            <a-button type="primary" danger size="small"><delete-outlined />解除绑定</a-button>
                          </a-popconfirm>
                        </a-col>
                      </a-row>
                    </a-col>
                  </a-row>
                  <div v-if="viewOptions.fengxiangbiaoView && item.status != -1">
                    <fengxiangbiao :item="item"></fengxiangbiao>
                  </div>
                  <div style="margin-top: 10px" v-if="viewOptions.orderInfo && item.status != -1">
                    <sidebar :schedules="item.schedule" :shopId="item.shopId"></sidebar>
                  </div>
                  <div v-if="viewOptions.amountView && item.status != -1">
                    <amount :item="item"></amount>
                  </div>
                </a-card>
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list>
      <a-empty v-if="shopInfos.length > 0">
        <template #description>
          <span>
            没有更多的店铺了
          </span>
        </template>
      </a-empty>
    </div>
  </div>
  <br><br><br>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="text-align: left;z-index:10001">
      <a-card size="small" >
        <a-row>
          <a-col :span="24" style="text-align: center">
            <span>显示信息:</span>&nbsp;&nbsp;&nbsp;
            <a-checkbox v-model:checked="viewOptions.infoVeiw">信息统计</a-checkbox>
            <a-checkbox v-model:checked="viewOptions.orderInfo">订单发货</a-checkbox>
            <a-checkbox v-model:checked="viewOptions.amountView">资产统计</a-checkbox>
            <a-checkbox v-model:checked="viewOptions.fengxiangbiaoView">风向标</a-checkbox>
            <a-divider  type="vertical"></a-divider>
            <a-radio-group @change="changeSort" size="small" v-model:value="byOrder">
              <a-radio-button size="small" value="0">默认排序</a-radio-button>
              <a-radio-button size="small" value="1">成交金额</a-radio-button>
              <a-radio-button size="small" value="2">在售商品</a-radio-button>
              <a-radio-button size="small" value="3">待售商品</a-radio-button>
              <a-radio-button size="small" value="4">资产排序</a-radio-button>
              <a-radio-button size="small" value="6">时间排序</a-radio-button>
            </a-radio-group>
            <a-divider  type="vertical"></a-divider>
            <a-radio-group @change="changeSort" size="small" v-model:value="descValue">
              <a-radio-button size="small" value="0">降序</a-radio-button>
              <a-radio-button size="small" value="1">升序</a-radio-button>
            </a-radio-group>
            <a-divider  type="vertical"></a-divider>
            <a-checkbox v-model:checked="simpleTable">简易模式</a-checkbox>
            <a-divider  type="vertical"></a-divider>
            <a-button @click="exportCsv" size="small" type="primary"><cloud-download-outlined />导出信息</a-button>
            <a-divider  type="vertical"></a-divider>
            <a-button @click="getInfo" size="small" type="primary"><reload-outlined />刷新信息</a-button>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>
import {message} from "ant-design-vue";
import {info,shopGroup,ShopIds} from "@/api/shopIds";
import fengxiangbiao from "./components/fengxiangbiao";
import sidebar from "./components/sidebar";
import amount from "./components/amount";
import editShopUser from "./server/editShopUserWindow"
import editGorup from "./server/editGorupWindow"
import {toClipboard} from "@soerenmartius/vue3-clipboard";
import summaryDay from "@/config/summaryDay";
import fengxiangbiaoInfo from "@/config/summaryDay";
import sortList from "@/components/page/shopMange/api/sort";
import {unbindShopId} from "@/api/shop";
import {updateShopInfo ,CloseService} from "./api/sysnc"
import {getItem} from "@/utils/storage";
import { Empty } from 'ant-design-vue';

export default {
  name: "shopList",
  data(){
    return {
      visible : false,
      shopGroup : {},
      simpleTable :false,
      useShopGroupIds :0,
      shopIds :[],
      year : 2023,
      time : null,
      spinning : false,
      delayTime : 2000,
      useShopIds :[],
      shopInfos : [],
      viewCheck : [],
      amountAll : 0,
      goodsAmountAll : 0,
      currentAmountAll : 0,
      toMonthCompensateAll : 0,
      lastMonthCompensateAll : 0,
      firstTwoMonthCompensateAll : 0,
      riskAmountAll : 0,
      walletAccountBalanceAll  : 0,
      totalBalanceAll  : 0,
      brokerageAmountAll : 0,
      viewOptions : {
        infoVeiw : true,
        fengxiangbiaoView : false,
        amountView : false,
        orderInfo : false,
        isOnline : false,
      },
      moneyRealTimeNumberTotal : 0,
      moneyYesterdayNumberTotal : 0,
      moneyMonthNumberTotal:0,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      orderRealTimeNumberTotal : 0,
      orderYesterdayNumberTotal : 0,
      orderMonthNumberTotal:0,

      userRealTimeNumberTotal : 0,
      userYesterdayNumberTotal : 0,
      userMonthNumberTotal:0,

      viewRealTimeNumberTotal : 0,
      viewYesterdayNumberTotal : 0,
      viewMonthNumberTotal:0,

      onSaleTotal : 0,
      forSaleTotal : 0,
      userinfo : getItem("userinfo"),
      shopNum : 0,
      byOrder : "0",
      descValue : "0",
      columns: [
        {
          title: '店铺名',
          dataIndex: 'shopName',
          key: 'shopName',
        },
        {
          title: '今日成交金额',
          dataIndex: 'MoneyToday',
          key: 'MoneyToday',
        },
        {
          title: '昨日成交金额',
          dataIndex: 'MoneyYesterday',
          key: 'MoneyYesterday',
        },
        {
          title: '今日成交单量',
          dataIndex: 'NumToday',
          key: 'NumToday',
        },
        {
          title: '昨日成交单量',
          dataIndex: 'NumYesterday',
          key: 'NumYesterday',
        },
        {
          title: '今日访客',
          dataIndex: 'PvToday',
          key: 'PvToday',
        },
        {
          title: '昨日访客',
          dataIndex: 'PvYesterday',
          key: 'PvYesterday',
        }
        ,
        {
          title: '今日成交转换率',
          dataIndex: 'RateToday',
          key: 'RateToday',
        }
        ,
        {
          title: '昨日成交转换率',
          dataIndex: 'RateYesterday',
          key: 'RateYesterday',
        }
        ,
        {
          title: '在售商品数',
          dataIndex: 'onSale',
          key: 'onSale',
        }
        ,
        {
          title: '待售商品数',
          dataIndex: 'forSale',
          key: 'forSale',
        },
        {
          title: '店铺资产',
          dataIndex: 'wallet',
          key: 'wallet',
        },
        {
          title: '操作',
          dataIndex: 'tool',
          key: 'tool',
        },
      ],
    }
  },
  setup(){
    return{editShopUser,editGorup,unbindShopId,updateShopInfo}
  },
  components:{
    fengxiangbiao : fengxiangbiao,
    sidebar : sidebar,
    amount : amount,
  },
  mounted(){
    shopGroup().then(res=>{
      if (res.success){
        this.shopGroup = res.data
        this.getShopIds(this.getInfo(true))
      }else{
        message.error("获取店铺分组失败,请刷新重试")
      }
    })
  },
  methods:{
    async copy (text){
      try {
        await toClipboard(text)
        message.success('复制成功')

      } catch (e) {
        message.success('复制失败:'+e)
      }
    },
    setYear(value){
      this.visible = true
      this.year = "读取中"
      this.spinning = true
      let time = setInterval(()=>{
        this.year = value
        clearInterval(time)
        this.spinning = false
      },2000)
    },
    openWindow(url){
      window.open(url)
    },
    getShopIds(onSuccess=()=>{}){
      ShopIds().then(res=>{
        if (res.success){
          this.shopIds = []
          res.data.forEach(shop=>{
            this.useShopIds.push({
              label : shop.shopName,
              value : shop.shopId,
            })
            this.shopIds.push({
              label : shop.shopName,
              value : shop.shopId,
            })
          })
          onSuccess()
        }else {
          message.error(res.msg)
        }
      })
    },
    CloseService(shopId,type=0){
      CloseService(shopId,type).then(res=>{
        if (res.success){
          if (res.data.closeServiceResult){
            message.success("指定服务已申请关闭")
          }else{
            message.error("指定服务已申请关闭失败")
          }
        }else {
          message.error(res.msg)
        }
      })
    },
    getInfo(loading=true){
      let shopIds=[]
      this.shopGroup.group.forEach(group=>{
        if(group.id == this.useShopGroupIds){
          group.data.forEach(shop=>{
            shopIds.push(shop.shopId)
          })
        }
      });
      info(shopIds,loading).then(res=>{
        if (res.success){
          this.shopInfos =[];
          this.shopInfos = res.data
          console.log(this.shopInfos)
          this.moneyYesterdayNumberTotal =  0
          this.moneyRealTimeNumberTotal =  0
          this.moneyMonthNumberTotal =  0
          this.orderYesterdayNumberTotal =  0
          this.orderRealTimeNumberTotal =  0
          this.orderMonthNumberTotal =  0
          this.userYesterdayNumberTotal =  0
          this.userRealTimeNumberTotal =  0
          this.userMonthNumberTotal =  0
          this.viewYesterdayNumberTotal =  0
          this.viewRealTimeNumberTotal =  0
          this.viewMonthNumberTotal =  0
          this.amountAll = 0
          this.goodsAmountAll  =  0
          this.currentAmountAll  =  0
          this.toMonthCompensateAll = 0
          this.lastMonthCompensateAll = 0
          this.firstTwoMonthCompensateAll = 0
          this.riskAmountAll = 0
          this.walletAccountBalanceAll  =  0
          this.totalBalanceAll  = 0
          this.brokerageAmountAll  =  0
          this.onSaleTotal = 0
          this.forSaleTotal = 0

          let time = new Date(new Date().toLocaleDateString()).getTime() /1000
          this.shopNum = 0
          this.shopInfos.forEach(shopInfo=>{
            //转换某些数据到json
            if (shopInfo.fengxiangInfo==null){
              shopInfo.fengxiangInfo =  fengxiangbiaoInfo
            }else{
              shopInfo.fengxiangInfo =  JSON.parse(shopInfo.fengxiangInfo)
            }

            if (shopInfo.summary_day==null){
              shopInfo.summary_day =  summaryDay
            }else{
              shopInfo.summary_day =  JSON.parse(shopInfo.summary_day)
            }
            if (shopInfo.schedule!=null){
              let schedule =[];
              JSON.parse(shopInfo.schedule).forEach(tab=>{
                if (tab.name!="营销"){
                  schedule.push(tab)

                }
              })
              shopInfo.schedule = schedule
            }else{
              shopInfo.schedule = []
            }

            if (shopInfo.time > time && shopInfo.status!=-1){
              this.shopNum++
            }

            this.goodsAmountAll  +=  parseFloat(shopInfo.goodsAmount)
            this.currentAmountAll  +=  parseFloat(shopInfo.currentAmount)
            this.toMonthCompensateAll +=  parseFloat(shopInfo.toMonthCompensate)
            this.lastMonthCompensateAll +=  parseFloat(shopInfo.lastMonthCompensate)
            this.firstTwoMonthCompensateAll +=  parseFloat(shopInfo.firstTwoMonthCompensate)
            this.riskAmountAll +=  parseFloat(shopInfo.riskAmount)
            this.walletAccountBalanceAll  +=  parseFloat(shopInfo.walletAccountBalance)
            this.totalBalanceAll  +=  parseFloat(shopInfo.totalBalance)
            this.brokerageAmountAll  +=  parseFloat(shopInfo.brokerageAmount )
            this.onSaleTotal += shopInfo.onSale
            this.forSaleTotal += shopInfo.forSale
            this.amountAll = parseFloat(this.amountAll) + parseFloat(shopInfo.totalBalance)+
                parseFloat(shopInfo.currentAmount) + parseFloat(shopInfo.walletAccountBalance)+
                parseFloat(shopInfo.goodsAmount) + parseFloat(shopInfo.riskAmount) -
                parseFloat(shopInfo.brokerageAmount)

            this.amountAll = this.amountAll.toFixed(2)
            this.moneyYesterdayNumberTotal += shopInfo.summary_day.shopDataModuleItemVOS[0].yesterdayNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[0].yesterdayNumber
            this.moneyRealTimeNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[0].realTimeNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[0].realTimeNumber
            this.moneyMonthNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[0].thisMonthNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[0].thisMonthNumber
            this.orderYesterdayNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[1].yesterdayNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[1].yesterdayNumber
            this.orderRealTimeNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[1].realTimeNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[1].realTimeNumber
            this.orderMonthNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[1].thisMonthNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[1].thisMonthNumber
            this.userYesterdayNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[3].yesterdayNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[3].yesterdayNumber
            this.userRealTimeNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[3].realTimeNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[3].realTimeNumber
            this.userMonthNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[3].thisMonthNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[3].thisMonthNumber

            this.viewYesterdayNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[4].yesterdayNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[4].yesterdayNumber
            this.viewRealTimeNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[4].realTimeNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[4].realTimeNumber
            this.viewMonthNumberTotal +=  shopInfo.summary_day.shopDataModuleItemVOS[4].thisMonthNumber==null?0:shopInfo.summary_day.shopDataModuleItemVOS[4].thisMonthNumber

          })
          console.log(this.shopInfos.length)
          this.shopInfos =  sortList(this.shopInfos,this.byOrder,this.descValue).length ===0?this.shopInfos:sortList(this.shopInfos,this.byOrder,this.descValue)
          console.log(this.shopInfos.length)
          this.moneyYesterdayNumberTotal = parseFloat(this.moneyYesterdayNumberTotal.toFixed(2))
          this.moneyRealTimeNumberTotal =  parseFloat(this.moneyRealTimeNumberTotal.toFixed(2))
          this.moneyMonthNumberTotal =  parseFloat(this.moneyMonthNumberTotal.toFixed(2))
          this.orderYesterdayNumberTotal = parseFloat(this.orderYesterdayNumberTotal.toFixed(2))
          this.orderRealTimeNumberTotal =  parseFloat(this.orderRealTimeNumberTotal.toFixed(2))
          this.orderMonthNumberTotal =  parseFloat(this.orderMonthNumberTotal.toFixed(2))
          this.userYesterdayNumberTotal = parseFloat(this.userYesterdayNumberTotal.toFixed(2))
          this.userRealTimeNumberTotal =  parseFloat(this.userRealTimeNumberTotal.toFixed(2))
          this.userMonthNumberTotal =  parseFloat(this.userMonthNumberTotal.toFixed(2))
          this.viewYesterdayNumberTotal = parseFloat(this.viewYesterdayNumberTotal.toFixed(2))
          this.viewRealTimeNumberTotal =  parseFloat(this.viewRealTimeNumberTotal.toFixed(2))
          this.viewMonthNumberTotal =  parseFloat(this.viewMonthNumberTotal.toFixed(2))
        }else{
          message.error("获取店铺信息失败")
        }
      }).catch(e=>{
        console.log(e)
      })
    },
    changeSort(){
      this.shopInfos = sortList(this.shopInfos,this.byOrder,this.descValue)
    },
    transformTime(timestamp) {
      let time = new Date(timestamp*1000);
      let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
      let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
      let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
      M = M < 10 ? "0"+ M : M;
      d = d < 10 ? "0"+ d : d;
      h = h < 10 ? "0"+ h : h;
      m = m < 10 ? "0"+ m : m;
      s = s < 10 ? "0"+ s : s;
      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    handUnbindShopId(shopId){
      unbindShopId(shopId).then(res=>{
        if (res.success){
          message.success("解绑成功")
          this.reload(false)
        }else{
          message.error("解绑失败,错误原因:"+res.msg)
        }
      })
    },
    updateShop(item){
      updateShopInfo(item.shopId).then(res=>{
        if(res.success){
          message.success("刷新成功")
          let shopInfo = res.data
          //转换某些数据到json
          if (shopInfo.fengxiangInfo==null){
            shopInfo.fengxiangInfo =  fengxiangbiaoInfo
          }else{
            shopInfo.fengxiangInfo =  JSON.parse(shopInfo.fengxiangInfo)
          }
          if (shopInfo.summary_day==null){
            shopInfo.summary_day =  summaryDay
          }else{
            shopInfo.summary_day =  JSON.parse(shopInfo.summary_day)
          }
          if (shopInfo.schedule!=null){
            let schedule =[];
            JSON.parse(shopInfo.schedule).forEach(tab=>{
              if (tab.name!="营销"){
                schedule.push(tab)
              }
            })
            shopInfo.schedule = schedule
          }else{
            shopInfo.schedule = []
          }
          item = shopInfo
        }else{
          message.error(res.msg)
        }
      })
    },
    exportCsv(){
      let data = '店铺名,店铺ID,商家id,店铺类目,在售商品,待售商品,今日成交金额,昨日成交金额,店铺资产,店铺保证金,待结算货款,待扣除佣金,钱包余额,营销钱包余额,风险保证金'+'\n'
      this.shopInfos.forEach(shopInfo=>{
        data += `${shopInfo.shopName},${shopInfo.shopId},${shopInfo.fengxiangInfo.venderId},${shopInfo.fengxiangInfo.scndCategoryName},${shopInfo.onSale},${shopInfo.forSale},${
          (shopInfo.summary_day.shopDataModuleItemVOS[0].realTimeNumber==null?shopInfo.summary_day.shopDataModuleItemVOS[0].realTimeNumber:0).toFixed(2)
        },${
          (shopInfo.summary_day.shopDataModuleItemVOS[0].yesterdayNumber?shopInfo.summary_day.shopDataModuleItemVOS[0].yesterdayNumber:0).toFixed(2)
        },`+
            `${(parseFloat(shopInfo.totalBalance) + parseFloat(shopInfo.currentAmount) +parseFloat(shopInfo.walletAccountBalance) +parseFloat(shopInfo.riskAmount) +parseFloat(shopInfo.goodsAmount) - parseFloat(shopInfo.brokerageAmount)).toFixed(2)},`+
            `${parseFloat(shopInfo.currentAmount)},${parseFloat(shopInfo.goodsAmount)},${parseFloat(shopInfo.brokerageAmount)},${shopInfo.walletAccountBalance},${shopInfo.totalBalance},${shopInfo.riskAmount}`+
            '\n'
      })

      let uri = 'data:text/csv;charset=utf-8,\ufeff' + encodeURIComponent(data);
      let downloadLink = document.createElement("a");
      downloadLink.href = uri;
      downloadLink.download = "店铺信息导出.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }
}
</script>

<style scoped>
  .bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height:46px;
    width:100%;
  }
</style>
