import request from "@/utils/request";

export const orders = (data) => {
    return request({
        url: 'jdziyingdian/order/list',
        method: 'POST',
        data: data
    })
}

export const getSkus = (data) => {
    return request({
        url: 'jdziyingdian/purchase_order/getSkus',
        method: 'POST',
        data: data
    })
}

export const delSku = (data) => {
    return request({
        url: 'jdziyingdian/purchase_order/delSku',
        method: 'POST',
        data: data
    })
}


export const saveRemark = (data) => {
    return request({
        url: 'jdziyingdian/order/updateRemark',
        method: 'POST',
        data: data
    })
}

export const getShops = (data) => {
    return request({
        url: 'jdziyingdian/order/getShops',
        method: 'POST',
        data: data
    })
}

export const chromeZYApp = {
    send : (data)=>{
        if (typeof(data)==='object'){
            window.postMessage(JSON.stringify(data))
        }else{
            window.postMessage(data)
        }
    },
    callback : (call)=>{
        window.addEventListener("message",(e)=>{
            if (e.origin === window.location.origin){
                call(e)
            }
        })
    }
}
