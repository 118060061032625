<script setup>
import {defineProps, ref} from "vue";
import {copy, transformMoney, transformTime} from "@/api/utils";
import {chromeZYApp, saveRemark} from "@/components/page/ziYinOrder/api";
import buyHistory from "@/components/page/ziYinOrder/components/buyHistory.vue";
import {message} from "ant-design-vue";

// eslint-disable-next-line no-unused-vars
const props = defineProps(
    {
      order: {
        type: Object,
        default: null,
        required: false
      }
    }
)

// const jdTime = (timestamp) =>{
//   let date = new Date(parseInt(timestamp));
//   return date.getTime()
// }

const jdTransformTime = (timestamp) =>{
  var e = new Date(parseInt(timestamp));
  return e.getFullYear() + "-" + (e.getMonth() + 1) + "-" + e.getDate() + " " + e.getHours() + ":" + e.getMinutes() + ":" + e.getSeconds()
}

const orderStatus = (status) =>{
  const statusList = [
    { label: '等待出库', value:7, color: "#5bc0de" },
    { label: '等待发货', value: 10, color: "#E6A23C" },
    { label: '等待确认发货', value:16, color: "#E6A23C" },
    { label: '完成', value: 19, color: "#67C23A" },
    { label: '删除', value: 20, color: "#909399" },
  ]
  for (let i = 0; i < statusList.length; i++) {
    if (statusList[i].value == status) {
      return statusList[i]
    }
  }
  return { label: '未知状态,' + status, value: status, color: "#909399" }
}

const jdImg = (src) =>{
  return 'https://img12.360buyimg.com/n6/'+src
}

const transformOrderType = (value) =>{
  if (value === 'taobao'||value === 'tmall'){
    return '淘宝'
  }else if (value === '1688'){
    return '1688'
  }else if(value === 'pdd'){
    return '拼多多'
  }
}

const transformOrderStatus = (value) =>{
  if (value === '0'){
    return '待付款'
  }else if (value === '1'){
    return '待发货'
  }else if (value === '2'){
    return '待收货'
  }else if (value === '3'){
    return '待评价'
  }else if (value === '4'){
    return '已取消'
  }
}

const refreshPurchaseOrder = (buyPlat,orderSn,jdOrderSn,jdSkuId,username) =>{
  chromeZYApp.send(JSON.stringify({
    type:'refreshPurchaseOrder',
    data:{
      buyPlat:buyPlat,
      orderSn: orderSn,
      jdOrderSn:jdOrderSn,
      jdSkuId: jdSkuId,
      username:username
    },
    success : false,
  }))
}


const handleRelated = (data) =>{
  chromeZYApp.send(JSON.stringify({
    type:'handleRelated',
    data:data,
    success : false,
  }))
}

const buyHistoryRef = ref(null)
const buyHistoryFunc = (jdOrderSn,jdSkuId,visible,thor) =>{
  buyHistoryRef.value.initData(jdOrderSn,jdSkuId,visible,thor)
}

const viewJdOrder =(jdOrderSn,thor) =>{
  chromeZYApp.send(JSON.stringify({
    type:'viewJdOrder',
    data:{
      thor:thor,
      jdOrderSn:jdOrderSn
    },
    success : false,
  }))
}

const jumpBuyPlat = (buyPlat,orderSn,username) =>{
  let url = null
  if (buyPlat === 'pdd'){
    // 拼多多
    url = 'https://mobile.yangkeduo.com/order.html?order_sn='+orderSn
  }else if (buyPlat === 'taobao' || buyPlat === 'tmall'){
    // 淘宝
    url = 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+orderSn
  }else if ( buyPlat === '1688'){
    // 1688
  }
  let data ={
      url:url,
      username:username,
      buyPlat:buyPlat
}
  chromeZYApp.send(JSON.stringify({
    type:'viewPurchaseOrder',
    data:data,
    success : false,
  }))
}

const refreshJdOrder = (jdOrderSn,thor) =>{
  chromeZYApp.send(JSON.stringify({
    type:'refreshJdOrder',
    data:{
      jdOrderSn:jdOrderSn,
      thor:thor
    },
    success : false,
  }))
}


const updateRemark = (order) =>{
  saveRemark({
    id:order.id,
    remark:order.remark,
    type:1
  }).then(res =>{
   if(res.success) {
      message.success(res.msg)
    }else{
      message.error(res.msg)
    }
  })
}

const jdVenderRemark = (order) =>{
  chromeZYApp.send(JSON.stringify({
    type:'jdVenderRemark',
    data:{
      id:order.id,
      orderId:order.orderId,
      thor:order.thor,
      remark:order.vendorRemark
    },
    success : false,
  }))
}

const comparseTime = (value,sec,type = 1) =>{
  let time = new Date().getTime() / 1000
  if (type === 1){
    return (value > (time - sec * 60 * 60))
  }else if (type === 2){
    return  (value - sec * 60 * 60) <= time
  }
}

const jdOrderDelivery = (order) =>{
  chromeZYApp.send(JSON.stringify({
    type:'jdOrderDeliver',
    data:{
     ...order
    },
    success : false,
  }))
}

const changeCarrier = (order) =>{
  let carrier = getCarrierResult(order.carrierId,order.carrierResult)
  // order.carrierCompany = carrier?carrier.carrierName:null
  console.log( carrier )
}

function getCarrierResult(carrierId,carriers){
  let value = null
  if (carriers){
    carriers.forEach((item) =>{
      if (item.carrierId === parseInt(carrierId)){
        value = item
      }
    })
  }
  return value
}

const decodeUnicode = (value) =>{
  value = value.replace(/\\/g, "%");
  // 转换中文
  value = unescape(value);
  // 将其他受影响的转换回原来
  value = value.replace(/%/g, "\\");
  // 对网址的链接进行处理
  value = value.replace(/\\/g, "");
  return value;
}

const linkTaobao = (name,username) =>{
  name =decodeUnicode(name).replaceAll('"',"")
  let url = `https://market.m.taobao.com/app/im/chat/index.html?&uid=cntaobao${name}&gid=&type=web#/`
  chromeZYApp.send(JSON.stringify({
    type:'linkTaobao',
    data:{
      name:name,
      thor:username,
      url:url
    },
    success : false,
  }))
}

const height = ref(200)

</script>

<template>
  <div>
    <div class="item_top" style="margin-bottom: 12px">
      <a-row>
        <a-col :span="16">
          <a-checkbox v-model:checked="order.select"></a-checkbox>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px"
             @click="viewJdOrder(order.orderId,order.thor)"
          >
            {{order.orderId}}&nbsp;&nbsp;
          </a>
          <a  @click="copy(''+order.orderId)"><copy-outlined /></a>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px">{{order.shopName}}</a>
          <a-divider type="vertical"></a-divider>
          <a-tooltip v-if="comparseTime(order.createTime,2)">
            <template #title>下单时间不足两小时</template>
            <a style="font-size: 14px;color: #E6A23C">
              <warning-outlined />
            </a>
          </a-tooltip>
          <a
              :style="{'font-size' : '14px',
              'color' : !(comparseTime(order.createTime,2))?'#409EFF':'#909399' }"
          >
            {{jdTransformTime(order.orderCreateDate)}}
          </a>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px;color:#303133">
           在线支付
          </a>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px;color:#303133">订单金额:
            {{
              transformMoney(order.totalPrice)
            }}元
          </a>
          <a-divider type="vertical"></a-divider>
          <a-tag style="margin-top: -12px" :color="orderStatus(order.orderState).color">
            {{
              orderStatus(order.orderState).label
            }}
          </a-tag>
          <a-divider type="vertical"></a-divider>
          <a-tooltip v-if="!(comparseTime(order.deliveryTime,0)) && order.orderState === 10">
            <template #title>
              {{
                !(comparseTime(order.deliveryTime,1,2)) ?'订单将在1小时内超时':
                    !(comparseTime(order.createTime,0)) ?'订单已超时':''
              }}
            </template>
            <a-tag
                v-if="!(comparseTime(order.deliveryTime,0))"
                :color="!(comparseTime(order.deliveryTime,0)) ?'#F56C6C':
                    !(comparseTime(order.deliveryTime,1,2)) ?'#E6A23C':''"
            >
              {{
                !(comparseTime(order.deliveryTime,0)) ?'发货超时':
                    !(comparseTime(order.deliveryTime,1,2)) ?'即将1小时内超时':''
              }}
            </a-tag>
          </a-tooltip>
        </a-col>
        <a-col :span="8" style="text-align: right">
          <a-button size="small" type="primary" @click="refreshJdOrder(order.orderId,order.thor)"><reload-outlined />刷新订单</a-button>
        </a-col>
      </a-row>
    </div>

    <div>
      <a-badge-ribbon text="Hippies" color="red">
        <a-row style="padding-top: 16px;" :gutter="[16,16]"  justify="start"  align="middle" >
          <a-col  :span="2" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku"  >
              <a-image style="width: 64px;height: 64px" :src="jdImg(item.imagePath)"></a-image>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku">
              <a style="color: #303133;">{{item.wareName}}</a>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku">
              <a style="color: #303133;">京东价:{{item.jdPrice}} 元</a>
              <br>
              <a style="color: #303133;">采购数 : {{item.wareNum}} 件</a>
            </div>
          </a-col>
          <a-col :span="2" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku">
             <div>
               <a-textarea
                   placeholder="拍单系统订单备注"
                   :auto-size="{ minRows: 3, maxRows: 3 }"
                   v-model:value="order.remark"
                   @blur="updateRemark(order)"
               ></a-textarea>
             </div>
              <div style="margin-top: 10px">
              <a-textarea
                  placeholder="商家备注"
                  v-model:value="order.vendorRemark"
                  @blur="jdVenderRemark(order)"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
              ></a-textarea>
                </div>
            </div>
          </a-col>
          <a-col :span="3">
            <div :style="{'height':height+'px','text-align':'center'}" v-for="(item) in order.orderDetailList" :key="item.sku">
              <div style="font-size: 12px;color: #909399">
                客户备注:{{!order.customRemark?'暂无备注':order.customRemark}}
              </div>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div  :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku">
              <a-button
                  size="small" type="primary" :danger="item.primary" class="purchase"
                  data-inject="0"
                  data-type="buyItem"
                  :data-data="JSON.stringify({
                    'order':order,
                    'sku':item
                  })">
                <shopping-cart-outlined />
                {{item.purchase_order !== null && item.purchase_order !== 1?'重新采购':'立即采购'}}
              </a-button>
              <br>
              <a-button style="margin-top: 12px" size="small"
                        @click="handleRelated({
                         'order':order,
                         'sku':item
                        })"
              >
                <share-alt-outlined />
                手动关联
              </a-button>
              <br>
              <a-button  style="margin-top: 12px" size="small" @click="buyHistoryFunc(order.orderId,item.sku,true,order.thor)"><fund-projection-screen-outlined />采购记录</a-button>
              <br>
              <a-popconfirm
                  title="确认是发货吗?"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="jdOrderDelivery(order)"
              >
                <a-button   style="margin-top: 12px"  size="small">
                  <DeliveredProcedureOutlined />发货
                </a-button>
              </a-popconfirm>

            </div>
          </a-col>
          <a-col :span="4" style="text-align: center">
            <div :style="{'height':height+'px'}">
              <div style="width: 100%">
                <a-select
                    style="width: 100%;"
                    :allowClear="true"
                    placeholder="请选择您要使用的快递"
                    v-model:value="order.carrierIds"
                    @change="changeCarrier(order)"
                >
                  <a-select-option v-for="carrier in order.carrierResult" :key="carrier.carrierId" :name="carrier.carrierId" :value="carrier.carrierId">
                    {{carrier.carrierName}}
                  </a-select-option>
                </a-select>
              </div>
              <div style="display: flex;flex-flow: row;align-items: center;margin-top: 10px">
                <a-input v-model:value="order.expressNo"  placeholder="多个运单号请用英文逗号隔开"></a-input>
              </div>
              <div style="display: flex;flex-flow: row;align-items: center;margin-top: 10px">
                <span style="margin-right: 5px;width: 25%">包裹数</span> <a-input v-model:value="order.packageNum" style="width: 70%"></a-input>
              </div>
              <div>
              </div>
            </div>

          </a-col>
          <a-col  :span="4" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderDetailList" :key="item.sku">
              <a v-if="order.purchase_order === 0"  style="color:#c3c3c5">暂无拍单数据</a>
              <div v-if="order.purchase_order === 1" style="width: 100%;height: 100%;text-align: left">

                <div v-if="item.purchase_order.orderCreateTime !== null " style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center"><span style="margin-right: 2px;">同步时间:</span><span>{{transformTime(item.purchase_order.updateTime)}}</span></div>
                <div v-if="item.purchase_order.buyerName !== null && item.purchase_order.buyerName !== ''" style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center"><span style="margin-right: 2px;">采购账号:</span><span>{{item.purchase_order.buyerName}}</span></div>

                <div>
                  <a-tooltip>
                    <template #title>点击跳转到订单详情页或者订单列表页</template>
                    <div>{{transformOrderType(item.purchase_order.buyPlat)}}:<a @click="jumpBuyPlat(item.purchase_order.buyPlat,item.purchase_order.orderSn,item.purchase_order.buyerName)">{{item.purchase_order.orderSn}}</a>  <a href="javascript:void(0)"  @click="copy(''+item.purchase_order.orderSn)"> <copy-outlined /></a></div>

                  </a-tooltip>
                </div>
                <div  style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center">
                  <span style="margin-right: 2px;">状态:</span>
                  <a href="#">{{transformOrderStatus(item.purchase_order.orderStatus)}}</a>
                  <a style="margin-left: 4px;" href="javascript:void(0)" @click="refreshPurchaseOrder(item.purchase_order.buyPlat,item.purchase_order.orderSn,item.purchase_order.jdOrderSn,item.purchase_order.jdSkuId,item.purchase_order.buyerName)"><reload-outlined /></a>
                </div>
                <div v-if="item.purchase_order.sellerName !== null " style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center">
                  <span style="margin-right: 2px;">卖家:</span>
                  <span>{{decodeUnicode(item.purchase_order.sellerName).replaceAll('"',"")}}</span>
                  <a href="javascript:void(0);" @click="linkTaobao(item.purchase_order.sellerName,item.purchase_order.buyerName)"  v-if="item.purchase_order.buyPlat === 'taotao' || item.purchase_order.buyPlat === 'tmall'" style="margin-left: 5px;"><img src="https://img.alicdn.com/tps/i4/T1uUG.XjtkXXcb2gzo-77-19.gif" /></a>
                </div>
                <div v-if="item.purchase_order.orderCreateTime !== null " style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center"><span style="margin-right: 2px;">采购时间:</span><span>{{item.purchase_order.orderCreateTime}}</span></div>

                <a-divider type="horizontal" style="margin: 5px 0"></a-divider>
                <div  style="color: red;display: flex;flex-flow: row;justify-content: flex-start">
                  <span style="margin-right: 2px;display: inline-block">收货信息:</span>
                  <div style="line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    display: -moz-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-all;width:70%">{{item.purchase_order.address}}</div>
                </div>
                <a-divider type="horizontal" style="margin: 5px 0"></a-divider>
                <div v-if="item.purchase_order.express" >
                  <span style="width:100%">物流公司:{{item.purchase_order.express.expressName}}</span>
                  <div style="width:100%">运单号:{{item.purchase_order.express.expressId}}</div>
                </div>
              </div>
            </div>
          </a-col>

        </a-row>
        <a-row>

          <a-col :span="24" style="text-align: left;padding-left: 28px">
            {{order.groupString}}
          </a-col>
        </a-row>
      </a-badge-ribbon>
    </div>
    <a-card style="margin-top: 14px;margin-bottom: 12px">
      <div>
        <a-descriptions :column="6" size="small">



          <a-descriptions-item :labelStyle="{'color':'#606266','font-size': '14px'}"
                               :contentStyle="{'color':'#606266','font-size': '14px'}"
                               label="下单账号">
            {{order.pin}}
          </a-descriptions-item>
          <a-descriptions-item v-if="order.shipNo" :labelStyle="{'color':'#606266','font-size': '14px'}"
                               :contentStyle="{'color':'#606266','font-size': '14px'}"
                               label="快递信息">
            {{order.carrierCompany}}：{{order.shipNo}}
          </a-descriptions-item>
          <a-descriptions-item :labelStyle="{'color':'#606266','font-size': '14px'}"
                               :contentStyle="{'color':'#606266','font-size': '14px'}"
                               label="收 货 人">{{order.address}}
            &nbsp;&nbsp;&nbsp;{{order.customerName}}&nbsp;&nbsp;&nbsp;{{order.phone}}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-card>

    <buyHistory ref="buyHistoryRef"></buyHistory>
  </div>
</template>

<style scoped>

</style>