import { createVNode, render } from 'vue'
import editShopUserWindow from './editShopUser'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)




// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}



const editShopUser = {
    open:(shopInfo)=>{
        const vnode = createVNode(editShopUserWindow, { shopInfo:shopInfo,onClose : render(null, div)})
        renderMessage(vnode)
    },
}

export default editShopUser
