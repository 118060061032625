<template>
  <a-card>

  </a-card>
</template>

<script>
import {get} from "@/api/store"
import { message } from 'ant-design-vue'
import {getCode } from "./api/item";
import {reactive, ref} from "vue";
import {getItem as storageGet} from "@/utils/storage";

export default {
  name: "legal",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)==false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      console.log(useShopIds.value)
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
    this.getCode()
  },
  methods:{
    getCode(page,pageSize){
     getCode(page,pageSize).then(res=>{
       console.log(res)
     })
    }
  },
  watch:{

  }
}
</script>

<style>
.bottom{
  position: fixed;
  bottom:0px;
  left:0px;
  height:72px;
  width:100%;
}


</style>
