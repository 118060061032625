

const menus = [
    {
        name : "用户主页",
        icon : "",
        key : 1,
        route : "home",
        disabled : false,
        children : [],
    },
    {
        name : "商品管理",
        icon : "bar-chart-outlined",
        key : 2,
        route : "order",
        disabled : false,
        children : [
            {
                name : "商品审核",
                icon : "bar-chart-outlined",
                key : 1,
                route : null,
                disabled : false,
            }
        ],
    },
]




export default menus;
