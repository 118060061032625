<template>
  <a-modal v-model:visible="visible" width="40%" title="订单采购记录" :footer="null" :maskClosable="false" :destroyOnClose="true">
    <a-row style="margin-top: -16px" :gutter="20"  justify="start"  align="middle">
      <a-col :span="8">
        <a-card hoverable>
          <template #cover>
            <a-row>
              <a-col :span="12">
                <a-image style="text-align: right" alt="example" :src="item.orderItems.skuimage" />
              </a-col>
              <a-col :span="12" style="text-align: right;margin-right: -16px">
                <a-button @click="refresh(order)" size="small" style="margin-top: 0px;">
                  <template #icon><reload-outlined /></template>
                </a-button>
              </a-col>
            </a-row>
          </template>
          <a-card-meta :title="order.shopInfo.shopName">
            <template #description>
              <div style="text-align: right">
                <a-typography-text
                    style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                    :ellipsis="{ tooltip: item.orderItems.skuName,symbol: item.orderItems.skuName,expandable:true}"
                    :content="item.orderItems.skuName"/><br>
                <a-typography-text
                    style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                    :ellipsis="{ tooltip: item.orderItems.color,symbol: item.orderItems.skuName,expandable:true}"
                    :content="item.orderItems.color"/><br>
                <span style="font-size: 12px">{{item.orderItems.jdPrice}} 元</span><br>
                <span :style="{'color' :orderStatus(order.orderStatus).color,fontSize:'12px' }">{{orderStatus(order.orderStatus).label}}</span><br>
              </div>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { defineComponent} from 'vue'
import { Modal ,Button,Card,CardMeta,Image,Row,Col,TypographyText} from 'ant-design-vue'
import store from '@/store/index'
import {ReloadOutlined} from '@ant-design/icons-vue';
import {History} from '@/api/order'
export default defineComponent({
  name : "buyHistoryWindow",
  components:{
    aModal : Modal,
    aButton : Button,
    aCard : Card,
    aCardMeta : CardMeta,
    aImage : Image,
    aRow : Row,
    aCol:Col,
    reloadOutlined : ReloadOutlined,
    aTypographyText : TypographyText,
  },
  props: {
    order: {},
    item: {},
  },
  data(){
    return{
      visible :true,
      time : null,
      buyUser : null,
      buyOrderType : "-1",
      seleBuyUser : {
        duoduoId : "",
      },
    }
  },
  setup() {
    const vuex = store
    return {
      vuex
    }
  },
  mounted() {
    History(this.item.skuId).then(res=>{
      if (res.success){
        console.log(res.data)
      }
    })
  },
  methods:{
    orderStatus(status){
      const statusList =  [
        { label: '等待出库', value: '8' ,color : "#409EFF" },
        { label: '等待收货', value: '16',color : "#E6A23C" },
        { label: '完成订单', value: '19',color : "#67C23A" },
        { label: '已取消', value: '-1'  ,color : "#F56C6C"},
      ]
      for (let i = 0 ; i < statusList.length;i++){
        if (statusList[i].value == status){
          return statusList[i]
        }
      }
      return { label: '未知状态,'+status, value: status  ,color : "#909399"}
    },
    cancel(){

    },
  }
})
</script>
