<script setup>
  import {ref} from "vue";
  import { createCard } from './api'
  import { Modal } from 'ant-design-vue'
  const type = ref("1")
  const num = ref(1000)
  const day = ref(30)
  const limit = ref(5000)
  const cardList = ref("")
  const useCreateCard = ()=>{
    createCard(type.value,num.value,day.value,limit.value).then(res=>{
      if (res.success){
        cardList.value = ""
        res.data.forEach(card=>{
          cardList.value += card + "\n"
        })
      }else{
        Modal.error({
          title: '生成卡密失败',
          content: res.msg,
        });
      }
    }).cache(e=>{
      console.log(e)
    })
  }
</script>

<template>

  <a-row :gutter="[20]">
    <a-col :span="1"></a-col>
    <a-col :span="4">
      卡密类型:
      <a-select v-model:value="type">
        <a-select-option value="1">店铺上货</a-select-option>
        <a-select-option value="2">智能客服</a-select-option>
        <a-select-option value="3">卡仓出库</a-select-option>
        <a-select-option value="4">品牌管理</a-select-option>
      </a-select>
    </a-col>
    <a-col :span="4">
      <a-input v-model:value="num">
        <template #addonBefore>生成数量</template>
      </a-input>
    </a-col>
    <a-col :span="4">
      <a-input v-model:value="day">
        <template #addonBefore>有效期限</template>
      </a-input>
    </a-col>
    <a-col :span="4">
      <a-input v-model:value="limit">
        <template #addonBefore>上货数量</template>
      </a-input>
    </a-col>
    <a-col :span="4" style="text-align: center">
      <a-button @click="useCreateCard">开始生成</a-button>
    </a-col>
  </a-row>
  <a-divider></a-divider>
  <a-textarea placeholder="生成的卡密" v-model:value="cardList" style="width: 90%"  :auto-size="{ minRows: 15, maxRows: 15 }">

  </a-textarea>
</template>

<style scoped>

</style>
