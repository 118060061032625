import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const recover = (shopIds,beginTime,endTime)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/recycleBin/recover`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "beginTime" : beginTime,
                    "endTime" : endTime
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}




