<template>
  <div class="orderList">
    <a-row>
      <a-col :span="24">
        <a-space direction="vertical" :size="24">
          <div
              v-for="order in orderList"
              :key="order.id"
              style="width: 100%">
            <a-card
                hoverable
                size="small"
                style="width: 100%;background-image: linear-gradient(4deg,white 55.17%,#eef3f9 95.21%);"
            >
              <itemCard :order="order"></itemCard>
            </a-card>
          </div>

        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script setup>
import { defineProps, defineComponent,onMounted } from 'vue'
import itemCard  from './components/itemCardNew.vue'
defineComponent({
  itemCard : itemCard
})
// eslint-disable-next-line no-unused-vars
const props = defineProps({
  orderList: {
    type: Array,
    default: () => []
  },
})

onMounted(()=>{
  // top.addEventListener('message',(e)=>{
  //   console.log(e)
  // },false)
})


</script>
