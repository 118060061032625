<template>
  <a-modal v-model:visible="visible" width="40%" title="手动关联采购订单" :footer="null" :maskClosable="false" :destroyOnClose="true" @cancel="cancel">
    <a-row style="margin-top: -16px" :gutter="20"  justify="start"  align="middle">
      <a-col :span="8">
        <a-card hoverable>
          <template #cover>
            <a-row>
              <a-col :span="12">
                <a-image style="text-align: right" alt="example" :src="item.orderItems.skuimage" />
              </a-col>
              <a-col :span="12" style="text-align: right;margin-right: -16px">
                <a-button @click="refresh(order)" size="small" style="margin-top: 0;">
                  <template #icon><reload-outlined /></template>
                </a-button>
              </a-col>
            </a-row>
          </template>
          <a-card-meta :title="order.shopInfo.shopName">
            <template #description>
              <div style="text-align: right">
                <a-typography-text
                    style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                    :ellipsis="{ tooltip: item.orderItems.skuName,symbol: item.orderItems.skuName,expandable:true}"
                    :content="item.orderItems.skuName"/><br>
                <a-typography-text
                    style="font-size: 12px;color: rgba(0, 0, 0, 0.45)"
                    :ellipsis="{ tooltip: item.orderItems.color,symbol: item.orderItems.skuName,expandable:true}"
                    :content="item.orderItems.color"/><br>
                <span style="font-size: 12px">{{item.orderItems.jdPrice}} 元</span><br>
                <span :style="{'color' :orderStatus(order.orderStatus).color,fontSize:'12px' }">{{orderStatus(order.orderStatus).label}}</span><br>
              </div>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
      <a-col :span="16">
        <a-row :gutter="20">
          <a-col :span="9">
            <a-select
                placeholder="采购平台"
                style="width: 98%"
                @change="changeSelect()"
                v-model:value="buyOrderType"
            >
              <a-select-option value="0" key="0">拼多多</a-select-option>
              <a-select-option value="1" key="1">淘宝&天猫</a-select-option>
            </a-select>
          </a-col>
          <a-col :span="15">
            <a-input v-model:value="seleBuyUser.nickname" placeholder="采购账号"></a-input>
          </a-col>
        </a-row>
        <br>
        <a-input placeholder="采购订单号"></a-input>
        <br><br>
        <div style="text-align: center">
          <a-button id="bindBuyOrder" class="bindBuyOrder" color="#E6A23C" type="primary" ><share-alt-outlined />关联订单</a-button>
        </div>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { defineComponent ,h  } from 'vue'
import { Modal ,Button,Card,CardMeta,Image,Row,Col,TypographyText,message,Select,Input,SelectOption } from 'ant-design-vue'
import store from '@/store/index'
import {ReloadOutlined,ShareAltOutlined} from '@ant-design/icons-vue';
import {refreshOrder} from "@/api/order"
import {getItem} from "@/utils/session"
export default defineComponent({
  name : "bindWindow",
  components:{
    aModal : Modal,
    aButton : Button,
    aCard : Card,
    aCardMeta : CardMeta,
    aImage : Image,
    aRow : Row,
    aCol:Col,
    aSelect : Select,
    reloadOutlined : ReloadOutlined,
    aTypographyText : TypographyText,
    aInput :Input,
    ShareAltOutlined : ShareAltOutlined,
    aSelectOption : SelectOption,
  },
  props: {
    order: {},
    item: {},
  },
  data(){
    return{
      visible :false,
      time : null,
      buyUser : null,
      buyOrderType : "-1",
      seleBuyUser : {
        duoduoId : "",
      },
    }
  },
  setup() {
    const mystore = store
    return {
      mystore
    }
  },
  mounted() {
    if (!this.mystore.state.isInstall){
      Modal.error({title: "未安装获芸订单管理插件,请安装之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "./Chrome.zip",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})
      return
    }
    if (!this.mystore.state.isGetOrder){
      Modal.error({title: "未运行获芸订单同步组件客户端,请启动之后再使用本功能",content: h('div', {}, [
          h('a',{
            "style" : {"color":"red"},
            "href" : "https://huoyuntest.oss-cn-hangzhou.aliyuncs.com/huoyunOrderSysteam.exe",
            "target" : "_blank",
          } ,'点击此处下载'),
        ]),})

      return
    }
    this.visible = true
    this.time = setInterval(()=>{
      this.buyUser = getItem("buyUser")
      if (this.buyUser != null && this.buyUser != undefined) {
          if (this.buyUser.pinduoduo.duoduoId != null){
            this.buyOrderType = "0"
            this.seleBuyUser = this.buyUser.pinduoduo
            clearInterval(this.time)
          }
        if (this.buyUser.taobao.duoduoId != null){
          this.buyOrderType = "1"
          this.seleBuyUser = this.buyUser.taobao
          clearInterval(this.time)
        }
      }
    },100)
  },
  methods:{
    orderStatus(status){
      const statusList =  [
        { label: '等待出库', value: '8' ,color : "#409EFF" },
        { label: '等待收货', value: '16',color : "#E6A23C" },
        { label: '完成订单', value: '19',color : "#67C23A" },
        { label: '已取消', value: '-1'  ,color : "#F56C6C"},
      ]
      for (let i = 0 ; i < statusList.length;i++){
        if (statusList[i].value == status){
          return statusList[i]
        }
      }
      return { label: '未知状态,'+status, value: status  ,color : "#909399"}
    },
    cancel(){
      clearInterval(this.time)
      clearInterval(this.time2)

    },
    useMiaoshouBuyUrls(){
      let buyUrls = getItem("buyUrls")
      if (buyUrls.purchaseWareUrls.length < 1) {
        message.info("妙手链接获取结果为空")
      }else{
        buyUrls.purchaseWareUrls.forEach((url,index)=>{
          this.buyInfo.push({remark : '妙手采购链接'+(index+1), url : url.purchaseUrl })
        })
        message.success("妙手订单链接同步成功")
        this.changeBuyUrl()
      }
    },
    refresh(order){
      refreshOrder(order).then(res=>{
        if (res.success){
          console.log(res.data)
          res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo);
          console.log(res.data.buyInfo)
          res.data.buyInfo = JSON.parse(res.data.buyInfo);
          res.data.shopInfo = JSON.parse(res.data.shopInfo);
          res.data.shopOnline = 0;
          res.data.orderItems.forEach(item=>{
            item.orderItems = JSON.parse(item.orderItems)
          })
          res.data.checkBoxSelect = order.checkBoxSelect;
          // eslint-disable-next-line vue/no-mutating-props
          order = res.data
          message.success("更新订单状态成功")
        }else{
          message.error(res.msg)
        }
      })
    },
    changeSelect(){
      this.buyUser = getItem("buyUser")
      if (this.buyUser != null && this.buyUser != undefined) {
        if ( this.buyOrderType == "0"){
          this.seleBuyUser = this.buyUser.pinduoduo
        }
        if ( this.buyOrderType == "1"){
          this.seleBuyUser = this.buyUser.taobao
        }
      }
      console.log(this.buyOrderType)
    }
  }
})
</script>
