import { createVNode, render } from 'vue'
import { browserWindow } from '@/components/server/browserWindow/browserWindow'

const div = document.createElement('div')
// 添加到body上
document.body.appendChild(div)




// 渲染虚拟dom
const renderMessage=(vnode)=>{
    render(vnode, div)
}



export const browser = {
    open:(title,url)=>{
        const vnode = createVNode(browserWindow, { title:title, url:url,onClose : render(null, div)})
        renderMessage(vnode)
    },
}
