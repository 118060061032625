import request from "@/utils/request";


export const createCard = (type,num,day,limit)=>{
    return request({
        url: `/shop_manage/upload/createCard`,
        method: 'POST',
        loading : true,
        data : {
            type,
            num,
            day,
            limit
        }
    })
}


