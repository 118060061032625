<template>
  <div style="margin-top: 64px;height: 100%">
    <a-row :gutter="12">
      <a-col :span="$store.state.isLogin?3:0">
        <menuHTML style="width: 100%;height: 98%;left: 0"></menuHTML>
      </a-col>
      <a-col :span="$store.state.isLogin?21:24" style="z-index: 102">
        <userInfo></userInfo>
        <downLoad></downLoad>
        <floatWindow></floatWindow>
        <router-view></router-view>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import floatWindow from "@/components/utils/floatWindow";
import menuHTML from "@/components/utils/leftMenu";
import userInfo from "@/components/utils/userInfo";
import downLoad from "@/components/utils/downLoad";
import { useStore } from 'vuex'

export default {
  name: 'order',
  components:{
    menuHTML,
    userInfo,
    downLoad,
    floatWindow
  },
  setup(){
    const store = useStore()  // 该方法用于返回store 实例
    return{
      store
    }
  }
}
</script>

