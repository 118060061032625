<template>
  <a-modal v-model:visible="visible" width="40%" title="订单出库" :footer="null" :maskClosable="false" :destroyOnClose="true" @cancel="cancel">
    <a-row :gutter="20">
      <a-col :span="24">
        <a-list item-layout="horizontal" :data-source="order.orderItems">
          <template #renderItem="{ item }">
            <a-list-item>
              <a-list-item-meta>
                <template #description>
                  <div style="margin-top: -6px">
                    <span style="font-size: 10px">{{item.skuId}}</span><br>
                    <span style="font-size: 10px">{{item.duoduoId==null?'订单未采购':'已采购:'+item.order_sn}}</span>
                  </div>
                </template>
                <template #title>
                  <h4 style="margin-top: 4px">{{ item.orderItems.skuName }}</h4>
                </template>
                <template #avatar>
                  <a-avatar style="margin-top: 12px" :src="item.orderItems.skuimage" shape="square" :size="64" />
                </template>
              </a-list-item-meta>
            </a-list-item>
          </template>
        </a-list>
        <a-divider />
        <a-empty v-if="sendAddressList.length < 1">
          <template #description>
            <span>
              没有获取到发货地址,请检查店铺是否掉线
              <a target="_blank" :href="'https://order.cklllll.com/extension.html?type=shopManage&shopId='+order.shopInfo.shopId">查看店铺后台</a>
            </span>
          </template>
        </a-empty>
        <a-radio-group v-if="sendAddressList.length > 0" @change="changeSelect" size="small" v-model:value="sendAddressId">
          <div style="margin-left:16px ">
            <a-radio  size="small" :style="radioStyle" v-for="sendAddress in sendAddressList" :key="sendAddress.id" :value="sendAddress.id">
              {{sendAddress.contact}}&nbsp;{{sendAddress.displayAddress}}&nbsp;{{sendAddress.addressDetail}}
            </a-radio>
          </div>
        </a-radio-group><br>
        <div style="text-align: center;margin-top: 18px">
          <a-button size="small" @click="openWindow('https://order.cklllll.com/extension.html?type=shopAddress&shopId='+order.shopInfo.shopId)"><plus-outlined />添加发货地址</a-button>
        </div>
        <a-divider />
        <a-row :gutter="20">
          <a-col :span="6">
            <a-select
                size="small"
                show-search
                :filter-option="filterOption"
                placeholder="选择您发货的快递"
                @change="changeSelect"
                v-model:value="select"
                style="width: 100%"
            >
              <a-select-option v-for="express in expressList" :key="express.id" :name="express.name" :value="express.id">
                {{express.name}}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="14">
            <a-input
                size="small"
                placeholder="请输入你要发出的快递单号"
                v-model:value="expressNo"
                style="width: 100%"
            >
            </a-input>
          </a-col>
          <a-col :span="4" style="text-align: left">
            <a-button @click="sendOutDialog=true" size="small" type="primary" style="width: 100%;">
              <send-outlined />
              确认发货
            </a-button>
            <a-modal v-model:visible="sendOutDialog" title="请确定是否出库发货?" @ok="sendOut">
              <p>请您确认是否要进行对应的发货出库操作</p>
            </a-modal>
          </a-col>
        </a-row>
        <br>
        <a-button @click="test" size="small" type="primary" style="width: 100%;">
          <send-outlined />
          一键智能出库
        </a-button>
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import { defineComponent,reactive } from 'vue'
import {
  Modal,
  Row,
  Col,
  List,
  ListItem,
  ListItemMeta,
  Avatar,
  Divider,
  Select,
  SelectOption,
  Input,
  Button,
  Radio,
  RadioGroup,
  message,
  Empty,
} from 'ant-design-vue'
import { SendOutlined,PlusOutlined } from '@ant-design/icons-vue';
import store from '@/store/index'
import {stockOut, stockOutTest, venderTakeAddress} from "@/api/shop";

export default defineComponent({
  name : "outWindow",
  components:{
    aModal : Modal,
    aRow : Row,
    aCol : Col,
    aList : List,
    aListItem :ListItem,
    aListItemMeta:ListItemMeta,
    aAvatar :Avatar,
    aDivider : Divider,
    aSelect : Select,
    aSelectOption : SelectOption,
    aInput : Input,
    aButton : Button,
    sendOutlined : SendOutlined,
    aRadio : Radio,
    aRadioGroup : RadioGroup,
    PlusOutlined:PlusOutlined,
    aEmpty : Empty,
  },
  props: {
    order: {},
    onClose : Function
  },
  data(){
    return{
      visible :true,
      expressList : [
        {
          "id": 2087,
          "name": "京东快递"
        },
        {
          "id": 1999327,
          "name": "达达同城快送"
        },
        {
          "id": 1819479,
          "name": "丰网速运"
        },
        {
          "id": 1946677,
          "name": "中欧物流"
        },
        {
          "id": 1946679,
          "name": "中欧速运"
        },
        {
          "id": 1988647,
          "name": "快弟来了"
        },
        {
          "id": 463,
          "name": "圆通快递"
        },
        {
          "id": 465,
          "name": "邮政EMS"
        },
        {
          "id": 467,
          "name": "顺丰快递"
        },
        {
          "id": 471,
          "name": "龙邦快递"
        },
        {
          "id": 1327,
          "name": "韵达快递"
        },
        {
          "id": 1409,
          "name": "宅急送"
        },
        {
          "id": 1499,
          "name": "中通速递"
        },
        {
          "id": 1549,
          "name": "宅急便"
        },
        {
          "id": 1747,
          "name": "优速"
        },
        {
          "id": 2094,
          "name": "快捷速递"
        },
        {
          "id": 2096,
          "name": "联邦快递"
        },
        {
          "id": 2105,
          "name": "速尔快递"
        },
        {
          "id": 2130,
          "name": "德邦物流"
        },
        {
          "id": 2170,
          "name": "邮政快递包裹"
        },
        {
          "id": 2171,
          "name": "中国邮政挂号信"
        },
        {
          "id": 2462,
          "name": "天地华宇"
        },
        {
          "id": 3046,
          "name": "德邦快递"
        },
        {
          "id": 4832,
          "name": "安能物流"
        },
        {
          "id": 5419,
          "name": "中铁物流"
        },
        {
          "id": 6012,
          "name": "斑马物联网"
        },
        {
          "id": 171686,
          "name": "易宅配物流"
        },
        {
          "id": 222693,
          "name": "贝业新兄弟"
        },
        {
          "id": 323141,
          "name": "亚风快运"
        },
        {
          "id": 332098,
          "name": "用户自提"
        },
        {
          "id": 336878,
          "name": "京东大件物流"
        },
        {
          "id": 568096,
          "name": "万家康"
        },
        {
          "id": 599866,
          "name": "跨越速运"
        },
        {
          "id": 247899,
          "name": "安得物流"
        },
        {
          "id": 605050,
          "name": "中铁CRE"
        },
        {
          "id": 596494,
          "name": "安能快递"
        },
        {
          "id": 617027,
          "name": "POP国际物流"
        },
        {
          "id": 680414,
          "name": "中通快运"
        },
        {
          "id": 688636,
          "name": "增速益"
        },
        {
          "id": 689816,
          "name": "集成"
        },
        {
          "id": 692584,
          "name": "品骏快递"
        },
        {
          "id": 724080,
          "name": "珠海广丰"
        },
        {
          "id": 3668,
          "name": "邮政EMS标准快递"
        },
        {
          "id": 732960,
          "name": "EWE智慧物流"
        },
        {
          "id": 731302,
          "name": "韵达快运"
        },
        {
          "id": 740588,
          "name": "久耶供应链"
        },
        {
          "id": 773574,
          "name": "京东快运"
        },
        {
          "id": 831932,
          "name": "京东同城速配"
        },
        {
          "id": 832230,
          "name": "顺心捷达"
        },
        {
          "id": 881232,
          "name": "众邮快递"
        },
        {
          "id": 668392,
          "name": "RoyalMail"
        },
        {
          "id": 1325880,
          "name": "壹米滴答"
        },
        {
          "id": 1362538,
          "name": "联通码上购"
        },
        {
          "id": 1369364,
          "name": "麦哲伦跨境物流"
        },
        {
          "id": 256122,
          "name": "UPS"
        },
        {
          "id": 256059,
          "name": "DHL"
        },
        {
          "id": 1512990,
          "name": "USPS"
        },
        {
          "id": 1613410,
          "name": "京东医药"
        },
        {
          "id": 1665004,
          "name": "云仓速递"
        }
      ],
      select : null,
      expressNo : "",
      sendAddress : null,
      sendAddressList : [],
      sendAddressId : null,
      sendOutDialog : false,
    }
  },
  setup() {
    const filterOption = (input, option) => {
      console.log(option)
      return option.name.indexOf(input) >= 0;
    };
    const radioStyle = reactive({
      display: 'flex',
      height: '30px',
      lineHeight: '30px',
    });
    return {
      store,filterOption,radioStyle
    }
  },
  mounted() {
    let jt = false
    this.order.orderItems.forEach(item=>{
      if (item.order_sn!=null && item.order_sn!=""){
        this.expressNo = item.expressNo
        this.expressList.forEach(express=>{
          if (express.name.indexOf(item.expressName.replace("快递","").replace("速递","").replace("包裹",""))>-1){
            this.select = express.id
          }
          if (item.expressName.indexOf('极兔') >-1 || item.expressName.indexOf('申通')>-1){
            this.select = 336878
            this.expressNo = "JD"+new Date().getTime()+"12"
            jt = true
          }
        })
      }
    })
    if (jt){
      message.info("上家单号为极兔或申通,已自动转换成京东大件物流")
    }
    if (this.select==null){
      this.select = this.expressList[0].id
    }
    venderTakeAddress(this.order.shopId).then(res=>{
      if (res.success){
        this.sendAddressList = res.data
        if (this.sendAddressList.length >0){
          this.sendAddressId = res.data[0].id
        }

      }
    })
  },
  methods:{
    changeSelect(){
      console.log(this.sendAddressId)
      console.log(this.select)
    },
    cancel(){
      this.visible = false

    },
    openWindow(url){
      window.open(url)
    },
    sendOut(){
      for (let i = 0 ; i < this.order.orderItems.length;i++){
        if (this.order.orderItems[i].order_sn == null || this.order.orderItems[i].order_sn == "") {
          this.sendOutDialog = false
          Modal.confirm({
            title: '再次确认',
            content: '订单里面有未采购商品,请确认是否进行出库操作?',
            onOk(){
              stockOut({
                "shopId" : this.order.shopId,
                "order" : this.order,
                "venderTaskAddressId" : this.sendAddressId,
                "deliveryId" : this.select,
                "deliveryNums" :this.expressNo
              }).then(res=>{
                if (res.success){
                  message.success("出库成功")
                }else{
                  message.error("出库失败:"+res.msg)
                }
              })
            }
          })
          return
        }
      }
      stockOut({
        "shopId" : this.order.shopId,
        "order" : this.order,
        "venderTaskAddressId" : this.sendAddressId,
        "deliveryId" : this.select,
        "deliveryNums" :this.expressNo
      }).then(res=>{
        if (res.success){
          this.sendOutDialog = false
          message.success("出库成功")
        }else{
          message.error("出库失败:"+res.msg)
        }
      })
    },
    test(){
      stockOutTest({
        "order" : this.order,
      }).then(res=>{
        if (res.success){
          this.sendOutDialog = false
          message.success("出库成功")
        }else{
          message.error("出库失败:"+res.msg)
        }
      })
    }
  }
})
</script>
