import request from "@/utils/request";
export const useCards = (shopIds,card,type=3)=>{
    return request({
        url: `/shop_manage/upload/useCards`,
        method: 'POST',
        loading : true,
        data : {
            shopIds,
            card,
            type
        }
    })
}
