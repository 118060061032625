<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择你要报备的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="请选择您要使用的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});console.log(useShopIds)" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>
    <div style="text-align: center">
      <div style="text-align: left;margin-left: 16px;margin-bottom: 16px">
        <a-input v-model:value="imageUrl" placeholder="请输入图片链接"  style="width: 80%"></a-input><a-divider  type="vertical"></a-divider>
        <a-button @click="addImage" type="primary"><plus-outlined />增加报备图片</a-button>
      </div>
      <div v-if="images.length > 0" style="text-align: left;margin-left: 16px;margin-bottom: 16px;margin-top: 32px">
        <a-badge v-for="(image,index) in images" :key="index"  title="Custom hover text" style="margin-left: 32px">
          <template #count >
            <a-button @click="images.splice(index,1)" danger size="small" type="primary" shape="circle">
              <template #icon><close-outlined /></template>
            </a-button>
          </template>
          <a-image style="width: 100px;height: 100px" :src="image"></a-image>
        </a-badge>
      </div>
      <a-textarea
          v-model:value="words"
          style="width: 98%;"
          placeholder="请在此处输入报备理由"
          :auto-size="{ minRows: 5, maxRows: 5 }"
      />
    </div>
  </div>
  <br><br><br><br><br><br><br><br>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="z-index:10001">
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="13" style="text-align: left">
              <a-radio-group v-model:value="report">
                <a-radio-button value="delayDeliveryReport">延迟发货报备</a-radio-button>
                <a-radio-button value="delaySaleAfter">延迟售后报备</a-radio-button>
              </a-radio-group>
              <a-divider  type="vertical"></a-divider>
              <a-select
                  v-if="report=='delaySaleAfter'"
                  v-model:value="delayDeliveryReportReportCause"
                  style="width: 256px;"
                  :allowClear="true"
                  placeholder="请选择报备理由"
              >
                <a-select-option v-for="reportCauses in delaySaleAfterReportCauses" :key="reportCauses.code" :name="reportCauses.reason" :value="reportCauses.code">
                  {{reportCauses.reason}}
                </a-select-option>
              </a-select>
              <a-select
                  v-if="report=='delayDeliveryReport'"
                  v-model:value="delayDeliveryReportReportCause"
                  style="width: 256px;"
                  :allowClear="true"
                  placeholder="请选择报备理由"
              >
                <a-select-option v-for="reportCauses in delayDeliveryReportReportCauses" :key="reportCauses.code" :name="reportCauses.reason" :value="reportCauses.code">
                  {{reportCauses.reason}}
                </a-select-option>
              </a-select>
              <a-checkbox style="margin-left: 8px" v-model:checked="wanfapei" v-if="report=='delayDeliveryReport'">延迟预警晚发赔报备</a-checkbox>
            </a-col>
            <a-col :span="10" style="text-align: right">
              <a-button @click="clearItem" type="primary" danger>开始批量报备</a-button>
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import { message,Modal } from 'ant-design-vue'
import {report} from "./api/item";
import {reactive, ref} from "vue";
import {getItem as storageGet} from "@/utils/storage";

export default {
  name: "report",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      words : "",
      report : "delayDeliveryReport",
      checkOption : {
        newTips : true,
        dayCheck : true,
        pv : true,
        sale : true
      },
      images : [],
      imageUrl : "",
      delayDeliveryReportReportCauses : [{
        "reason": "无货/缺货",
        "bizType": 1,
        "code": 103,
        "yn": 1,
        "planOutSelectDay": 99,
        "reasonDesc": "仅晚发赔订单可选择此原因进行报备，报备后无需消费者审核，提交后即默认报备成功。报备成功后，将会对消费者进行300京豆的赔付，赔付金额将由商家承担，请您谨慎选择。"
      }, {
        "reason": "国家大型会议/政策",
        "bizType": 1,
        "code": 21,
        "yn": 1,
        "planOutSelectDay": 7,
        "reasonDesc": "系统将会通过多家承运商对订单收发货地的承运能力来判断您的报备是否通过。若报备失败，建议您及时使用延迟发货报备协商卡片与消费者进行协商报备。"
      }, {
        "reason": "已与消费者达成一致",
        "bizType": 1,
        "code": 101,
        "yn": 1,
        "planOutSelectDay": 90,
        "reasonDesc": "您的报备将会以延迟发货报备协商卡片的形式发送给消费者进行选择；请您及时与消费者进行联系，如消费者未在24H内点击接受或拒绝，那么该报备单将失效。"
      }, {
        "reason": "不可抗力原因",
        "bizType": 1,
        "code": 102,
        "yn": 1,
        "planOutSelectDay": 7,
        "reasonDesc": "系统将会通过多家承运商对订单收发货地的承运能力来判断您的报备是否通过。若报备失败，建议您及时使用延迟发货报备协商卡片与消费者进行协商报备。"
      }],
      delaySaleAfterReportCauses : [{"reason":"自然灾害","bizType":3,"code":30,"yn":1,"reasonDesc":"证据说明：请提供国家型公告截图，并在备注尽肯能描述店铺所受影响。"},{"reason":"疫情","bizType":3,"code":31,"yn":1,"reasonDesc":"证据说明：请提供国家型公告截图，并在备注尽肯能描述店铺所受影响。"},{"reason":"国家大型会议/政策","bizType":3,"code":32,"yn":1,"reasonDesc":"证据说明：请提供国家型公告截图，并在备注尽肯能描述店铺所受影响。"},{"reason":"物流站点倒闭","bizType":3,"code":33,"yn":1,"reasonDesc":"请在备注明确填写商家 ①售后地址所在地/客户所在地 ②本店铺受影响时间及相关有效凭证。"},{"reason":"京东上门取件，揽件完成后超7天未妥投","bizType":3,"code":34,"yn":1,"reasonDesc":"请提供相关有效凭证截图。"},{"reason":"客户发货，物流超7天未妥投","bizType":3,"code":35,"yn":1,"reasonDesc":"请提供相关有效凭证截图。"},{"reason":"维修/检测处理超时","bizType":3,"code":36,"yn":1,"reasonDesc":"证据说明：证据审核仅支持咚咚聊天内容，请确保已在咚咚内和消费者对延长维修时间达成一致。"}],
      delayDeliveryReportReportCause : null,
      wanfapei : false,
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)==false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      console.log(useShopIds.value)
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods:{
    addImage(){
      if (this.images.length >=5){
        Modal.error({
          title: '添加失败',
          content: '最多只能添加五张图片进行报备',
        });
        return
      }
      if (this.imageUrl != ""){
        this.images.push(this.imageUrl)
        this.imageUrl = ""
      }else{
        Modal.error({
          title: '添加失败',
          content: '图片链接不能为空',
        });
      }
    },
    openWindow(url){
      window.open(url)
    },
    clearItem(){
      if (this.useShopIds==""){
        Modal.error({
          title: '执行失败',
          content: '报备店铺不可为空',
        });
        return
      }
      if (this.delayDeliveryReportReportCause==null){
        Modal.error({
          title: '执行失败',
          content: '请选择报备原因',
        });
        return
      }
      if (this.words==""){
        Modal.error({
          title: '执行失败',
          content: '报备理由不可为空白',
        });
        return
      }
      report(this.useShopIds,{
        "images" : this.images,
        "contents" : this.words,
        "delayDeliveryReportReportCause" : this.delayDeliveryReportReportCause,
      },this.report,this.wanfapei).then(res=>{
        if (res.success){
          message.success("成功提交订单批量报备脚本")
        }else{
          message.error(res.msg)
        }
      }).catch(e=>{
        message.error(e)
      })
    },
    useWords(){
      this.words = "如图片上诉原因导致我们"
    }
  },
  watch:{

  }
}
</script>

<style>
.bottom{
  position: fixed;
  bottom:0px;
  left:0px;
  height:72px;
  width:100%;
}


</style>
