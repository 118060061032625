import request from "@/utils/request";
import {checkComponent} from "@/api/init";
import store from "@/store";


export const venderTakeAddress = (shopId)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/api/venderTakeAddress`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopId" : shopId
                }),
                uninstall : false
            },
            loading : false,
        })
    }


}

export const stockOut = (data)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/api/stockOut`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopId" : data.shopId,
                    "order" : data.order,
                    "deliveryId" : data.deliveryId,
                    "deliveryNums" : data.deliveryNums,
                    "venderTaskAddressId": data.venderTaskAddressId,
                }),
                uninstall : false
            },
            loading : false,
        })
    }
}

export const stockOutTest = (data)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/api/ShopStockOut`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "order": data.order,
                }),
                uninstall : false
            },
            loading : false,
        })
    }


}

export const remark = (orderId,itemId,remark)=>{
    return request({
        url: `shop_manage/api/remark`,
        method: 'POST',
        loading : false,
        data : {
            orderId : orderId,
            skuId : itemId,
            remark : remark
        }
    })
}


export const bindShopIds = ()=>{
    return request({
        url: `shop/manage/bindShopIds`,
        method: 'GET',
        loading : false,
    })

}

export const unbindShopId = (shopId)=>{
    return request({
        url: `shop/manage/unbindShopId?shopId=${shopId}`,
        method: 'GET',
        loading : false,
    })
}


export const bindShopIdsDetail= (loading=true)=>{
    return request({
        url: `shop/manage/bindShopIdsDetail`,
        method: 'GET',
        loading : loading,
    })

}


export const shopList = (data)=>{
    return request({
        url: 'shop_manage/upload/list',
        method: 'POST',
        data :data
    })
}


export  const buyCard = (data) =>{
    return request({
        url: 'shop_manage/upload/buyCard',
        method: 'POST',
        data :data
    })
}