import request from "@/utils/request";

export const getProfitList = (data)=>{
    return request({
        url: 'order/profit/profit',
        data:data,
        method: 'POST',
    })
}


export const toDayProfit = (loading = false)=>{
    return request({
        url: 'order/profit/toDayProfit',
        method: 'GET',
        loading : loading
    })
}
