import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const add = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/logistComMan/add`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export const exportAdvancecompensate = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/logistComMan/exportAdvancecompensate`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const addTakeAddress = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/logistComMan/addTakeAddress`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const doAddStoreTemplate = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/logistComMan/doAddStoreTemplate`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
export const exportSystemDown = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/exportSystemDown`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export const updateFreight = (shopIds,price)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/logistComMan/updateFreight`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "price" : price
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export default {
    add,exportSystemDown
};
