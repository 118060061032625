<template>
  <div class="orderList">
    <a-row>
      <a-col :span="24">
        <a-space direction="vertical" :size="36">
          <a-card class="animate__animated animate__flash" hoverable v-for="(order,orderIndex) in orderList" :key="order.id" size="small" style="width: 99%">
            <a-row>
              <a-col :span="10" style="text-align: left">
                <h3>订单信息</h3>
              </a-col>
              <a-col :span="14" style="text-align: right">
              <a-dropdown>
                <template #overlay>
                  <a-menu>
                    <a-menu-item @click="refresh(order,orderIndex)" key="1">
                      <reload-outlined />&nbsp;&nbsp;更新京东状态
                    </a-menu-item>
                  </a-menu>
                </template>
                <a-button  type="link" size="small"><setting-outlined />订单操作<down-outlined /></a-button>
              </a-dropdown>
            </a-col>
            </a-row>
            <a-divider style="margin-top: 2px"></a-divider>
            <div :class="'order_'+orderIndex+'_head'" style="text-align: left;margin-top: 16px;width: 100%;overflow: hidden;">
              <a-space :size="14"  style="">
                <a-checkbox v-model:checked="order.select" style="text-align: left;" size="small"></a-checkbox><a-divider type="vertical" />
                <a-dropdown>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="copy(''+order.shopId)" key="1">
                        <copy-outlined />&nbsp;复制店铺ID
                      </a-menu-item>
                      <a-menu-item @click="copy(''+order.shopName)" key="2">
                        <copy-outlined />&nbsp;复制店铺名
                      </a-menu-item>
                      <a-menu-item key="2" @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+order.shopId)"><exclamation-circle-outlined />&nbsp;查看店铺</a-menu-item>
                    </a-menu>
                  </template>
                  <a-button :style="{'color': isOnline(order.shopId)?'#409EFF':'#F56C6C'}" size="small"><shop-outlined />{{order.shopName}}{{isOnline(order.shopId)?'':'(掉线)'}}<DownOutlined /></a-button>
                </a-dropdown><a-divider type="vertical" />
                <a-dropdown>
                  <template #overlay>
                    <a-menu>
                      <a-menu-item @click="copy(''+order.orderId)" key="1">
                        <copy-outlined />&nbsp;复制订单
                      </a-menu-item>
                      <a-menu-item key="2" @click="openWindow('https://order.cklllll.com/extension.html?type=orderInfo&shopId='+order.shopId+'&orderid='+order.orderId)"><exclamation-circle-outlined />&nbsp;查看订单</a-menu-item>
                    </a-menu>
                  </template>
                  <a-button  style="color: #409EFF"  size="small"><file-text-outlined />订单号:{{order.orderId}}<DownOutlined /></a-button>
                </a-dropdown><a-divider type="vertical" />
                <a-button style="color: #409EFF" target="_blank" :href="'https://order.cklllll.com/extension.html?type=afs&shopId='+order.shopId+'&afsServiceId='+order.afsServiceId+'&afsServiceStatus='+order.afsServiceStatus+'&afsApplyId='+order.afsApplyId"  size="small"><warning-outlined />服务单号:{{order.afsServiceId}}</a-button><a-divider type="vertical" />
                <a-button style="color: #409EFF" size="small"><warning-outlined />{{transformTime(order.afsApplyTime)}}</a-button><a-divider type="vertical" />
                <a-button style="color: #409EFF" size="small"><warning-outlined />{{order.popApplyReason}}</a-button><a-divider type="vertical" />
                <a-button style="color: #409EFF" size="small"><warning-outlined />售后状态:{{getAfsServerStatus(order.afsServiceStatus).text}}</a-button><a-divider type="vertical" />
                <a-divider type="vertical" />
              </a-space>
            </div>
            <a-divider></a-divider>
            <div style="margin-top: 12px" v-for="(item,itemIndex) in order.orderItems" :key="item.id" :class="'itemIndex'+itemIndex">
              <a-row :gutter="20">
                <a-col :span="3" style="text-align: center">
                  <a-image style="width: 80%;height: 80%" :src="item.orderItems.skuimage.replace('N5','N0').replace('N4','N0')"></a-image>
                </a-col>
                <a-col :span="5" style="text-align: left">
                  <i @click="browserWindow(item.orderItems.skuName,item.orderItems.url)" style="font-size: 14px;color:  #409EFF;font-style:normal;">{{item.orderItems.skuName}}</i><br><br>
                  <i style="font-size: 14px;color:  #606266;font-style:normal;">规格:{{item.orderItems.color}}</i><br>

                  <i style="font-size: 14px;color:  #606266;font-style:normal;">购买数量:x{{item.orderItems.skuNum}}</i>
                  <div style="margin-top: 12px" v-if="order.warnType>-1 || order.breakAgreementType>-1 || order.refunded>-1">
                    <a-space>
                      <a-button v-if="order.warnType>-1" type="warning" size="small" ><warning-outlined /> {{order.warnType==1?'出库预警':order.warnType==3?'揽收预警':'未知预警'}}</a-button>
                      <a-button v-if="order.breakAgreementType>-1" type="danger" size="small" ><warning-outlined />{{order.breakAgreementType==1?'出库超时':order.breakAgreementType==3?'揽收超时':'未知超时'}}</a-button>
                      <a-button v-if="order.refunded>-1" type="danger" size="small" ><warning-outlined /> 本单已经退款</a-button>
                    </a-space>
                  </div>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <div v-if="typeof (order.userRemark)=='string' && order.userRemark!=''" style="margin-top: 12px">
                    <a-button danger type="primary" style="font-size: 14px;" >客户备注:{{order.userRemark}}</a-button>
                  </div>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <a-textarea style="color: #303133" v-model:value="item.remark" @change="itemRemark(order.orderId,item.skuId,item.remark)" size="mini"  placeholder="本地备注信息">
                  </a-textarea>
                  <br><br>
                  <a-textarea style="color: #303133" v-model="order.shopRemark" size="mini" placeholder="商家备注信息">
                  </a-textarea>
                </a-col>
                <a-col :span="4" style="text-align: center">
                  <a-button v-if="item.status > 0 && item.status<99" @click="buyWindow(order,item)" type="danger"><shopping-cart-outlined />申请售后</a-button>
                </a-col>
                <a-col :span="4" style="text-align: center" name="buyOrder" :data-orderIndex="orderIndex" :data-itemIndex="itemIndex" :id="order.orderId + '_' + item.skuId" :data-update="0" :data-orderId="order.orderId" :data-skuId="item.skuId">
                  <a-row>
                    <a-col :span="22" style="text-align: left">
                      <a-spin tip="更新中..." :spinning="item.reload !=null || item.reload !=undefined">
                        <a-empty v-if="item.order_sn=='' || item.order_sn==null">
                          <template #description>
                            <span style="color:red;">该订单尚未采购</span><br><br>
                            <a-button @click="buyWindow(order,item)" danger size="small"><shopping-cart-outlined />采购</a-button>
                            &nbsp;或&nbsp;
                            <a-button class="refreshOrder" @click="refreshPdd(item,orderIndex)" :data-skuId="item.skuId" :data-orderId="order.orderId" size="small"><reload-outlined />刷新</a-button>
                          </template>
                        </a-empty>
                        <div v-if="!item.order_sn == '' || !item.order_sn == null">
                          <a-row>
                            <a-col :span="5" style="text-align: left;color: #409EFF"><span style="color: #409EFF;font-size: 11px">买手号</span></a-col>
                            <a-col :span="19" style="text-align: right;color: #409EFF">
                              <a-typography-paragraph @click="openWindow(item.buyOrderType==0?'https://order.cklllll.com/extension.html?type=pddOrderInfo&order_sn='+item.order_sn+'&duoduoId='+item.duoduoId:'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+item.order_sn)" :copyable="{'text' : item.nickname}"><span style="color: #409EFF;font-size: 11px">{{item.nickname}}</span></a-typography-paragraph>
                            </a-col>
                            <a-col :span="4" style="text-align: left;color: #409EFF;margin-top: -14px"><span style="color: #409EFF;font-size: 11px">订单号</span></a-col>
                            <a-col :span="20" style="text-align: right;color: #409EFF;margin-top: -14px">
                              <a-typography-paragraph  @click="openWindow(item.buyOrderType==0?'https://order.cklllll.com/extension.html?type=pddOrderInfo&order_sn='+item.order_sn+'&duoduoId='+item.duoduoId:'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+item.order_sn)" :copyable="{'text' : item.order_sn}"><span style="color: #409EFF;font-size: 11px">{{item.order_sn}}</span></a-typography-paragraph>
                            </a-col>
                            <a-col :span="5" style="margin-top: -14px;text-align: left;color: #409EFF"><span style="color: #409EFF;font-size: 11px">时  间：</span></a-col>
                            <a-col :span="19" style="margin-top: -14px;text-align: right;color: #409EFF"><span style="color: #409EFF;font-size: 11px">{{transformTime(item.buyOrderPayTime)}}</span></a-col>
                            <a-col :span="5" style="text-align: left;color: #409EFF"><span style="color: #409EFF;font-size: 11px">状  态：</span></a-col>
                            <a-col :span="19" style="text-align: right;color: #409EFF"><span style="color: #409EFF;font-size: 11px">{{item.buyOrderStatusStrCN}}</span>&nbsp;&nbsp;<a :data-orderId="order.orderId" :data-skuId="item.skuId"  class="refreshOrder"  @click="refreshPdd(item,orderIndex)"><reload-outlined style="color: #409EFF;font-size: 11px"></reload-outlined></a></a-col>
                            <a-col v-if="item.expressNo!=null && item.expressNo!=''" :span="8" style="text-align: left;color: #409EFF"><span style="color: #409EFF;font-size: 11px">{{item.expressName}}</span></a-col>
                            <a-col v-if="item.expressNo!=null && item.expressNo!=''" :span="16" style="text-align: right;color: #409EFF">
                              <a-typography-paragraph  @click="openWindow('https://www.baidu.com/s?ie=utf-8&f=8&rsv_bp=1&rsv_idx=1&tn=baidu&wd='+item.expressNo)" :copyable="{'text' : item.expressNo}"><span style="color: #409EFF;font-size: 11px">{{item.expressNo}}</span></a-typography-paragraph>
                            </a-col>
                          </a-row>
                        </div>
                      </a-spin>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </a-card>
        </a-space>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import useClipboard from "vue-clipboard3";
import {message} from "ant-design-vue";
import buy from "@/components/server/buyWindow";
import out from "@/components/server/outWindow";
import browser from "@/components/server/browserWindow";
import {refreshOrder ,refreshOrderPdd ,remark } from "@/api/order";

export default {
  name: "afsOrderList",
  props : {
    orderList : [],
    shopIdList : [],
  },
  setup(){
    const { toClipboard } = useClipboard()
    const copy = async (text) => {
      try {
        await toClipboard(text)
        message.success('复制成功:'+text)

      } catch (e) {
        message.success('复制失败:'+e)
      }
    }
    return{
      copy,refreshOrder,refreshOrderPdd,remark
    }
  },
  methods:{
    orderStatus(status){
      const statusList =  [
        { label: '等待出库', value: '8' ,color : "#409EFF" },
        { label: '等待收货', value: '16',color : "#E6A23C" },
        { label: '完成订单', value: '19',color : "#67C23A" },
        { label: '已取消', value: '-1'  ,color : "#F56C6C"},
      ]
      for (let i = 0 ; i < statusList.length;i++){
        if (statusList[i].value == status){
          return statusList[i]
        }
      }
      return { label: '未知状态,'+status, value: status  ,color : "#909399"}
    },
    buyWindow(order,item){
      item.status = 99
      buy.open(order,item)
    },
    outWindow(order){
      out.open(order)
    },
    browserWindow(title,url){
      browser.open(title,url)
    },
    openWindow(url){
      window.open(url)
    },
    transformTime(timestamp) {
      let time = new Date(timestamp*1000);
      let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
      let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
      let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
      M = M < 10 ? "0"+ M : M;
      d = d < 10 ? "0"+ d : d;
      h = h < 10 ? "0"+ h : h;
      m = m < 10 ? "0"+ m : m;
      s = s < 10 ? "0"+ s : s;
      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    refresh(order,index){
      refreshOrder(order).then(res=>{
        if (res.success){
            // eslint-disable-next-line no-empty
            try {res.data.address = JSON.parse( res.data.address)} catch (e) {}
            // eslint-disable-next-line no-empty
            try {res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo)} catch (e) {}
            // eslint-disable-next-line no-empty
            try {res.data.buyerInfo = JSON.parse(res.data.buyerInfo)} catch (e) {}
            // eslint-disable-next-line no-empty
            try {res.data.shopInfo = JSON.parse(res.data.shopInfo)} catch (e) {}

          res.data.orderItems.forEach(item=>{
              item.expressInfo = JSON.parse(item.expressInfo)
              item.orderItems = JSON.parse(item.orderItems)
              item.orderItems.visible = false
            })
          res.data.checkBoxSelect = false;
          // eslint-disable-next-line vue/no-mutating-props
          this.orderList[index] = res.data
          message.success("更新订单状态成功")
        }else{
          message.error(res.msg)
        }
      })
    },
    refreshPdd(item,index){
      item.reload = true
      refreshOrderPdd(item).then(res=>{
        if (res.success){
          item.reload = null
          // eslint-disable-next-line no-empty
          try {res.data.address = JSON.parse( res.data.address)} catch (e) {}
          // eslint-disable-next-line no-empty
          try {res.data.buyAddressInfo = JSON.parse(res.data.buyAddressInfo)} catch (e) {}
          // eslint-disable-next-line no-empty
          try {res.data.buyerInfo = JSON.parse(res.data.buyerInfo)} catch (e) {}
          // eslint-disable-next-line no-empty
          try {res.data.shopInfo = JSON.parse(res.data.shopInfo)} catch (e) {}
          res.data.orderItems.forEach(item=>{
            item.expressInfo = JSON.parse(item.expressInfo)
            item.orderItems = JSON.parse(item.orderItems)
            item.orderItems.visible = false
          })
          res.data.checkBoxSelect = true;

          res.data.shopName="3123123131"
          // eslint-disable-next-line vue/no-mutating-props
          this.orderList[index] = res.data
          message.success("采购订单信息已同步")
        }else{
          item.reload = null
          message.error(res.msg)
        }
      })
    },
    itemRemark(orderId,itemId,remarkStr){
      remark(orderId,itemId,remarkStr).then(res=>{
        if (res.success){
          message.success("备注已修改")
        }else{
          message.info("备注未修改")
        }
      })
    },
    isOnline(shopId){
      for (let i = 0 ; i < this.$props.shopIdList.length;i++){
        if (shopId==this.$props.shopIdList[i].shopId){
          if (this.$props.shopIdList[i].status > -1){
            return true
          }
        }
      }
      return false
    },
    getAfsServerStatus(Status){
      let statusList = [
        {
          "status":4000,
          "text":"待收货,闪电退款",
          "color":"red",
          "type":"waitReceive"
        },
        {
          "status":0,
          "text":"待处理",
          "color":"#409EFF",
          "type":"waitProcess"
        },
        {
          "status":1031,
          "text":"待收货,客户未发货",
          "color":"#F56C6C",
          "type":"waitReceive"
        },
        {
          "status":1033,
          "text":"待收货,客户已发货",
          "color":"red",
          "type":"waitReceive"
        },
        {
          "status":10001,
          "text":"待审核",
          "color":"#409EFF",
          "type":"waitAudit"
        }
      ]
      for (let i = 0 ; i < statusList.length; i++){
        if (Status===statusList[i].status){
          return statusList[i]
        }
      }
      return {
        "status":-1,
        "text":"未知状态",
        "color":"red"
      }
    }
  }
}
</script>

<style scoped>
  .text-style{

  }
</style>
