import { createStore } from 'vuex'
import { get } from '@/api/store'
import createPersistedstate from 'vuex-persistedstate'

export default createStore({
    state: {
        isLogin : false,
        token : get("token").data,
        isGetOrder : false,
        isInstall : false,
        setting : {
            autoPay : false,
            showBuyerUserInfo : true,
            changeBuyerPhone : false,
            passWord : "",
        },
        userInfo : {

        },
        currentPage : ['home'],
    },
    mutations: {
        setLogin(state,data){
            state.isLogin=data;
        },
        setToken(state,data){
            state.token=data;
        },
        setIsGetOrder(state,data){
            state.isGetOrder=data;
        },
        setIsInstall(state,data){
            state.isInstall=data;
        },
        setCurrentPage(state,data){
            state.currentPage=data;
        },
    },
    actions: {
        updateLogin(context,data){
            context.commit('setLogin',data);
            if (data==false){
                context.commit('setToken',null);
            }
        },
        updateToken(context,data){
            context.commit('setToken',data);
        },
        updateIsInstall(context,data){
            context.commit('setIsInstall',data);
        },
        updateIsGetOrder(context,data){
            context.commit('setIsGetOrder',data);
        },
        updateCurrentPage(context,data){
            context.commit('setCurrentPage',data);
        },
    },
    modules: {
    },
    plugins: [
        createPersistedstate({
            key: 'huoyunOrder-store', // 存数据的key名   自定义的  要有语义化
        })
    ]
})

