const colors = [
    {
        value : 8,
        label : '等待出库',
        color : '#5bc0de'
    },
    {
        value : -2,
        label : '删除订单',
        color : '#909399'
    },
    {
        value : 2,
        label : '等待付款',
        color : '#909399'
    },
    {
        value : 5,
        label : '订单暂停',
        color : '#E6A23C'
    },
    {
        value : 19,
        label : '订单完成',
        color : '#67C23A'
    },
    {
        value : 16,
        label : '等待收货',
        color : '#E6A23C'
    }
];
const flagAry = [
    'https://static.360buyimg.com/bus/webapp/order/image/0.png',
    'https://static.360buyimg.com/bus/webapp/order/image/1.png',
    'https://static.360buyimg.com/bus/webapp/order/image/2.png',
    'https://static.360buyimg.com/bus/webapp/order/image/3.png',
    'https://static.360buyimg.com/bus/webapp/order/image/4.png',
    'https://static.360buyimg.com/bus/webapp/order/image/5.png',
];
export const orderStatusColor = (status,statusCN)=>{
    let color = {
        value : status,
        label : statusCN,
        color : '#F56C6C'
    }
    for (let i = 0 ; i < colors.length ; i++){
        if (colors[i].value === status){
            color =  colors[i]
        }
    }
    return color
}

export const flag = (flag)=>{
    if (!flag){
        flag = 0
    }
    return flagAry[flag];
}
