<template>
  <div style="text-align: center;width: 96%;margin-left: 2%">
    <a-steps :current="0" size="small">
      <a-step :status="index>=0?'finish':'wait'">
        <template #title>
          <span style="font-size: 12px">第一步:导入订单号</span>
        </template>
        <template #icon>
          <import-outlined />
        </template>
      </a-step>
      <a-step :status="index>=1?'finish':'wait'">
        <template #title>
          <span style="font-size: 12px">第二步:执行自动发货</span>
        </template>
        <template #icon>
          <send-outlined />
        </template>
      </a-step>
      <a-step :status="index>=2?'finish':'wait'">
        <template #title>
          <span style="font-size: 12px">第三步:处理完成</span>
        </template>
        <template #icon>
          <file-done-outlined />
        </template>
      </a-step>
    </a-steps>
    <div v-show="index==0" style="margin-top: 16px">
      <a-alert
          message="提示：目前导入格式支持CSV、XLS、XLSX格式，如果导入文件中未指定快递公司，以默认快递为准。"
          type="warning"
      /><br>
      <div style="text-align: left">
        <a-row :gutter="20">
          <a-col span="6">
            <a-select
                size="small"
                show-search
                :filter-option="filterOption"
                placeholder="请选择您默认发货的快递"
                v-model:value="select"
                style="width: 256px;text-align: left"
            >
              <a-select-option v-for="express in expressList" :key="express.id" :name="express.name" :value="express.id">
                {{express.name}}
              </a-select-option>
            </a-select>
          </a-col>
          <a-col span="6">
            <a-upload
                v-model:file-list="fileList"
                :before-upload="beforeUpload"
                accept=".xlsx,.xls,.csv">
              <span>请上传导入模板:</span>&nbsp;
              <a-button size="small">
                <upload-outlined></upload-outlined>
                上传导入模板文件
              </a-button>
            </a-upload>
          </a-col>
          <a-col span="6" style="text-align: left">
            <a :href="'./导入发货模板.xls?t'+new Date().getTime()" download="导入发货模板.xls">            <download-outlined></download-outlined>点击下载导入模板</a><br>
          </a-col>
          <a-col span="5" style="text-align: right">
            <a-button type="primary" size="small" @click="next"><right-outlined />下一步</a-button>
          </a-col>
        </a-row>
      </div>
    </div>
    <div v-show="index==1" style="margin-top: 16px">
      <a-row>
        <a-col span="24" style="text-align: left">
          <a-alert
              message="使用批量发货功能需要启用获芸增强组件,运行期间请不要关闭本页面"
              type="warning"
          />
        </a-col><br><br><br>
        <a-col span="12" style="text-align: left">
          <span>当前导入{{orderList.length}}个订单,当前完成:0个</span>
        </a-col>
        <a-col span="12" style="text-align: right">
          <a-button size="small" @click="index--"><left-outlined />上一步</a-button>&nbsp;&nbsp;
          <a-button type="primary" size="small" @click="next"><right-outlined />下一步</a-button>
        </a-col>
      </a-row>
    </div>
    <div v-show="index==2" style="margin-top: 16px">
      <a-row>
        <a-col span="6" style="text-align: left">
          <a-button type="primary" size="small" @click="next"><reload-outlined />重新开始</a-button>
        </a-col>
      </a-row>
    </div>
  </div>

</template>

<script>
import * as XLSX from 'xlsx'
import {message} from "ant-design-vue"
import {ref} from "vue"
import {ShopStockOut} from "@/api/order";

export default {
  name: "sendOut",
  components:{

  },
  data(){
    return{
      index : 0,
      expressList : [
        {
          "id": 2087,
          "name": "京东快递"
        },
        {
          "id": 1999327,
          "name": "达达同城快送"
        },
        {
          "id": 1819479,
          "name": "丰网速运"
        },
        {
          "id": 1946677,
          "name": "中欧物流"
        },
        {
          "id": 1946679,
          "name": "中欧速运"
        },
        {
          "id": 1988647,
          "name": "快弟来了"
        },
        {
          "id": 463,
          "name": "圆通快递"
        },
        {
          "id": 465,
          "name": "邮政EMS"
        },
        {
          "id": 467,
          "name": "顺丰快递"
        },
        {
          "id": 471,
          "name": "龙邦快递"
        },
        {
          "id": 1327,
          "name": "韵达快递"
        },
        {
          "id": 1409,
          "name": "宅急送"
        },
        {
          "id": 1499,
          "name": "中通速递"
        },
        {
          "id": 1549,
          "name": "宅急便"
        },
        {
          "id": 1747,
          "name": "优速"
        },
        {
          "id": 2094,
          "name": "快捷速递"
        },
        {
          "id": 2096,
          "name": "联邦快递"
        },
        {
          "id": 2105,
          "name": "速尔快递"
        },
        {
          "id": 2130,
          "name": "德邦物流"
        },
        {
          "id": 2170,
          "name": "邮政快递包裹"
        },
        {
          "id": 2171,
          "name": "中国邮政挂号信"
        },
        {
          "id": 2462,
          "name": "天地华宇"
        },
        {
          "id": 3046,
          "name": "德邦快递"
        },
        {
          "id": 4832,
          "name": "安能物流"
        },
        {
          "id": 5419,
          "name": "中铁物流"
        },
        {
          "id": 6012,
          "name": "斑马物联网"
        },
        {
          "id": 171686,
          "name": "易宅配物流"
        },
        {
          "id": 222693,
          "name": "贝业新兄弟"
        },
        {
          "id": 323141,
          "name": "亚风快运"
        },
        {
          "id": 332098,
          "name": "用户自提"
        },
        {
          "id": 336878,
          "name": "京东大件物流"
        },
        {
          "id": 568096,
          "name": "万家康"
        },
        {
          "id": 599866,
          "name": "跨越速运"
        },
        {
          "id": 247899,
          "name": "安得物流"
        },
        {
          "id": 605050,
          "name": "中铁CRE"
        },
        {
          "id": 596494,
          "name": "安能快递"
        },
        {
          "id": 617027,
          "name": "POP国际物流"
        },
        {
          "id": 680414,
          "name": "中通快运"
        },
        {
          "id": 688636,
          "name": "增速益"
        },
        {
          "id": 689816,
          "name": "集成"
        },
        {
          "id": 692584,
          "name": "品骏快递"
        },
        {
          "id": 724080,
          "name": "珠海广丰"
        },
        {
          "id": 3668,
          "name": "邮政EMS标准快递"
        },
        {
          "id": 732960,
          "name": "EWE智慧物流"
        },
        {
          "id": 731302,
          "name": "韵达快运"
        },
        {
          "id": 740588,
          "name": "久耶供应链"
        },
        {
          "id": 773574,
          "name": "京东快运"
        },
        {
          "id": 831932,
          "name": "京东同城速配"
        },
        {
          "id": 832230,
          "name": "顺心捷达"
        },
        {
          "id": 881232,
          "name": "众邮快递"
        },
        {
          "id": 668392,
          "name": "RoyalMail"
        },
        {
          "id": 1325880,
          "name": "壹米滴答"
        },
        {
          "id": 1362538,
          "name": "联通码上购"
        },
        {
          "id": 1369364,
          "name": "麦哲伦跨境物流"
        },
        {
          "id": 256122,
          "name": "UPS"
        },
        {
          "id": 256059,
          "name": "DHL"
        },
        {
          "id": 1512990,
          "name": "USPS"
        },
        {
          "id": 1613410,
          "name": "京东医药"
        },
        {
          "id": 1665004,
          "name": "云仓速递"
        }
      ],
      select : null,
      outputs: [],
      orderList : [],
      orderIndex : 0,
    }
  },
  setup(){
    const fileList = ref([]);
    const filterOption = (input, option) => {
      console.log(option)
      return option.name.indexOf(input) >= 0;
    };
    return {
      fileList,
      filterOption
    }

  },
  mounted(){

  },
  methods:{
    changeSelect(){
      console.log(this.sendAddressId)
      console.log(this.select)
    },
    handleReadExcel (file) {
      const fileReader = new FileReader()
      fileReader.onload = (ev) => {
        try {
          const fileData = ev.target.result
          console.log(XLSX)
          const workbook = XLSX.read(fileData, {
            type: 'binary'
          })
          const wsname = workbook.SheetNames[0] // 取第一张表
          const snArr = XLSX.utils.sheet_to_json(workbook.Sheets[wsname]) // 生成json表格内容
          this.orderList = []
          snArr.forEach(order=>{
            console.log(order.物流公司)
            // eslint-disable-next-line no-unused-vars
            let deliveryId = this.select
            this.expressList.forEach(express=>{
              if (express.name==order.物流公司){
                deliveryId=express.id
              }
            })
            this.orderList.push({
              orderId : order.订单号,
              deliveryId : deliveryId,
              deliveryNums : order.运单号,
              shopName : order.店铺名
            })
          })
          console.log(this.orderList)
          message.success(`成功导入${this.orderList.length}个订单`)
        } catch (e) {
          message.error("上传失败,错误:"+e.message)
          console.log(e.message)
          return false
        }
      }
      fileReader.readAsBinaryString(file)
    },
    beforeUpload (file) {
      console.log(XLSX)
      const arr = file.name.split('.')
      if (arr[arr.length - 1] !== 'xlsx' && arr[arr.length - 1] === 'xls' && arr[arr.length - 1] === 'csv') {
        message.error("上传失败,当前只支持XLSX或者XLS格式")
        return
      }
      this.fileList = [file]
      if (this.fileList.length > 0) {
        this.handleReadExcel(file)
      }
      return false
    },
    next(){
      switch (this.index){
        case 0:
          if (this.select==null){
            message.error("请选择默认发货的快递")
            return
          }
          if (this.orderList.length==0){
            message.error("请导入要进行发货的订单号")
            return
          }
          this.index++
          break
        case 1:
          this.orderIndex = 0;
          this.postOrder()
          this.index++
          break
        case 2:
          this.index=0
          break
      }
    },
    postOrder(){
      ShopStockOut(this.orderList,true).then(res=>{
        if (res.success){
          message.success(`已成功提交发货申请`)
        }else{
          message.error(`发货失败,${res.msg}`)
        }
      })
    }
  }
}
</script>

