import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const setCoupon = (shopIds,num,price,sub)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/setCoupon/setCoupon`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    price,
                    num,
                    sub
                }),
                uninstall : true
            },
            loading : true,
        })
    }
}



export const setCouponNew = (shopIds,num,price,initial=9.9,start=0)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/setCoupon/setCouponNew`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    price,
                    num,
                    initial,
                    start
                }),
                uninstall : true
            },
            loading : true,
        })
    }
}
