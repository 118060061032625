import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const checkShopIds = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarket/server`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在检测智云管家店铺是否可用,详细进度请查看获芸增强组件"
        })
    }
}


export const uploadTasks = (uploadTasks,uploadChecked)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarket/zhiyun`,
                parameter : JSON.stringify({
                    "tasks" : uploadTasks,
                    "upload" : uploadChecked
                }),
                uninstall : false
            },
            loading : true,
            text : "正在提交上货任务"
        })
    }
}

export const imgzoneClean = (uploadTasks)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/serviceMarket/imgzoneClean`,
                parameter : JSON.stringify({
                    "tasks" : uploadTasks,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在请求清理"
        })
    }
}


export default {
    checkShopIds,uploadTasks,imgzoneClean
}
