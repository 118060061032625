<template>
  <br>
  <a-row :gutter="30">
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>总资产金额</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://shop.jd.com'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  (parseFloat(item.totalBalance) +
                              parseFloat(item.currentAmount) +
                                parseFloat(item.walletAccountBalance) +
                                parseFloat(item.riskAmount) +
                                parseFloat(item.goodsAmount) -
                                parseFloat(item.brokerageAmount))
                                .toFixed(2) > 0 ?'#F56C6C':'#67C23A'}">
            {{(parseFloat(item.totalBalance) +
              parseFloat(item.currentAmount) +
              parseFloat(item.walletAccountBalance) +
              parseFloat(item.goodsAmount) -
              parseFloat(item.brokerageAmount))
              .toFixed(2)}}&nbsp;元
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>待结算货款</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fin.shop.jd.com/taurus/comprehensiveIndex#/order'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  item.goodsAmount > 0 ?'#F56C6C':'#67C23A'}">
            +&nbsp;{{parseFloat(item.goodsAmount).toFixed(2)}}&nbsp;元
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>待扣除佣金</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fin.shop.jd.com/taurus/comprehensiveIndex#/order'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  item.brokerageAmount > 0 ?'#67C23A':'#F56C6C'}">
            -{{parseFloat(item.brokerageAmount).toFixed(2)}}&nbsp;元
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>钱包余额</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fin.shop.jd.com/homePage/homePageIndex#/'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  item.walletAccountBalance > 0 ?'#F56C6C':'#67C23A'}">
            {{parseFloat(item.walletAccountBalance).toFixed(2)}}&nbsp;元
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>营销钱包</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://jzt.jd.com/account/#/financial'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  item.totalBalance > 0 ?'#F56C6C':'#67C23A'}">
            {{parseFloat(item.totalBalance).toFixed(2)}}&nbsp;元
          </p>
        </div>
      </a-card>
    </a-col>
    <a-col :span="4" style="text-align: left">
      <a-card size="small">
        <template #title>
          <a-row>
            <a-col :span="12">
              <span>店铺保证金</span>
            </a-col>
            <a-col style="text-align: right" :span="12">
              <a style="color: #909399" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://jzt.jd.com/account/#/financial'">查看详情&nbsp;<question-circle-outlined /></a>
            </a-col>
          </a-row>
        </template>
        <div style="text-align: right">
          <p :style="{'color':  item.currentAmount > 0 ?'#F56C6C':'#67C23A'}">
            {{parseFloat(item.currentAmount).toFixed(2)}}&nbsp;元(风险保证:{{parseFloat(item.riskAmount).toFixed(2)}} 元)
          </p>
        </div>
      </a-card>
    </a-col>
  </a-row>
</template>
<script>


export default {
  name: "amount",
  data(){
    return{

    }
  },
  props:{
    item : {},
  },
  mounted(){

  },
  methods:{

  }
}
</script>

<style scoped>

</style>
