<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择您要使用进行操作的的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="请选择您要使用进行操作的的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});console.log(useShopIds)" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>
    <br>
    <div style="text-align: center">
      <a-textarea
          v-model:value="words"
          placeholder="请输入要下架的输入关键字"
          :auto-size="{ minRows: 5, maxRows: 20 }"
      ></a-textarea>
      <br>
      <h3 style="color:#F56C6C;">
        <warning-outlined />
        请选择您要进行的操作123
      </h3>
    </div>
  </div>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="z-index:10001">
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="12" style="text-align: right"></a-col>
            <a-col :span="11" style="text-align: right">

              <a-button @click="wordsDown" type="primary">
                关键字下架
              </a-button>
              <a-divider  type="vertical"></a-divider>
              <a-button v-if="wareStatusStr=='systemDown'" @click="exportSystemDownOrder" type="primary" >导出下架商品</a-button><a-divider  v-if="wareStatusStr=='systemDown'" type="vertical"></a-divider>
              <a-button @click="exportItem" type="primary">
                <template #icon>
                  <svg t="1676617115291" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1875" width="200" height="200"><path d="M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-0.3 1.5-0.4 3-0.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-0.1 4.4-0.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390z m468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z" p-id="1876"></path></svg>
                </template>
                导出商品编码
              </a-button>
              <a-divider  type="vertical"></a-divider>
              <a-button @click="clearItem" type="primary">
                <template #icon>
                  <svg t="1676617115291" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1875" width="200" height="200"><path d="M899.1 869.6l-53-305.6H864c14.4 0 26-11.6 26-26V346c0-14.4-11.6-26-26-26H618V138c0-14.4-11.6-26-26-26H432c-14.4 0-26 11.6-26 26v182H160c-14.4 0-26 11.6-26 26v192c0 14.4 11.6 26 26 26h17.9l-53 305.6c-0.3 1.5-0.4 3-0.4 4.4 0 14.4 11.6 26 26 26h723c1.5 0 3-0.1 4.4-0.4 14.2-2.4 23.7-15.9 21.2-30zM204 390h272V182h72v208h272v104H204V390z m468 440V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H416V674c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v156H202.8l45.1-260H776l45.1 260H672z" p-id="1876"></path></svg>
                </template>
                下架商品
              </a-button>
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import { message,Modal } from 'ant-design-vue'
import {createVNode, getCurrentInstance, reactive, ref} from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {downOnSaleWords, exportSystemDown} from "./api/item";
import * as xlsx from 'xlsx'
import {getItem as storageGet} from "@/utils/storage";
import {exportItem} from "../onSale/api/item"; // Vue3 版本

export default {
  name: "forSale",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      wareStatusStr : "onSale",
      words : "",
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)==false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      console.log(useShopIds.value)
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods:{
    openWindow(url){
      window.open(url)
    },
    clearItem(){
      const appContext = getCurrentInstance()
      const shopGroup = reactive(this)
      Modal.confirm({
        title: '确认询问',
        content: '是否下架当前选中的店铺在售商品?',
        okText: '确认',
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: '取消',
        appContext,
        onOk(data=shopGroup) {
          if (data.useShopIds.length<1){
            Modal.error({
              title: '执行失败',
              content: '你还没有选择店铺',
            });
          }else{
            downOnSaleWords(data.useShopIds,data.wareStatusStr).then(res=>{
              if (res.success){
                message.success("成功在启动在售商品关键字下架脚本")
              }else{
                message.error(res.msg)
              }
            }).catch(e=>{
              message.error(e)
            })
          }
        },
      });
    },
    wordsDown(){
      if (this.words===""){
        Modal.error({
          title: '执行失败',
          content: '关键词不能为空',
        });
        return
      }
      const appContext = getCurrentInstance()
      const shopGroup = reactive(this)
      Modal.confirm({
        title: '确认询问',
        content: '是否下架当前选中的店铺在售包含指定关键字的商品?',
        okText: '确认',
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: '取消',
        appContext,
        onOk(data=shopGroup) {
          if (data.useShopIds.length<1){
            Modal.error({
              title: '执行失败',
              content: '你还没有选择店铺',
            });
          }else{
            downOnSaleWords(data.useShopIds,data.words).then(res=>{
              if (res.success){
                message.success("成功在启动在售关键字下架脚本")
              }else{
                message.error(res.msg)
              }
            }).catch(e=>{
              message.error(e)
            })
          }
        },
      });
    },
    exportSystemDownOrder(){
      exportSystemDown(this.useShopIds,this.wareStatusStr).then(res=>{
        if (res.success){
          const changeTableHead = (
              tableData = res.data,
              fieldName = {
                wareId: "wareId",
                wardStr: "下架原因",
                shopId: "店铺ID",
              }
          ) => {
            const list = tableData.map((item) => {
              const obj = {};
              for (const k in item) {
                if (fieldName[k]) {
                  obj[fieldName[k]] = item[k];
                }
              }
              return obj;
            });
            return list;
          };


          let fieldNameObj = {
            wareId: "wareId",
            wardStr: "下架原因",
            shopId: "店铺ID",
          };
          let list = changeTableHead(res.data, fieldNameObj);
          const data = xlsx.utils.json_to_sheet(list);
          // 创建工作簿
          const wb = xlsx.utils.book_new();
          // 将工作表放入工作簿中
          xlsx.utils.book_append_sheet(wb, data, "sheet1");
          // 生成文件并下载
          xlsx.writeFile(wb, `系统下架商品${res.data.length}个.xlsx`);
          message.success("导出成功")
        }else{
          message.error(res.msg)
        }
      }).catch(e=>{
        message.error(e)
      })
    },
    exportItem(){
      exportItem(this.useShopIds).then(res=>{
        if (res.success){
          message.success("获取成功")
          let orderList = "店铺名,商品编码\n"
          res.data.forEach(order=>{
            orderList += `${order.shopName},${order.spu}\n`
          })
          let textFileAsBlob = new Blob(["\ufeff",orderList], {type: 'text/plain'});
          //创建一个 a 元素
          let downloadLink = document.createElement('a');
          //指定下载过程中显示的文件名
          downloadLink.download =  '店铺商品SPU.csv';
          downloadLink.href = window.URL.createObjectURL(textFileAsBlob);
          downloadLink.style.display = 'none';
          document.body.appendChild(downloadLink);
          //模式鼠标左键单击事件
          downloadLink.click();
          document.removeChild(downloadLink)
        }else {
          Modal.warning({
            title : "导出失败",
            content : res.msg
          })
        }
      })
    }
  },
  watch:{

  }
}
</script>

<style>
  .bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height:72px;
    width:100%;
  }


</style>
