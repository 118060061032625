import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const authorization = ()=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/getAccessToken/getAccessToken`,
                parameter : "",
                uninstall : false
            },
            loading : true,
        })
    }
}

export const stockOut = (orderList)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/getAccessToken/stockOut`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    orderList : orderList
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export const orderShipModify = (orderList)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/getAccessToken/orderShipModify`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    orderList : orderList
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export const useCards = (shopIds,card)=>{
    return request({
        url: `/shop_manage/upload/useCards`,
        method: 'POST',
        loading : true,
        data : {
            shopIds,
            card,
        }
    })
}



export default {
    authorization,stockOut,orderShipModify
};
