import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const setPrice = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/price/price`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}



export const exportPrice = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/price/exportPrice`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
