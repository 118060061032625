<template>
  <a-row :gutter="12">
    <a-col :span="6">
      <a-card hoverable>
        <a-row :gutter="12">
          <a-col :span="24">
            <div style="text-align: left">
              <a-avatar :src="require('@/assets/avatar.png')" />
            </div>
          </a-col>
          <br><br>
          <a-col :span="10" style="text-align: left"><span>用户名</span></a-col>
          <a-col :span="14" style="text-align: right"><span>{{userInfo.userName}}</span></a-col>
          <br>
          <a-col :span="10" style="text-align: left"><span>余额</span></a-col>
          <a-col :span="14" style="text-align: right"><span>{{(userInfo.points / 100).toFixed(2)}} 元</span></a-col>
          <br>
          <a-col :span="10" style="text-align: left"><span>用户等级</span></a-col>
          <a-col :span="14" style="text-align: right"><span>普通用户</span></a-col>
        </a-row>
        <a-divider></a-divider>
        <div style="text-align: right">
          <a-button >充值</a-button>
        </div>
      </a-card>
    </a-col>
    <a-col :span="6">
      <a-card hoverable>
        <a-statistic
            title="今日拍单金额"
            :value="profit.totalPurchase"
            :precision="2"
            suffix="元"
            :value-style="{ color: '#3f8600' }"
        >
        </a-statistic>
        <a-divider></a-divider>
        <a-statistic
            title="今日拍单利润"
            :value="profit.totalProfit"
            :precision="2"
            suffix="元"
            :value-style="{ color: '#3f8600' }"
        >
        </a-statistic>
      </a-card>
    </a-col>
    <a-col :span="6">
      <a-card hoverable>
        <a-statistic
            title="今日订单营业额度"
            :value="profit.totalMoney"
            :precision="2"
            suffix="元"
            :value-style="{ color: '#3f8600' }"
        >
        </a-statistic>
        <a-divider></a-divider>
        <a-statistic
            title="今日新增订单"
            :value="todayOrderNum"
            suffix="单"
            :value-style="{ color: '#3f8600' }"
        >
        </a-statistic>
      </a-card>
    </a-col>
  </a-row>
  <br>
  <a-card size="small" hoverable style="text-align: left;width: 98%">
    <template #title>
      <a-row>
        <a-col :span="12">
          <span>店铺信息览略</span>
        </a-col>
        <a-col :span="12" style="text-align: right">
          <a-button @click="reload" size="small"><reload-outlined />刷新</a-button>
        </a-col>
      </a-row>
    </template>
    <a-list size="large" :data-source="shopList">
      <template #renderItem="{ item }">
        <a-list-item key="item.shopId">
          <a-list-item-meta>
            <template #description>
              <a-row>
                <a-col :span="12">
                  <div v-if="item.data==undefined">
                    <span>店铺信息获取错误,请不要关闭浏览器插件登录到店铺店铺后台一次即可获取</span>
                  </div>
                  <div v-else>
                    <span>最近待出库订单:{{item.waitout}}</span><a-divider  type="vertical"></a-divider><span>待确认收货订单:{{item.waitget}}</span><a-divider  type="vertical"></a-divider>
                    <span>今日新增订单:{{item.today}}</span><a-divider  type="vertical"></a-divider><span>昨日新增订单:{{item.yesterday}}</span>
                  </div>
                </a-col>
                <a-col style="text-align: right" :span="12">
                  <a-button size="small"  @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+item.shopId)" type="primary">店铺后台</a-button>
                  <a-divider  type="vertical"></a-divider>
                  <a-button size="small" target="_blank" :href="'https://order.cklllll.com/extension.html?type=jumpUrl&shopId='+item.shopId+'&url=https://fw.jd.com/home/sers.action'" type="primary">服务市场</a-button><a-divider  type="vertical"></a-divider>
                  <a-popconfirm
                      title="是否解除绑定店铺?"
                      ok-text="确定"
                      @confirm="handUnbindShopId(item.shopId)"
                      cancel-text="取消"
                  >
                    <a-button size="small"  type="primary" danger>解绑店铺</a-button>
                  </a-popconfirm>
                </a-col>
              </a-row>
            </template>
            <template #title>
              <a @click="openWindow('https://order.cklllll.com/extension.html?type=shopManage&shopId='+item.shopId)" :style="{'color':item.status > -1?'#409EFF':'#F56C6C'}">{{ item.shopName }} {{item.status > -1?'(正常)':'(掉线)'}}</a>
              <a-divider  type="vertical"></a-divider>
              <a>{{item.data==undefined?'请重新登录该店铺':JSON.parse(item.data).scndCategoryName}}</a>
              <a-divider  type="vertical"></a-divider>
              <a :style="{'color':item.userName==null?'red':'black'}">{{item.userName==null?'未绑定登录账号':item.userName}}</a>
              <a-divider  type="vertical"></a-divider>
              <a :style="{'color':item.jm_access_token==null?'#F56C6C':'#67C23A'}">{{item.jm_access_token==null?'未授权京麦发货权限':'已授权京麦发货权限'}}</a>
            </template>
            <template #avatar><a-avatar :size="64">{{item.shopName.substring(0,2)}}</a-avatar></template>
          </a-list-item-meta>
        </a-list-item>
      </template>
    </a-list>
  </a-card>
  <br><br><br><br>
  <br><br><br><br>
</template>

<script>


import { getItem }  from '@/utils/storage'
import {reactive} from 'vue'
import {bindShopIdsDetail, unbindShopId} from "@/api/shop";
import {toDayProfit} from "@/api/record";
import {message} from "ant-design-vue";


export default {
  name: "home",
  components:{

  },
  data(){
    return{
      shopList :[],
      profit : {
        "totalMoney": 0,
        "totalProfit": 0,
        "totalPurchase": 0,
        "yesterDayTotalMoney": 0,
        "yesterDayTotalProfit": 0,
        "yesterDayTotalPurchase": 0
      },
      todayOrderNum : 0,
    }
  },
  setup (){
    const userInfo =  reactive(getItem("userinfo"))
    return { userInfo}
  },
  mounted() {
      this.reload(true);
      setInterval(()=>{
        this.reload(false)
      },60000)
  },
  methods:{
    openWindow(url){
      window.open(url)
    },
    reload(loading = false){
      bindShopIdsDetail(loading).then(res=>{
        if (res.success){
          this.shopList = res.data
          this.todayOrderNum = 0
          this.shopList.forEach(shop=>{
            this.todayOrderNum+=shop.today;
          })
        }
      })
      toDayProfit(loading).then(res=>{
        if (res.success){
          this.profit = res.data
        }
      })
    },
    handUnbindShopId(shopId){
      unbindShopId(shopId).then(res=>{
        if (res.success){
          message.success("解绑成功")
          this.reload(false)
        }else{
          message.error("解绑失败,错误原因:"+res.msg)
        }
      })
    }
  }

}
</script>


