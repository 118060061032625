import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const checkShopIds = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/shangzhi/shangzhi`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                }),
                uninstall : false
            },
            loading : true,
            text : "正在开始采集目标店铺类目商品信息"
        })
    }
}

