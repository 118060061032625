import { Modal } from 'ant-design-vue'
import store from '@/store/index'
import {h} from "vue";

export const checkComponent = ()=>{
    if (!store.state.isInstall){
        Modal.error({title: "未安装获芸订单管理插件,请安装之后再使用本功能",content: h('div', {}, [
                h('a',{
                    "style" : {"color":"red"},
                    "href" : "./Chrome.zip",
                    "target" : "_blank",
                    "download" : "获芸订单管理插件.zip"
                } ,'点击此处下载'),
            ]),})
        return false
    }
    if (!store.state.isGetOrder){
        Modal.error({
            title: "未运行获芸插件通用增强组件,请启动之后再使用本功能",
            content:
                h('div', {},
                    [
                                h('a',{
                                    "style" : {"color":"red"},
                                    "href" : "https://huoyuntest.oss-cn-hangzhou.aliyuncs.com/huoyunOrderSysteam.exe",
                                    "target" : "_blank",
                                    "download" : "huoyunOrderSysteam.zip"
                                } ,'点击此处下载'),
                            ]
                ),
        })
        return false
    }
    return true
}
