import axios from 'axios'
import { message } from 'ant-design-vue';
import { set } from '@/api/store'
import store from '@/store/index'
import { ElLoading } from 'element-plus'
import 'element-plus/dist/index.css'
import {getItem} from "./storage";

let loading = null
let token = store.state.token;

const service = axios.create({
    baseURL: 'https://api.cklllll.com',
    timeout: 30*1000*60,
    loading : true,
    headers : {
        "token" : token ? token : getItem("userInfo")?getItem("userInfo")['token'] : null,
    },
})

console.log("token:" + token)




// 拦截请求
service.interceptors.request.use(
  config => {
      config.headers.token =   token ? token : getItem("userInfo")?getItem("userInfo")['token'] : null
      if (config.loading){
          loading = ElLoading.service({
              lock: true,
              text: config.text==null||config.text==undefined?'正在努力载入中':config.text,
              background: 'rgba(0, 0, 0, 0.7)',
          })
          //app.config.globalProperties.$loading.open()
      }
      console.log(config)
    return config
  },
  error => {
    return Promise.reject(error)
  }
)


// 拦截响应
service.interceptors.response.use(
  // 响应成功进入第1个函数，该函数的参数是响应对象
  response => {
      //app.config.globalProperties.$loading.close()
      if (loading!=null){
          loading.close()
      }

    if (response.status == 202){
        store.dispatch('updateToken',response.data.data.token);
        set("token",response.data.data.token)
    }
    return response.data
  },
  // 响应失败进入第2个函数，该函数的参数是错误对象
  async error => {
      if (loading!=null){
          loading.close()
      }
      //app.config.globalProperties.$loading.close()
    // 如果响应码是 401 ，则请求获取新的 token
    // 响应拦截器中的 error 就是那个响应的错误对象
    if (error.response && error.response.status === 401) {
      // 校验是否有 refresh_token

    }
    // console.dir(error) // 可在此进行错误上报
    try {
        message.error(error.response.data.msg)
    } catch (err) {
        message.error(error.message)
    }
    return Promise.reject(error)
  }
)

export default service
