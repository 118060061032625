/*
 * @Descripttion:
 * @version:
 * @Date: 2021-04-20 11:06:21
 * @LastEditors: huzhushan@126.com
 * @LastEditTime: 2021-04-21 09:36:55
 * @Author: huzhushan@126.com
 * @HomePage: https://huzhushan.gitee.io/vue3-element-admin
 * @Github: https://github.com/huzhushan/vue3-element-admin
 * @Donate: https://huzhushan.gitee.io/vue3-element-admin/donate/
 */
import request from '@/utils/request'

// 登录接口
export const Login = data => {
    return request({
        url: 'account/account/login',
        method: 'POST',
        data : data,
    })
}

// 登录接口
export const loginCode = data => {
    return request({
        url: 'account/account/loginCode',
        method: 'POST',
        data : data,
    })
}


// 获取登录用户信息
export const GetUserinfo = (loading=false) => {
    return request({
        url: 'account/account/userInfo',
        method: 'GET',
        loading : loading
    })
}



// 获取登录用户信息
export const GetSMS = (data) => {
    return request({
        url: '/message/message/sendMessage',
        method: 'POST',
        data : data
    })
}

export const chargeHistory = ()=> {
    return request({
        url: 'account/account/chargeHistory',
        method: 'GET'
    })
}

// 获取登录用户信息
export const Register = (data) => {
    return request({
        url: '/account/account/register',
        method: 'POST',
        data : data
    })
}
