import useClipboard from "vue-clipboard3";
const { toClipboard } = useClipboard()
import {message, Modal} from 'ant-design-vue'
import {h} from "vue";

export const transformTime = (timestamp)=> {
    let time = new Date(timestamp*1000);
    let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
    let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
    let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
    let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
    let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
    let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
    M = M < 10 ? "0"+ M : M;
    d = d < 10 ? "0"+ d : d;
    h = h < 10 ? "0"+ h : h;
    m = m < 10 ? "0"+ m : m;
    s = s < 10 ? "0"+ s : s;
    return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
}

export const isNumber = (value) => {
    return typeof value === 'number' && isFinite(value)
}

export const transformMoney = (value, abs = false) => {
    value = parseInt(value)
    value = isNumber(value) ? value : 0
    value = isNaN(value) ? 0.00 : value
    return parseFloat(abs ? Math.abs(value / 100) : value / 100).toFixed(2)
}
export const setupApp = (e)=>{
    console.log(e)
}


export const copy = async (text) => {
    try {
        await toClipboard(text)
        message.success('复制成功:'+text)

    } catch (e) {
        message.success('复制失败:'+e)
    }
}

export const NotSetUpBox = ()=>{
    Modal.error({title: "本功能需要安装最新的插件支持,请您下载安装浏览器插件后使用",content: h('div', {}, [
            h('a',{
                "style" : {"color":"red"},
                "href" : "http://huoyunpublic.oss-cn-hangzhou.aliyuncs.com/%E5%BA%97%E9%93%BA%E8%AE%A2%E5%8D%95%E5%A2%9E%E5%BC%BA%E7%AE%A1%E7%90%86%E6%8F%92%E4%BB%B6.zip",
                "target" : "_blank",
                "download" : "店铺订单增强管理插件.zip"
            } ,'点击此处下载'),
        ]),})
}
