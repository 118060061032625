<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择您要使用进行操作的的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="请选择您要使用进行操作的的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});console.log(useShopIds)" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>
    <div style="text-align: left">

    </div>
  </div>
  <br><br><br><br>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" style="z-index:10001">
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="14">
              开始时间(天后):
              <a-input style="width: 128px" v-model:value="start"></a-input>
              设置天数:
              <a-input style="width: 128px" v-model:value="num"></a-input>
              &nbsp;&nbsp;
              初始折扣:
              <a-input style="width: 128px" v-model:value="initial"></a-input>
              &nbsp;&nbsp;
              设置递增:
              <a-input style="width: 128px" v-model:value="price"></a-input>
            </a-col>
            <a-col :span="8" style="text-align: right">
              <a-button @click="handeSavaBrand">
                <template #icon>
                  <plus-outlined />
                </template>
                添加店铺优惠
              </a-button>
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import { message } from 'ant-design-vue'
import {getItem as storageGet} from "@/utils/storage";
import {reactive, ref} from "vue";
import {setCouponNew} from "./api/item";

export default {
  name: "setCoupon",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      addBrandDialog : false,
      num : "20",
      price : "0.05",
      initial : "9.0",
      start : "0",
      parameter: {
        name : null
      },
      plaformValue : ["4"],
      plaform : [
        {
          label : '京东PC端',
          value : "0"
        },
        {
          label : '手机专享',
          value : "1"
        },
        {
          label : '微信专享',
          value : "5"
        },
        {
          label : '手Q专享',
          value : "4"
        },
        {
          label : 'M版京东',
          value : "3"
        },
      ],
      getCouponBeginTime : "2023/10/20 17:23:31",
      getCouponEndTime : "2023/10/20 17:23:31",
      CouponBeginTime : "2023/10/20 17:23:31",
      CouponEndTime : "2023/10/28 17:23:31",
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(useShopIds.value.includes(shop.shopId)==false){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods: {
    handeSavaBrand(){
      setCouponNew(this.useShopIds,this.num,this.price,this.initial,this.start).then(res=>{
        if (res.success){
          message.success("提交批量生成优惠券请求成功")
        }else{
          message.error("提交批量生成优惠券请求失败")
        }
      })
    }
  },
  watch:{

  }
}
</script>

<style>
  .bottom{
    position: fixed;
    bottom:0px;
    left:0px;
    height:72px;
    width:100%;
  }


</style>
