<template>
  <a-drawer
      :title="title"
      placement="bottom"
      :visible="visible"
      height="80%"
      zIndex="999999"
      :closable="false"
      :maskClosable="true"
      :destroyOnClose="true"
  >
    <template #extra>
      <a-button type="primary" style="margin-right: 12px" :href="url" target="_blank"><ie-outlined />新页面打开</a-button>
      <a-button type="primary" danger style="margin-right: 12px" @click="close"><close-outlined />关闭</a-button>
    </template>
    <iframe id="inlineFrameExample"
            width="100%"
            style="border: medium none;height: 98%"
            :src="url">
    </iframe>
  </a-drawer>
</template>
<script>


import { defineComponent } from 'vue'
import { Drawer ,Button } from 'ant-design-vue'
import store from '@/store/index'
import {IeOutlined,CloseOutlined} from '@ant-design/icons-vue';

export default defineComponent({
  name : "browserWindow",
  components:{
    aDrawer : Drawer,
    aButton : Button,
    IeOutlined : IeOutlined,
    CloseOutlined : CloseOutlined
  },
  props: {
    title : String,
    url : String,
    onClose : Function
  },
  data(){
    return{
      visible :true,
      spinning : true,
      buyInfo : []
    }
  },
  setup() {
    const vuex = store
    return {
      vuex
    }
  },
  mounted() {

  },
  methods:{
    close(){
      this.visible = false;
    }
  }
})
</script>
