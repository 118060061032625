import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const upProductIndex = (shopIds)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/productIndex/productIndex`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}


export const exportSystemDown = (shopIds,wareStatusStr)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/exportSystemDown`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "wareStatusStr" : wareStatusStr
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}
