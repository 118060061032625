<template>
  <a-card id="tool" hoverable style="text-align: left">
    <span style="font-size: 14px"><Shop-outlined />&nbsp;选择店铺:</span><br>
    <a-row :gutter="20" style="margin-top: 12px">
      <a-col :span="20" >
        <a-select
            v-model:value="useShopIds"
            mode="multiple"
            style="width: 100%;"
            :allowClear="true"
            show-search
            @change="selectShopIds"
            placeholder="请选择您要使用的店铺(可多选)"
        >
          <a-select-option v-for="shop in shopList" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
            {{shop.shopName}}{{shop.status>-1?"":"(掉线)"}}
          </a-select-option>
        </a-select>
      </a-col>
      <a-col :span="4" style="text-align: right">
        <a-button @click="this.useShopIds=[];shopList.forEach(shop=>{this.useShopIds.push(shop.shopId)})"  type="primary" size="small">全部选择</a-button>&nbsp;&nbsp;
        <a-button @click="this.useShopIds=[];" size="small">取消选择</a-button>
      </a-col>
    </a-row>
    <br>
    <a-row :gutter="20">
      <a-col :span="6" >
        <span style="font-size: 14px"><field-time-outlined />&nbsp;选择日期:</span><br>
        <a-range-picker
            style="margin-top: 12px"
            v-model:value="searchParams.selectTime"
            size="small" />
      </a-col>
      <a-col :span="6" >
        <span style="font-size: 14px"><file-search-outlined />&nbsp;订单号:</span><br>
        <a-textarea
            style="margin-top: 12px"
            size="small"
            v-model:value="searchParams.orderIds"
            placeholder="请输入内容,多个订单号请以,号或者换行分开"
            :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </a-col>
      <a-col :span="12" style="text-align: right"  >
          <a-button type="primary"><search-outlined />搜索订单</a-button>
      </a-col>
    </a-row>
  </a-card>
  <br>

  <a-card id="orderList" style="text-align: left">
    <div style="text-align: center">
      <a-pagination @change="pageChange" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
    <a-divider></a-divider>
    <div>
      <a-space :size="16">
        <a-checkbox v-model:checked="allSelect" @click="orderList.map(order=>order.select=!allSelect)">全部选中</a-checkbox>
        <a-button type="primary" size="middle">导出选中订单
          <template #icon>
            <export-outlined />
          </template>
        </a-button>
      </a-space>
    </div>
    <a-divider></a-divider>
    <a-empty v-if="!orderList.length > 0"></a-empty>
    <afsOrderList :orderList="orderList" :shopIdList="shopList"></afsOrderList>
    <div style="text-align: center;margin-top: 56px">
      <a-pagination @change="pageChange" v-model:current="pageInfo.page" size="small" :total="pageInfo.total" show-size-changer show-quick-jumper :show-total="total => `共有 ${total} 单`"  />
    </div>
    <br>
  </a-card>
  <br><br><br><br>
</template>

<script>
import dayjs from 'dayjs';
import {ShopIds} from "@/api/shopIds"
import {getAfsOrderList} from "@/api/order";
import {useRouter} from "vue-router";
import { message } from 'ant-design-vue';
import useClipboard from 'vue-clipboard3'
import afsOrderList from '@/components/page/order/afsOrderList'


export default {
  name: "home",
  components:{
    afsOrderList
  },
  data(){
    return{
      orderStatusCheckOptions : [
        { label: '待出库', value: '8' },
        { label: '已出库', value: '16' },
        { label: '已完成', value: '19' },
        { label: '已取消', value: '-1' },
      ],
      warnTypeCheckOptions : [
        { label: '出库预警', value: '1' },
        { label: '揽件预警', value: '3' },
      ],
      breakAgreementTypeCheckOptions : [
        { label: '出库超时', value: '1' },
        { label: '揽件超时', value: '3' },
      ],
      isRemarkCheckOptions : [
        { label: '有备注', value: '1' },
        { label: '无备注', value: '-1' },
      ],
      cloudOutExpressNoCheckOptions : [
        { label: '云仓未转发', value: '-1' },
        { label: '云仓已转发', value: '1' },
      ],
      expressStatusStrCNCheckOptions : [
        { label: '已签收', value: '已签收' },
      ],
      buyOrderStatusCheckOptions : [
        { label: '未拍单', value: '-1' },
        { label: '同步异常', value: '-2' },
        { label: '待支付', value: '101' },
        { label: '待发货', value: '201' },
        { label: '待收货', value: '202' },
        { label: '待评价', value: '203' },
        { label: '交易已取消', value: '301' },
        { label: '退款成功', value: '302' },
        { label: '退款待用户处理', value: '303' },
        { label: '退款待商家处理', value: '304' },
        { label: '退货待商家签收', value: '305' },
        { label: '同步失败', value: '401' },
        { label: '关联错误', value: '402' },
      ],
      shopIds :[],
      pickerOptions :{
        shortcuts: [
          {
            text: '今天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近15天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 15);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近30天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '近90天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      useShopIds :[],
      allSelect : false,
      orderList : [],
      shopList : [],
      pageInfo : {
        page : 1,
        pageSize : 10,
        total : 0,
      },
      searchParams : {
        selectTime: [dayjs(new Date()).subtract(1, 'month'),dayjs(new Date())],
        orderIds : "",
        order_sn : "",
        moreSearchParamsDiv : false,
      },
    }
  },
  setup(){
    const router = useRouter()
    const { toClipboard } = useClipboard()
    const copy = async (text) => {
      try {
        await toClipboard(text)
        message.success('复制成功:'+text)

      } catch (e) {
        message.success('复制失败:'+e)
      }
    }
    return{
      router,copy
    }

  },
  mounted(){
    this.getShopIds()
  },
  methods:{
    getShopIds(list=[]){
      ShopIds().then(res=>{
        if (res.success){
          this.shopIds = []
          this.shopList = res.data
          if (list.length == 0){
            res.data.forEach(shop=>{
              this.useShopIds.push({
                label : shop.shopName,
                value : shop.shopId,
              })
              this.shopIds.push({
                label : shop.shopName,
                value : shop.shopId,
              })
            })
          }
          this.getOrderList()
        }else {
          message.error(res.msg)
        }
      })
    },
    selectShopIds(){
      console.log(this.useShopIds)
    },
    getOrderList(){
      let shopIds = ""
      const data = `page=${this.pageInfo.page}&pagesize=${this.pageInfo.pageSize}&shopIds=${shopIds}&searchParams=${JSON.stringify(this.searchParams)}`
      getAfsOrderList(data).then(res=>{
        if (res.success){
          //转换数据某些数据为object
          if (res.data.total < 1){
            this.orderList = []
            this.pageInfo.total = res.data.total
            return
          }
          res.data.data.forEach(order=>{
            if (this.allSelect){
              order.select = true
            }else{
              order.select = false
            }
            try {
              order.address = JSON.parse(order.address)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
              order.buyAddressInfo = JSON.parse(order.buyAddressInfo)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
              order.buyerInfo = JSON.parse(order.buyerInfo)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            try {
              order.shopInfo = JSON.parse(order.shopInfo)
              // eslint-disable-next-line no-empty
            } catch (e) {}

            order.orderItems.forEach(item=>{
              item.expressInfo = JSON.parse(item.expressInfo)
              item.orderItems = JSON.parse(item.orderItems)
              item.orderItems.visible = false
            })
          })
          this.orderList = res.data.data
          this.shopIds = res.data.shopIds
          this.pageInfo.total = res.data.total
        }else{
          message.error(res.msg)
        }
      })
    },
    transformTime(timestamp) {
      let time = new Date(timestamp*1000);
      let y = time.getFullYear(); //getFullYear方法以四位数字返回年份
      let M = time.getMonth() + 1; // getMonth方法从 Date 对象返回月份 (0 ~ 11)，返回结果需要手动加一
      let d = time.getDate(); // getDate方法从 Date 对象返回一个月中的某一天 (1 ~ 31)
      let h = time.getHours(); // getHours方法返回 Date 对象的小时 (0 ~ 23)
      let m = time.getMinutes(); // getMinutes方法返回 Date 对象的分钟 (0 ~ 59)
      let s = time.getSeconds(); // getSeconds方法返回 Date 对象的秒数 (0 ~ 59)
      M = M < 10 ? "0"+ M : M;
      d = d < 10 ? "0"+ d : d;
      h = h < 10 ? "0"+ h : h;
      m = m < 10 ? "0"+ m : m;
      s = s < 10 ? "0"+ s : s;
      return y + '-' + M + '-' + d + ' ' + h + ':' + m + ':' + s;
    },
    pageChange(value){
      this.pageInfo.page = value
      this.getOrderList()
    },
  }
}
</script>

<style>
  .shop{
    text-align: left;
  }

  .a-select__tags {
    width: 100%;
  }

</style>
