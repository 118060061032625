<template>
  <div class="bottom" style="text-align: center;">
    <a-image style="margin-top: 12px;width: 32px;height: 32px" src="require('@/assets/logo-white.png')" ></a-image><br>
    <a style="color:white;">无阻网络工作室 - 浙江获芸网络科技有限公司</a><br>
    <a style="color:white;">Copyright © 2021-2024 cklllll.com All Rights Reserved 无阻网络科技&浙江获芸网络科技有限公司 版权所有 桂ICP备2022000874号-1</a>
  </div>
</template>

<script>
export default {
  name: "bottom"
}
</script>

<style>
  .bottom{
    position: fixed;
    bottom:0;
    left:0;
    height:100px;
    width:100%;
    z-index:9;
  }
  a{
    font-size: 12px;
  }
</style>
