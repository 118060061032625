<script setup>
import { orderStatusColor , flag } from "../config/layout";
import { NotSetUpBox } from "@/api/utils.js";
import {chromeApp} from "../api/index";
import { transformTime ,copy } from "@/api/utils"
import { defineProps ,ref , onMounted} from 'vue'
import remarkWindow from "@/components/server/remarkWindow";
import {CloseOutlined, IeOutlined} from "@ant-design/icons-vue";
import {message} from "ant-design-vue";
// import buyHistoryNew from "@/components/server/buyHistoryWindowNew";
import {cancleRelated} from "@/api/order";

import buyHistoryWindowNew from "@/components/server/buyHistoryWindowNew/buyHistoryWindowNew.vue";
// eslint-disable-next-line no-unused-vars
const props = defineProps(
    {
      order: {
        type: Object,
        default: null,
        required: false
      }
    }
)
const visible = ref(false);
const title = ref(null)
const url = ref(null)
const setUp = ref(false)
const height = ref(200)
const checkSetup = ()=>{
  if (setUp.value === false){
    NotSetUpBox()
  }
}




const transformOrderType = (value) =>{
  if (value === 1){
    return '淘宝'
  }else if (value === 2){
    return '1688'
  }else if(value === 0){
    return '拼多多'
  }
}

const transformStatus = (value,buyOrderType) =>{
  if (parseInt(buyOrderType) == 1){
    return value
  }else if (parseInt(buyOrderType) == 0){
    if (value  == 0){
      return '待付款'
    }else if (value  == 1){
      return '待分享'
    }else if (value  == 2){
      return '待发货'
    }else if (value  == 3){
      return '待收货'
    }else if (value  == 4){
      return '待评价'
    }else if (value  == 5){
      return ''
    }else if (value  == 6){
      return '退款成功'
    }else if (value  == 6){
      return '已取消'
    }
  }
}
// const transformSellerIcon = (buyType) =>{
//   if (buyType === 1){
//     return 'https://img.alicdn.com/tps/i4/T1uUG.XjtkXXcb2gzo-77-19.gif'
//   }else if (buyType === 2){
//     return null
//   }else if(buyType === 0){
//     return null
//   }
// }
const jumpBuyPlat = (buyType,orderSn,username) =>{
  let url = null
  if (buyType === 0){
    // 拼多多
    url = 'https://mobile.yangkeduo.com/order.html?order_sn='+orderSn
  }else if (buyType === 1){
    // 淘宝
    url = 'https://trade.taobao.com/trade/detail/trade_order_detail.htm?biz_order_id='+orderSn
  }else if ( buyType === 2){
    // 1688
  }
  checkSetup()
  chromeApp.send(JSON.stringify({
    type:'viewOrder',
    data:{
      buyOrderType:buyType,
      order_sn: orderSn,
      url:url,
      username:username
    },
    success : false,
  }))
}

const refreshPurchaseOrder = (buyType,orderSn,orderId,skuId) =>{
  checkSetup()
  chromeApp.send(JSON.stringify({
    type:'refreshPurchaseOrder',
    data:{
      buyOrderType:buyType,
      order_sn: orderSn,
      orderId:orderId,
      skuId: skuId
    },
    success : false,
  }))
}

const buyHistoryWindowNewRef = ref(null)
const buyHistoryWindow = (orderId,skuId,username) =>{
  buyHistoryWindowNewRef.value.initData(orderId,skuId,true,username,setUp.value)
}



const orderCancleRelated = (item) =>{
  cancleRelated({
    orderId:item.orderId,
    skuId:item.skuId
  }).then(res =>{
    if (res.success){
      item.order_sn = null
      item.nickname = null
      item.out_trade_no = null
      item.goods_id = null
      item.jdPrice = null
      item.buyOrderType = null
      item.buyOrderPayTime = null
      item.status = 0
      item.buyOrderStatus = 0
      item.buyOrderStatusStrCN = null
      message.success("取消成功")
    }else{
      message.error(res.msg)
    }
  })
}

onMounted(()=>{
  chromeApp.send({
    'type' : 'isSetUp',
    "success" : false
  })
  chromeApp.callback((e)=>{
    try {
      let msg =  e.data  instanceof Object?e.data:JSON.parse(e.data)
      switch (msg.type){
        case 'isSetUp':
          setUp.value = msg.success
          break
      }
    }catch (e){
      console.error(e)
    }

  })
})
const remarkWindowOpen = (order,item)=>{
  remarkWindow.open(order,item)
};
</script>

<template>
  <div>
    <div class="item_top" style="margin-bottom: 12px">
      <a-row>
        <a-col :span="16">
          <a-checkbox v-model:checked="order.select"></a-checkbox>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px"
             data-inject="0"
             data-type="jumpJd"
             :data-data="JSON.stringify(order)"
             :data-url="'https://neworder.shop.jd.com/order/orderDetail?orderId='+order.orderId"
             @click="checkSetup"
          >
            {{order.orderId}}&nbsp;&nbsp;
          </a>
          <a  @click="copy(''+order.orderId)"><copy-outlined /></a>
          <a-divider type="vertical"></a-divider>
          <a
              style="font-size: 14px"
              data-inject="0"
              data-type="jumpJd"
              :data-data="JSON.stringify(order)"
              :data-url="'https://shop.jd.com'"
              @click="checkSetup"
          >{{order.shopName}}</a>
          <a-divider type="vertical"></a-divider>
          <a-tooltip v-if="(order.create_time > ((new Date().getTime() / 1000)) - (2 * 60 * 60))">
            <template #title>下单时间不足两小时</template>
            <a style="font-size: 14px;color: #E6A23C">
              <warning-outlined />
            </a>
          </a-tooltip>
          &nbsp;
          <a
              :style="{'font-size' : '14px',
              'color' : !(order.create_time > ((new Date().getTime() / 1000)) - (2 * 60 * 60))?'#409EFF':'#909399' }"
          >
            {{transformTime(order.create_time)}}
          </a>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px;color:#303133">
            {{
              order.paymentTypeName
            }}
          </a>
          <a-divider type="vertical"></a-divider>
          <a style="font-size: 14px;color:#303133">实付:
            {{
              order.shouldPay
            }}元
          </a>
          <a-divider type="vertical"></a-divider>
          <a-tag style="margin-top: -12px" :color="orderStatusColor(order.orderStatus,order.orderStatusStrCN).color">
            {{
              orderStatusColor(order.orderStatus,order.orderStatusStrCN).label
            }}
          </a-tag>
          <a-divider type="vertical"></a-divider>
          <a-tag style="margin-top: -12px" :color="orderStatusColor(order.orderStatus,order.orderStatusStrCN).color">
            {{
              order.invoiceTypeName
            }}
          </a-tag>
          <a-divider type="vertical"></a-divider>
          <a-tooltip v-if="!(order.create_time >= ( (new Date().getTime() / 1000) - (48 * 60 * 60)))">
            <template #title>
              {{
                !(order.create_time >= ( (new Date().getTime() / 1000) - (72 * 60 * 60))) ?'订单已超过72小时':
                    !(order.create_time >= ( (new Date().getTime() / 1000) - (48 * 60 * 60))) ?'订单已经超过48小时':''
              }}
            </template>
            <a-tag
                v-if="!(order.create_time >= ( (new Date().getTime() / 1000) - (48 * 60 * 60)))"
                :color="!(order.create_time >= ( (new Date().getTime() / 1000) - (72 * 60 * 60))) ?'#F56C6C':
                    !(order.create_time >= ( (new Date().getTime() / 1000) - (48 * 60 * 60))) ?'#E6A23C':''"
            >
              {{
                !(order.create_time >= ( (new Date().getTime() / 1000) - (72 * 60 * 60))) ?'发货超时':
                    !(order.create_time >= ( (new Date().getTime() / 1000) - (48 * 60 * 60))) ?'即将超时':''
              }}
            </a-tag>
          </a-tooltip>
        </a-col>
        <a-col :span="8" style="text-align: right">
          <a-button
              data-inject="0"
              data-type="jumpJd"
              :data-data="JSON.stringify(order)"
              :data-url="'https://porder.shop.jd.com/order/singleOut/'+order.orderId"
              size="small"><send-outlined />订单出库</a-button>
          <a-divider type="vertical"></a-divider>
          <a-button size="small"><delete-outlined />隔离订单</a-button>
          <a-divider type="vertical"></a-divider>
          <a-button size="small" type="primary"><reload-outlined />刷新订单</a-button>
        </a-col>
      </a-row>
    </div>
    <div>
      <a-badge-ribbon text="Hippies" color="red">
        <a-row style="padding-top: 16px;" :gutter="[16,16]"  justify="start"  align="middle" >
          <a-col  :span="2" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id"  >
              <a-image v-if="item.orderItems.skuimage" style="width: 96px;"  :src="item.orderItems.skuimage.replace('N4','N0')"></a-image>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id">
              <a style="color: #303133;" @click="title = item.orderItems.skuName;url='https://item.jd.com/'+item.skuId+'.html';visible = true;"
              >{{item.orderItems.skuName}}</a>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id">
              <a style="color: #303133;">{{item.orderItems.color?item.orderItems.color:'默认规格'}}</a>
              <br>
              <a style="color: #303133;">京东价:{{item.orderItems.jdPrice}} 元</a>
              <br>
              <a style="color: #303133;">采购数 : {{item.orderItems.skuNum}} 件</a>
            </div>
          </a-col>
          <a-col :span="4" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id">
              <a-textarea
                  placeholder="拍单系统订单备注"
                  :auto-size="{ minRows: 3, maxRows: 3 }"
              ></a-textarea>
            </div>
          </a-col>
          <a-col :span="3">
            <div :style="{'height':height+'px','text-align':'center'}" v-for="(item) in order.orderItems" :key="item.id">
              <span style="font-size: 9px;color: #909399">商家备注:</span><br>
              <img :src="flag(order.halleyOrderisDetail.flag)">&nbsp;&nbsp;
              <span style="font-size: 9px;color: #909399">{{!order.halleyOrderisDetail.remark?'暂无备注':order.halleyOrderisDetail.remark}}</span>
              &nbsp;&nbsp;<br>
              <a @click="remarkWindowOpen(order,item)"><form-outlined />修改</a>
            </div>
          </a-col>
          <a-col :span="3" style="text-align: center">
            <div  :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id">
              <a-button
                  data-inject="0"
                  data-type="buyItem"
                  :data-data="JSON.stringify({
                    order,
                    item
                  })"
                  @click="checkSetup"
                  size="small" type="primary" :danger="item.primary">
                <shopping-cart-outlined />
                {{item.order_sn !== null && item.order_sn !== ''?'重新采购':'立即采购'}}
              </a-button>
              <br>
              <a-button style="margin-top: 12px" size="small"
                        data-inject="0"
                        data-type="handleRelated"
                        :data-handle="JSON.stringify({
                    order,
                    item
                  })"
                        @click="checkSetup"
              >
                <share-alt-outlined />
                手动关联
              </a-button>
              <br>
              <a-button  style="margin-top: 12px" size="small" @click="buyHistoryWindow(item.orderId,item.skuId,item.nickname)"><fund-projection-screen-outlined />采购记录</a-button>
              <br>
              <a-popconfirm

                  title="是否解除关联已采购的订单?"
                  v-if="item.order_sn!='' && item.order_sn!=null"
                  ok-text="确定"
                  cancel-text="取消"
                  @confirm="orderCancleRelated(item)"
              >
                <a-button   style="margin-top: 12px"  size="small" danger>
                  <delete-outlined />解除关联
                </a-button>
              </a-popconfirm>

            </div>
          </a-col>
          <a-col  :span="6" style="text-align: center">
            <div :style="{'height':height+'px'}" v-for="(item) in order.orderItems" :key="item.id">
              <a v-if="item.order_sn === null || item.order_sn === ''" style="color:#c3c3c5">暂无拍单数据</a>
              <div v-if="item.order_sn !== null && item.order_sn !== ''" style="width: 100%;height: 100%;text-align: left">
                <div>
                  <a-tooltip>
                    <template #title>点击跳转到订单详情页或者订单列表页</template>
                    <a  @click="jumpBuyPlat(item.buyOrderType,item.order_sn,item.nickname)">{{transformOrderType(item.buyOrderType)}}:{{item.order_sn}} </a>
                    <a href="javascript:void(0)"  @click="copy(''+item.order_sn)"> <copy-outlined /></a>
                  </a-tooltip>
                </div>
                <!--                <div  style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center" v-if="item.buySellerName">-->
                <!--                  <span style="margin-right:2px;">卖家:</span>-->
                <!--                  <a-tooltip>-->
                <!--                    <template #title>点击给卖家 {{item.buySellerName}} 发消息</template>-->
                <!--                    <a href="javascript:void(0)" v-if="transformSellerIcon(item.buyOrderType) != null"  target="_blank"><img :src="transformSellerIcon(item.buyOrderType)"  style="height: 16px;"/></a>-->
                <!--                  </a-tooltip>-->
                <!--                </div>-->
                <div  style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center">
                  <span style="margin-right: 2px;">状态:</span>
                  <a href="#">{{transformOrderType(item.buyOrderType)}}{{transformStatus(item.buyOrderStatusStrCN,item.buyOrderType)}}</a>
                  <a style="margin-left: 4px;" href="javascript:void(0)" @click="refreshPurchaseOrder(item.buyOrderType,item.order_sn,item.orderId,item.skuId)"><reload-outlined /></a>
                </div>
                <div v-if="item.buyCreateTime !== null " style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center"><span style="margin-right: 2px;">采购单创建时间:</span><span>{{item.buyCreateTime}}</span></div>
                <div v-if="item.nickname !== null && item.nickname !== ''" style="color: #303133;margin-top: 5px;display: flex;flex-flow: row;align-items: center"><span style="margin-right: 2px;">下单账号:</span><span>{{item.nickname}}</span></div>
                <a-divider type="horizontal" style="margin: 5px 0"></a-divider>
                <div  style="color: red;display: flex;flex-flow: row;justify-content: flex-start">
                  <span style="margin-right: 2px;display: inline-block">收货信息:</span>
                  <div style="line-height: 1.4;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    display: -moz-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
    word-break: break-all;width:70%">{{item.buyDeliveryAddress}}</div>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </a-badge-ribbon>
    </div>

    <a-card style="margin-top: 14px;margin-bottom: 12px">
      <div>
        <a-descriptions :column="6" size="small">
          <a-descriptions-item :labelStyle="{'color':order.buyerInfo.data.user.jdScore<2000?'red':'#606266','font-size': '12px'}"
                               :contentStyle="{'color':order.buyerInfo.data.user.jdScore<2000?'red':'#606266','font-size': '12px'}"
                               label="京 享 值">{{order.buyerInfo.data.user.jdScore}}
          </a-descriptions-item>
          <a-descriptions-item :labelStyle="{'color':order.buyerInfo.data.user.jdScore<2000?'red':'#606266','font-size': '12px'}"
                               :contentStyle="{'color':order.buyerInfo.data.user.jdScore<2000?'red':'#606266','font-size': '12px'}"
                               label="会员等级">{{order.buyerInfo.data.user.customerLevel}}
          </a-descriptions-item>
          <a-descriptions-item :labelStyle="{'color':'#606266','font-size': '12px'}"
                               :contentStyle="{'color':'#606266','font-size': '12px'}"
                               label="注册时间">{{order.buyerInfo.data.user.reg}}
          </a-descriptions-item>
          <a-descriptions-item :labelStyle="{'color':'#606266','font-size': '12px'}"
                               :contentStyle="{'color':'#606266','font-size': '12px'}"
                               label="好 评 率">{{order.buyerInfo.data.user.goodRate}}
          </a-descriptions-item>
          <a-descriptions-item :labelStyle="{'color':'#606266','font-size': '12px'}"
                               :contentStyle="{'color':'#606266','font-size': '12px'}"
                               label="收 货 人">{{order.buyAddressInfo.address}}
            &nbsp;&nbsp;&nbsp;{{order.buyAddressInfo.name}}&nbsp;&nbsp;&nbsp;{{order.buyAddressInfo.mobile}}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-card>
    <a-drawer
        :title="title"
        placement="bottom"
        :visible="visible"
        height="80%"
        zIndex="999999"
        :closable="false"
        :maskClosable="true"
        :destroyOnClose="true"
    >
      <template #extra>
        <a-button type="primary" style="margin-right: 12px" :href="url" target="_blank"><ie-outlined />新页面打开</a-button>
        <a-button type="primary" danger style="margin-right: 12px" @click="visible=false;"><close-outlined />关闭</a-button>
      </template>
      <iframe id="inlineFrameExample"
              width="100%"
              style="border: medium none;height: 98%"
              :src="url">
      </iframe>
    </a-drawer>


    <buyHistoryWindowNew ref="buyHistoryWindowNewRef">

    </buyHistoryWindowNew>


  </div>
</template>

<style scoped>

</style>
