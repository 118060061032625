<template>
  <div style="text-align: left">
    <div style="text-align: center">
      <a-alert
          message="本页面功能需要依靠运行获芸增强组件支持,如果您需要使用本页面功能。请保持获芸增强组件的运行状态"
          type="warning"
          show-icon
          closable
      />
    </div>
    <br>
    <a-card size="small" title="请选择你要操作进行商智类目采集的店铺">
      <a-row :gutter="20">
        <a-col :span="19">
          <a-select
              :max-tag-count="20"
              v-model:value="useShopIds"
              mode="multiple"
              style="width: 100%;"
              :allowClear="true"
              show-search
              :filter-option="filterOption"
              placeholder="选择你要操作进行商智类目采集的店铺(可多选)"
          >
            <a-select-option v-for="shop in userInfo.shopIds" :key="shop.shopId" :name="shop.shopName" :value="shop.shopId">
              {{shop.shopName}}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5" style="text-align: left">
          <a-button  @click="seleceGroup=true" type="primary" >分组选择</a-button>
          <a-modal v-model:visible="seleceGroup" width="40%" title="按店铺分组选择店铺" :footer="null" :maskClosable="false" :destroyOnClose="true">
            <div>
              <a-checkbox-group v-model:value="selectGroupId">
                <a-checkbox  :value="g.id" v-for="(g,index) in group" :key="index">{{g.name}}<span style="color: #606266;font-size:12px">({{g.data.length}}个店铺)</span></a-checkbox>
              </a-checkbox-group>
              <a-divider></a-divider>
              <div style="text-align: right;margin-top: 12px">
                <a-button @click="importGroup" type="primary" size="small"><check-outlined />确认</a-button>
              </div>
            </div>
          </a-modal>
          <a-divider  type="vertical"></a-divider>
          <a-button @click="useShopIds=[];userInfo.shopIds.forEach(shop=>{useShopIds.push(shop.shopId)});" type="primary">全部选择</a-button>
          <a-divider  type="vertical"></a-divider>
          <a-button  @click="useShopIds=[];">全部取消</a-button>
        </a-col>
      </a-row>
    </a-card>
    <br>


  </div>
  <a-row class="bottom">
    <a-col :span="3"></a-col>
    <a-col :span="21" >
      <div style="text-align: left">
        <a-card>
          <a-row>
            <a-col :span="23" style="text-align: right">
              <a-button @click="useCheckShopIds"  type="primary"><template #icon></template>开始采集</a-button>
            </a-col>
          </a-row>
        </a-card>
        <br>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import {get} from "@/api/store"
import { message,Modal } from 'ant-design-vue'
import { createVNode ,getCurrentInstance ,reactive,ref } from 'vue'
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
import {getItem as storageGet} from "@/utils/storage";
import {checkShopIds} from "./api/item"
import {uploadTasks} from "../xiongda/api/item";

export default {
  name: "zhiyun",
  components:{

  },
  data(){
    return{
      userInfo : get("userinfo"),
      checkType : "mean",
      num : 2000,
      checkUseShopIds : [],
      failUseShopIds : [],
      itemUrl : [],
      urls : "",
      uploadChecked : false,
      key : "",
      price : "135"
    }
  },
  setup(){
    const filterOption = (input, option) => {
      return option.name.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    };
    const useShopIds = ref([])
    const seleceGroup  = ref(false)
    const selectGroupId = ref([])
    const group = reactive(storageGet("userinfo")==null?'':JSON.parse(storageGet("userinfo")['group']))
    const importGroup = ()=>{
      if (selectGroupId.value.length < 1) {
        seleceGroup.value = false
        message.warning("你没有选择任何店铺")
        return
      }
      useShopIds.value = []
      group.forEach(g=>{
        selectGroupId.value.forEach(id=>{
          if (id==g.id){
            g.data.forEach(shop=>{
              if(!useShopIds.value.includes(shop.shopId)){
                useShopIds.value.push(shop.shopId)
              }
            })
          }
        })
      })
      seleceGroup.value = false
      message.info("已选择您选择的分组店铺")
    }
    return {filterOption,message,seleceGroup,selectGroupId,group,importGroup,useShopIds}
  },
  mounted(){
    this.userInfo.shopIds = JSON.parse(this.userInfo.shopIds)
    this.userInfo.group = JSON.parse(this.userInfo.group)
  },
  methods:{
    openWindow(url){
      window.open(url)
    },
    clearItem(){
      if (this.words==""){
        Modal.error({
          title: '执行失败',
          content: '使用的检测关键词不能为空',
        });
        return
      }
      const appContext = getCurrentInstance()
      const shopGroup = reactive(this)
      Modal.confirm({
        title: '确认询问',
        content: '是否清理选中的店铺的商品标题?',
        okText: '确认',
        icon: createVNode(ExclamationCircleOutlined),
        cancelText: '取消',
        appContext,
        onOk(data=shopGroup) {
          if (data.useShopIds.length<1){
            Modal.error({
              title: '执行失败',
              content: '你还没有选择店铺',
            });
          }
        },
      });
    },
    useCheckShopIds(){
      checkShopIds(this.useShopIds).then(res=>{
        if (res.success){
          message.success("采集软件已经开始工作了")
        }else {
          Modal.warning({
            title : "采集软件启动任务失败",
            content : res.msg
          })
        }
      })
    },
    upLoad(){
      if (this.checkType === "mean"){
        let index = 0
        this.checkUseShopIds.forEach(shop=>{
          shop.itemIds = []
        })
        //分配任务
        this.tasks.data.forEach(task=>{
          if (index >= this.checkUseShopIds.length){
            index = 0
          }
          this.checkUseShopIds[index].itemIds.push(task)
          index++
        })
      }else {
        let index = 0
        this.checkUseShopIds.forEach(shop=>{
          shop.itemIds = []
        })
        //分配任务
        this.tasks.data.forEach(task=>{
          if (index >= this.checkUseShopIds.length){
            index = 0
          }
          if (this.checkUseShopIds[index].itemIds.length >= this.num){
            index++
          }else{
            this.checkUseShopIds[index].itemIds.push(task)
          }
        })
      }

      uploadTasks(this.checkUseShopIds,this.uploadChecked).then(res=>{
        if (res.success){
          Modal.success({
            title : "成功",
            content : "提交上货任务成功"
          })
        }else{
          Modal.error({
            title : "失败",
            content : res.msg
          })
        }
      })
    },
  },
  watch:{

  }
}
</script>

<style>
.bottom{
  position: fixed;
  bottom:0px;
  left:0px;
  height:72px;
  width:100%;
}


</style>
