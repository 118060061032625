import {checkComponent} from "@/api/init";
import request from "@/utils/request";
import store from "@/store";

export const clearSaleImg = (shopIds,checkType)=>{
    if (checkComponent()){
        return request({
            url: `http://localhost:8081/api`,
            method: 'POST',
            data : {
                name : `/script/jingdong/item/clearSaleImg`,
                parameter : JSON.stringify({
                    "token" :  store.state.token,
                    "shopIds" : shopIds,
                    "checkType" : checkType
                }),
                uninstall : false
            },
            loading : true,
        })
    }
}

export default {
    clearSaleImg,
};
