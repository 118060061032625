<template>
  <br><br>
  <a-row>
    <a-col :span="17">
      <home></home>
    </a-col>
    <a-col :span="7">
      <login></login>
    </a-col>
  </a-row>
</template>

<script>
import home from '@/components/utils/home'
import Login from "@/components/utils/login";

export default {
  name: 'App',
  components: {
    Login,
    home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
